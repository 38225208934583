import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox, TextMaskCustom } from '../atoms';
import { SimpleAlertDialog } from '../molecules';
import { Field, FeelingField, Slider, RadioField } from '../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
  formatSecondsToTime,
} from '../utils';
import ENDPOINTS from '../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const ResponsiveDialog = ({ removeActivity, ...activity }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();
  const updateActivityState = useRequest();
  const deleteActivityState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const sportState = useFetchRequest(ENDPOINTS.enumSportTypes());

  const {
    object,
    requiredString,
    string,
    number,
    requiredNumber,
    url,
    time,
  } = translatedValidations(t);

  const [state, setState] = useState({
    enabledHR: false,
  });

  const handleChangeEnableHR = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const onSubmitMemoized = useCallback(
    ({
      activityId,
      name,
      description,
      sport,
      tracking,
      map,
      result,
      feeling,
      exertion,
      trUnitTime,
      enabledHR,
      zone1,
      zone2,
      zone3,
      zone4,
      zone5,
      type,
    }) => {
      updateActivityState.request(ENDPOINTS.updateActivity(), {
        method: 'PUT',
        onSuccess: () => {
          handleClose();
          removeActivity(activityId);
          enqueueSnackbar(t('Organisms.ActivityForm.UpdatedActivityAlert'), {
            variant: 'success',
          });
        },
        data: {
          activityId,
          name,
          description,
          sport,
          tracking,
          map,
          result,
          feeling,
          exertion,
          trUnitTime,
          enabledHR,
          zone1,
          zone2,
          zone3,
          zone4,
          zone5,
          type,
        },
      });
    },
    [updateActivityState, handleClose, removeActivity, enqueueSnackbar, t],
  );

  const onDeleteMemoized = useCallback(
    (activity) => {
      deleteActivityState.request(ENDPOINTS.deleteActivity(), {
        method: 'DELETE',
        onSuccess: () => {
          handleClose();
          removeActivity(activity.activityId);
          enqueueSnackbar(t('Organisms.ActivityForm.DeletedActivityAlert'), {
            variant: 'success',
          });
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          activityId: activity.activityId,
        },
      });
    },
    [deleteActivityState, handleClose, removeActivity, enqueueSnackbar, t],
  );

  const schema = object({
    name: requiredString,
    sport: requiredNumber,
    trUnitTime: time,
    description: string,
    type: requiredString,
    tracking: url,
    map: url,
    result: url,
    zone1: time,
    zone2: time,
    zone3: time,
    zone4: time,
    zone5: time,
    exertion: number,
    feeling: number,
  });

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Organisms.ActivityForm.EditActivity')} - {activity.name}
        </DialogTitle>
        <DialogContent>
          <ErrorBox
            errorList={[
              { id: 1, error: sportState.error },
              { id: 2, error: updateActivityState.error },
            ]}
          />
          {sportState.isLoading && <CircularProgress />}
          {sportState.data && (
            <Formik
              initialValues={{
                activityId: activity.activity_id,
                name: activity.name,
                description: activity.description,
                tracking: activity.tracking,
                map: activity.doma,
                zone1: formatSecondsToTime(activity.time_zone1),
                zone2: formatSecondsToTime(activity.time_zone2),
                zone3: formatSecondsToTime(activity.time_zone3),
                zone4: formatSecondsToTime(activity.time_zone4),
                zone5: formatSecondsToTime(activity.time_zone5),
                type: 'T',
                feeling: 0,
                exertion: 50,
                trUnitTime: '',
                distance: '',
                elapsed_time: '',
                elevation_gain: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, values }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label={t('Organisms.ActivityForm.ActivityName')}
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        id="sport"
                        variant="outlined"
                        fullWidth
                        select
                        name="sport"
                        required
                        data={sportState.data.sports}
                        label={t('Organisms.ActivityForm.Sport')}
                      />
                    </Grid>
                    <Field
                      variant="outlined"
                      fullWidth
                      id="description"
                      multiline
                      placeholder="Sem rozepiš průběh tréninku ..."
                      rows={5}
                      label={t('Organisms.ActivityForm.Description')}
                      name="description"
                    />
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        type="url"
                        fullWidth
                        id="tracking"
                        label={t('Organisms.ActivityForm.Tracking')}
                        placeholder="Vlož odkaz na tracking"
                        name="tracking"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        type="url"
                        fullWidth
                        id="map"
                        label={t('Organisms.ActivityForm.Doma')}
                        placeholder="Vlož odkaz na archiv DOMA"
                        name="map"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FeelingField
                        id="feeling"
                        name="feeling"
                        label={t('Organisms.ActivityForm.Feeling')}
                        defaultValue={2}
                        required
                        value={values.feeling}
                        onChange={(event, value) =>
                          setFieldValue('feeling', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Slider
                        className="ml-20"
                        id="exertion"
                        name="exertion"
                        label={t('Organisms.ActivityForm.Exertion')}
                        defaultValue={50}
                        value={values.exertion}
                        onChange={(event, value) =>
                          setFieldValue('exertion', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        variant="outlined"
                        fullWidth
                        type="text"
                        id="trUnitTime"
                        label={t('Organisms.ActivityForm.TrUnitTime')}
                        placeholder="HH:MM:SS"
                        name="trUnitTime"
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} sm={3}>
                      <RadioField
                        id="type"
                        name="type"
                        label={t('Organisms.ActivityForm.Type')}
                        color="primary"
                        data={[
                          {
                            value: 'T',
                            label: t('Organisms.ActivityForm.Training'),
                          },
                          {
                            value: 'R',
                            label: t('Organisms.ActivityForm.Race'),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        type="url"
                        fullWidth
                        id="result"
                        label={t('Organisms.ActivityForm.Results')}
                        placeholder="Vlož odkaz na výsledky"
                        name="result"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.enabledHR}
                              onChange={handleChangeEnableHR}
                              name="enabledHR"
                              id="enabledHR"
                              color="primary"
                            />
                          }
                          label={t('Organisms.ActivityForm.EnabledHR')}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="zone1"
                        type="text"
                        label={t('Organisms.ActivityForm.Zone1')}
                        placeholder="HH:MM:SS"
                        name="zone1"
                        disabled={!state.enabledHR}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="zone2"
                        type="text"
                        label={t('Organisms.ActivityForm.Zone2')}
                        placeholder="HH:MM:SS"
                        name="zone2"
                        disabled={!state.enabledHR}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="zone3"
                        type="text"
                        label={t('Organisms.ActivityForm.Zone3')}
                        placeholder="HH:MM:SS"
                        name="zone3"
                        disabled={!state.enabledHR}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="zone4"
                        type="text"
                        label={t('Organisms.ActivityForm.Zone4')}
                        placeholder="HH:MM:SS"
                        name="zone4"
                        disabled={!state.enabledHR}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="zone5"
                        type="text"
                        label={t('Organisms.ActivityForm.Zone5')}
                        placeholder="HH:MM:SS"
                        name="zone5"
                        disabled={!state.enabledHR}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={updateActivityState.isLoading}
                      >
                        {t('Organisms.ActivityForm.SaveActivity')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <SimpleAlertDialog
                        title={t(
                          'Organisms.ActivityForm.DeleteActivityConfirm',
                        )}
                        message={
                          t('Organisms.ActivityForm.DeleteActivityMessage') +
                          ' ' +
                          activity.name
                        }
                        handleConfirm={onDeleteMemoized}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
