import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

const marks = [
  {
    value: 0,
    label: 'veryEasy',
  },
  {
    value: 50,
    label: 'moderate',
  },
  {
    value: 100,
    label: 'allOut',
  },
];

function valuetext(value) {
  return `${value}`;
}

export const SliderWithLabel = ({ error, label, inputRef, ...inputProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const translatedMarks = marks.map((mark) => {
    return {
      value: mark.value,
      label: t('Molecules.SliderWithLabel.Marks.' + mark.label),
    };
  });

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-custom" gutterBottom>
        {label}
      </Typography>
      <Slider
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-custom"
        step={1}
        valueLabelDisplay="auto"
        marks={translatedMarks}
        {...inputProps}
      />
    </div>
  );
};
