import React from 'react';
import { Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import theme from 'theme';

export const Footer = () => {
  return (
    <>
      <Helmet>
        <style>
          {`
              .footer {
                background: ${theme.palette.primary.main};
                color: ${theme.palette.primary.contrastText};
              }
            `}
        </style>
      </Helmet>
      <div className="footer flex flex-middle">
        <div className="flex flex-middle container px-sm-30 w-100">
          <a
            href="http://www.orientacnisporty.cz/"
            className="mr-8 show-on-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="text-middle bottom-8"
              src="/assets/images/logos/logocsos_white.svg"
              alt="čsos"
              title="Orientační sporty"
              width="100px"
            />
          </a>
          <a
            href="https://www.strava.com/"
            className="mr-8 show-on-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="text-middle"
              src="/assets/images/logos/api_logo_pwrdBy_strava_stack_light.svg"
              alt="strava"
              title="Powered by STRAVA"
              width="100px"
            />
          </a>
          <span className="m-auto"></span>
          <p className="m-0">
            {'Copyright © '}{' '}
            <Link color="inherit" href="http://www.orientacnisporty.cz/">
              Český svaz orientačních sportů
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </p>
        </div>
      </div>
    </>
  );
};
