import React from 'react';
import { withRouter } from 'react-router-dom';

import { HeaderBaseLoggedIn } from '../molecules/HeaderBaseLoggedIn';
import PATHNAMES from '../pathnames';

const LoggedInHeaderBase = () => {
  return (
    <HeaderBaseLoggedIn homeLinkTo={PATHNAMES.dashboard()}></HeaderBaseLoggedIn>
  );
};

export const LoggedInHeader = withRouter(LoggedInHeaderBase);
