import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { ErrorBox } from '../../atoms';
import { Field, CheckBoxField, DatePickerField } from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
  formatDateTime,
} from '../../utils';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const EditSicknessForm = ({
  sicknessStateData,
  setSicknessStateData,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();
  const updateSicknessState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const enumSicknessState = useFetchRequest(ENDPOINTS.enumSicknessTypes());

  const {
    sickness_id: sicknessId,
    problem,
    type_id,
    date_from,
    date_to,
    description,
    alternative_training: alternativeTraining,
  } = sicknessStateData.data;

  const {
    object,
    requiredString,
    string,
    requiredDate,
    requiredNumber,
    boolean,
  } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({
      sicknessId,
      problem,
      type,
      date_from,
      date_to,
      description,
      alternativeTraining,
    }) => {
      updateSicknessState.request(ENDPOINTS.updateSicknesss(), {
        method: 'PUT',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          handleClose();
          enqueueSnackbar(t('Organisms.ActivityForm.UpdatedActivityAlert'), {
            variant: 'success',
          });
          setSicknessStateData({
            data,
          });
        },
        data: {
          sicknessId,
          problem,
          type,
          date_from,
          date_to,
          description,
          alternativeTraining,
        },
      });
    },
    [
      updateSicknessState,
      handleClose,
      enqueueSnackbar,
      t,
      setSicknessStateData,
    ],
  );

  const schema = object({
    problem: requiredString,
    type: requiredNumber,
    date_from: requiredDate,
    date_to: requiredDate.min(
      yup.ref('date_from'),
      "end date can't be before start date",
    ),
    description: string,
    alternativeTraining: boolean,
  });
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {t('Page.Sickness.SicknessDetailPage.Edit')}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Page.Sickness.SicknessDetailPage.EditSickness')} - {problem}
          <div className="card-subtitle">
            {formatDateTime(date_from)} - {formatDateTime(date_to)}
          </div>
        </DialogTitle>
        <DialogContent>
          <ErrorBox
            errorList={[
              { id: 1, error: enumSicknessState.error },
              { id: 2, error: updateSicknessState.error },
            ]}
          />
          {enumSicknessState.isLoading && <CircularProgress />}
          {enumSicknessState.data && (
            <Formik
              initialValues={{
                sicknessId: sicknessId,
                problem: problem,
                type: type_id,
                date_from: date_from,
                date_to: date_to,
                description: description,
                alternativeTraining: alternativeTraining ? true : false,
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, values }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        id="problem"
                        label={t('Page.Sickness.CreateSicknessPage.Problem')}
                        name="problem"
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        select
                        id="type"
                        label={t('Page.Sickness.CreateSicknessPage.Diagnosis')}
                        name="type"
                        data={enumSicknessState.data.sicknesses}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DatePickerField
                        fullWidth
                        required
                        type="date"
                        id="date_from"
                        name="date_from"
                        disableFuture
                        animateYearScrolling={true}
                        showTodayButton
                        label={t('Page.Sickness.CreateSicknessPage.DateFrom')}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        value={values.date_from}
                        onChange={(value) => setFieldValue('date_from', value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DatePickerField
                        fullWidth
                        required
                        type="date"
                        id="date_to"
                        name="date_to"
                        disableFuture
                        animateYearScrolling={true}
                        showTodayButton
                        label={t('Page.Sickness.CreateSicknessPage.DateTo')}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        value={values.date_to}
                        onChange={(value) => setFieldValue('date_to', value)}
                      />
                    </Grid>
                    <Field
                      variant="outlined"
                      fullWidth
                      id="description"
                      multiline
                      placeholder="Sem rozepiš omezení a průběh nemoci / zranění ..."
                      rows={5}
                      label={t('Organisms.ActivityForm.Description')}
                      name="description"
                    />
                    <CheckBoxField
                      value={values.alternativeTraining}
                      name="alternativeTraining"
                      id="alternativeTraining"
                      color="primary"
                      label={t(
                        'Page.Sickness.CreateSicknessPage.AlternativeTraining',
                      )}
                    />
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={updateSicknessState.isLoading}
                      >
                        {t('Page.Sickness.CreateSicknessPage.SaveSickness')}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => history.goBack()}
                        fullWidth
                        variant="outlined"
                      >
                        {t('Page.Settings.ZoneSettings.Cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
