import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import CardActions from '@material-ui/core/CardActions';

import { ActivityForm } from '../organisms';
import { formatDateTime } from '../utils';

export const TrainingCard2 = ({ theme, ...activity }) => {
  return (
    <Grid item xs={12} md={6}>
      <Card className="play-card p-sm-24 bg-paper" elevation={6}>
        <div className="flex flex-middle">
          <DirectionsRunIcon
            style={{
              fontSize: '44px',
              opacity: 0.6,
              color: theme.palette.primary.main,
            }}
          />
          <div className="ml-12">
            <small className="text-muted">
              {formatDateTime(activity.start_date)}
            </small>
            <h6 className="m-0 mt-4 text-primary font-weight-500">
              {activity.name}
            </h6>
          </div>
        </div>
        <CardActions>
          <ActivityForm {...activity} />
        </CardActions>
      </Card>
    </Grid>
  );
};
