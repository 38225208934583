import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { TrainingCard2 } from '../../organisms';
import Pagination from '@material-ui/lab/Pagination';
import ENDPOINTS from '../../endpoints';
import { useRequest } from '../../utils';

export const LayoutedActivities = ({ activityList, theme }) => {
  const [page, setPage] = useState(1);
  const refreshActivityList = useRequest();
  const [activityListState, setActivityListState] = useState({
    activityList: activityList,
  });

  const removeActivity = (activityId) => {
    setActivityListState((prevState) => {
      return {
        ...prevState,
        activityList: {
          ...prevState.activityList,
          pageOfItems: activityListState.activityList.pageOfItems.filter(
            (item) => item.activity_id !== activityId,
          ),
        },
      };
    });
  };

  const updateActivityState = useCallback(({ data: { results: { data } } }) => {
    setActivityListState({
      activityList: data,
    });
  }, []);

  const fetchUnidentifiedActivity = (pageValue) => {
    // get page of items from api
    const page = parseInt(pageValue);
    if (page !== activityListState.activityList.pager.currentPage) {
      refreshActivityList.request(ENDPOINTS.getUnidentifiedActivity(page), {
        method: 'GET',
        onSuccess: (response) => updateActivityState(response),
      });
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchUnidentifiedActivity(value);
  };

  return (
    <Grid container spacing={2} className="mb-24 mt-16">
      {activityListState.activityList.pageOfItems.map((activity) => (
        <>
          <TrainingCard2
            key={activity.activity_id}
            {...activity}
            removeActivity={removeActivity}
            theme={theme}
          />
        </>
      ))}
      {activityListState.activityList.pager.totalPages > 1 && (
        <div className="flex flex-end w-100 mt-12">
          <Pagination
            className="p-8 flex"
            count={activityListState.activityList.pager.totalPages}
            variant="outlined"
            color="primary"
            onChange={handlePageChange}
          />
        </div>
      )}
    </Grid>
  );
};
