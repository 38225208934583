import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CircularProgress, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SendIcon from '@material-ui/icons/Send';

import { ErrorBox } from '../../atoms';
import { Field, RadioField, DatePickerField } from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
} from '../../utils';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
}));

export const BaseSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    object,
    requiredString,
    string,
    requiredEmail,
    requiredCsosRegNum,
    date,
  } = translatedValidations(t);
  const { enqueueSnackbar } = useSnackbar();

  const settingsProfileState = useFetchRequest(ENDPOINTS.getSettings());
  const updateBaseSettingsState = useRequest();

  const onSubmitMemoized = useCallback(
    ({ firstName, lastName, nickName, regNum, email, birth, sex }) => {
      updateBaseSettingsState.request(ENDPOINTS.updateUserSettings(), {
        method: 'PUT',
        onSuccess: () => {
          enqueueSnackbar(t('Organisms.ActivityForm.UpdatedActivityAlert'), {
            variant: 'success',
          });
        },
        data: {
          firstName,
          lastName,
          nickName,
          regNum,
          email,
          birth,
          sex,
        },
      });
    },
    [updateBaseSettingsState, enqueueSnackbar, t],
  );

  const schema = object({
    firstName: requiredString,
    lastName: requiredString,
    nickName: string,
    email: requiredEmail,
    regNum: requiredCsosRegNum,
    birth: date,
    sex: requiredString,
  });
  return (
    <Card elevation={3} className="p-20">
      <div className="card-title mb-12">
        {t('Page.Settings.BaseSettings.BaseSettingsTitle')}
      </div>
      <ErrorBox
        errorList={[
          { id: 1, error: settingsProfileState.error },
          { id: 2, error: updateBaseSettingsState.error },
        ]}
      />
      {settingsProfileState.isLoading && <CircularProgress />}
      {settingsProfileState.data && (
        <Formik
          initialValues={{
            firstName: settingsProfileState.data.firstname,
            lastName: settingsProfileState.data.lastname,
            nickName: settingsProfileState.data.nickname,
            email: settingsProfileState.data.email,
            regNum: settingsProfileState.data.regnum,
            birth: settingsProfileState.data.birth,
            sex: settingsProfileState.data.sex,
          }}
          validationSchema={schema}
          onSubmit={onSubmitMemoized}
        >
          {({ setFieldValue, values }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label={t('User.Firstname')}
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label={t('User.Lastname')}
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    fullWidth
                    id="nickName"
                    label={t('User.Nickname')}
                    name="nickName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    required
                    disabled
                    fullWidth
                    id="email"
                    label={t('User.Email')}
                    name="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="regNum"
                    label={t('User.CsosRegistration')}
                    name="regNum"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerField
                    fullWidth
                    type="date"
                    id="birth"
                    name="birth"
                    disableFuture
                    animateYearScrolling={true}
                    showTodayButton
                    label={t('Page.Settings.AthleteSettings.DateOfBirth')}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="dd/mm/yyyy"
                    value={values.birth}
                    onChange={(value) => setFieldValue('birth', value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RadioField
                    row
                    required
                    id="sex"
                    name="sex"
                    label={t('Page.Settings.AthleteSettings.Sex')}
                    color="primary"
                    data={[
                      {
                        value: 'M',
                        label: t('Page.Settings.AthleteSettings.Male'),
                      },
                      {
                        value: 'F',
                        label: t('Page.Settings.AthleteSettings.Female'),
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={updateBaseSettingsState.isLoading}
                    >
                      <SendIcon /> {t('Page.Settings.BaseSettings.Save')}
                    </Button>
                    {updateBaseSettingsState.isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};
