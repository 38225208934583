import MockAdapter from 'axios-mock-adapter';
import { mocks } from './mock';
import ENDPOINTS from '../endpoints';

const MOCK_API_DELAY = 500;

export function installApiMocks(api) {
  const mockAdapter = new MockAdapter(api, { delayResponse: MOCK_API_DELAY });

  // login
  mockAdapter.onPost(ENDPOINTS.login()).reply(200, {
    token: 'mock-token',
    user: mocks.users[0],
  });

  // unidentified activity
  mockAdapter
    .onGet(ENDPOINTS.getUnidentifiedActivity())
    .reply(200, { data: mocks.unidentifiedActivity });

  // enumSports
  mockAdapter.onGet(ENDPOINTS.enumSports()).reply(200, {
    message: 'OK',
    error: false,
    code: 200,
    results: {
      data: {
        sports: [
          { label: 'Orienteering', value: 1 },
          { label: 'MTBO', value: 2 },
          { label: 'LOB', value: 3 },
        ],
      },
    },
  });

  // all other
  mockAdapter.onAny().passThrough();
}
