import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Link from '@material-ui/core/Link';
import Scrollbar from 'react-perfect-scrollbar';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';
import { ErrorBox } from '../../atoms';
import { CopyrightWithBox } from '../../molecules';
import { useRequest } from '../../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [
  'Datové oprávnění',
  'Kontrola soukromí',
  'Nastavení tréninkových zón',
  'Oprávnění pro Tréninkáč',
];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <DataPermission />;
    case 1:
      return <PrivacyControls />;
    case 2:
      return <ZoneSettings />;
    case 3:
      return <StravaPermissions />;
    default:
      throw new Error('Unknown step');
  }
}

const DataPermission = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Datové oprávnění
      </Typography>
      <Typography variant="subtitle1">
        Zkontrolujte povolení pro zpracování zdravotních dat jako jsou data
        srdečního tepu. Toto oprávnění je vyžadováno pro správnou funkčnost
        Tréninkáče.
      </Typography>
      <Typography variant="subtitle1">
        Pro více informací prostudujte{' '}
        <Link href="https://treninkac.orientacnisporty.cz/napoveda/doku.php#registrace_a_nastaveni_profilu_na_strave">
          nápovědu
        </Link>
        .
      </Typography>

      <img
        src="/assets/images/screenshots/heart_rate_access.jpg"
        alt="Allow Heart Rate"
        title="Allow heart rate access"
        className="m-12 w-100"
      />
    </>
  );
};

const PrivacyControls = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Kontrola soukromí
      </Typography>
      <Typography variant="subtitle1">
        Vaše aktivity nemusí být veřejné. Pokud chcete, můžete je nechat
        viditelné pouze pro sebe. Obrázek ukazuje jak nastavit co největší
        soukromí.
      </Typography>
      <Typography variant="subtitle1">
        Pro více informací prostudujte{' '}
        <Link href="https://treninkac.orientacnisporty.cz/napoveda/doku.php#registrace_a_nastaveni_profilu_na_strave">
          nápovědu
        </Link>
        .
      </Typography>

      <img
        src="/assets/images/screenshots/strava_privacy_control.jpg"
        alt="Privacy control"
        title="Privacy control"
        className="m-12 w-100"
      />
    </>
  );
};

const ZoneSettings = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Nastavení tréninkových zón
      </Typography>
      <Typography variant="subtitle1">
        Tak abychom mohli Vaše aktivity ze Stravy třídit podle intenzity,
        potřebujeme znát Vaše tréninkové zóny. Pokud chcete tuto funkci
        využívat, nastavte zóny ještě před propojením se Stravou v
        <Link href={PATHNAMES.settings()} target="_blank">
          nastavení Tréninkáče
        </Link>
        .
      </Typography>
      <Typography variant="subtitle1">
        Pro více informací prostudujte{' '}
        <Link href="https://treninkac.orientacnisporty.cz/napoveda/doku.php#registrace_a_nastaveni_profilu_na_strave">
          nápovědu
        </Link>
        .
      </Typography>

      <img
        src="/assets/images/screenshots/settings.jpg"
        alt="Settings"
        title="Settings"
        className="m-12 w-100"
      />
    </>
  );
};

const StravaPermissions = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Oprávnění pro Tréninkáč
      </Typography>
      <Typography variant="subtitle1">
        Pro správné propojení se Stravou vyžaduje Tréninkáč oprávnění na Vaše
        soukromé aktivity, tzn. obě zátržítka nechat označené.
      </Typography>
      <Typography variant="subtitle1">
        Pro více informací prostudujte{' '}
        <Link href="https://treninkac.orientacnisporty.cz/napoveda/doku.php#registrace_a_nastaveni_profilu_na_strave">
          nápovědu
        </Link>
        .
      </Typography>

      <img
        src="/assets/images/screenshots/strava_permissions.jpg"
        alt="Strava permissions"
        title="Strava permissions"
        width="400px"
        className="m-12"
      />
    </>
  );
};

export const StravaGuidePage = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const stravaAcessState = useRequest();

  const onSuccess = useCallback(({ data: { results } }) => {
    window.location = results.data;
  }, []);

  const onStravaClick = useCallback(() => {
    stravaAcessState.request(ENDPOINTS.stravaAuthorization(), {
      method: 'GET',
      onSuccess,
    });
  }, [stravaAcessState, onSuccess]);

  const errorList = [{ id: 1, error: stravaAcessState.error }];

  return (
    <Scrollbar className="scrollable-content">
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Průvodce nastavení účtu na síti STRAVA
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for connection.
                </Typography>
                <ErrorBox errorList={errorList} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      {t('Page.Upload.StravaGuide.Back')}
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onStravaClick}
                      className={classes.button}
                    >
                      {t('Page.Upload.StravaGuide.Connect')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {t('Page.Upload.StravaGuide.Next')}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <CopyrightWithBox />
      </Container>
    </Scrollbar>
  );
};
