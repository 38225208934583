export const getTotals = (data, key, kind) => {
  let total = 0;
  data.forEach((item) => {
    if (kind && typeof kind === 'string') {
      if (item.kind === kind) {
        total += item[key];
      }
    }
    if (!kind && kind === undefined) {
      total += item[key];
    }
  });
  return total;
};

export const getTotalsInWeek = (data, key, week, kind) => {
  let total = 0;
  data.forEach((item) => {
    if (item.week === week) {
      if (kind && typeof kind === 'string') {
        if (item.kind === kind) {
          total += item[key];
        }
      }
      if (!kind && kind === undefined) {
        total += item[key];
      }
    }
  });
  return total;
};

export const getTotalsInCycle = (data, key, kind) => {
  let total = 0;
  data.forEach((item) => {
    if (kind && typeof kind === 'string') {
      if (item[0].kind === kind) {
        total += item[0][key];
      }
    } else if (!kind && kind === undefined) {
      if (item && item.length > 0) {
        total += item[0][key];
      } else {
        total += item[key];
      }
    }
  });
  return total;
};

export const getTotalsInCycleSum = (data, key, kind, sport) => {
  let total = 0;
  data.map((cycle) => {
    cycle.map((item) => {
      if (
        kind &&
        typeof kind === 'string' &&
        !sport &&
        typeof sport === 'undefined'
      ) {
        if (item.kind === kind) {
          total += item[key];
        }
      }
      if (
        !kind &&
        kind === undefined &&
        !sport &&
        typeof sport === 'undefined'
      ) {
        total += item[key];
      }
      if (sport && typeof sport === 'number') {
        if (item.type_id === sport) {
          total += item[key];
        }
      }
    });
  });
  return total;
};
