import { useCallback } from 'react';
import ENDPOINTS from '../../endpoints';
import { useFetchRequest } from '../../utils';

export const useFetchData = (setAthleteStatisticsState) => {
  const onSuccessFetchAthleteStatisticsState = (response) => {
    updateAthleteStatisticsState(response);
  };
  const fetchSeasonState = useFetchRequest(ENDPOINTS.getSeasonPeriod());
  const unidentifiedActivityState = useFetchRequest(
    ENDPOINTS.getUnidentifiedActivity(1),
  );
  const athleteSettingsState = useFetchRequest(
    ENDPOINTS.checkAthleteSettings(),
  );
  const fetchAthleteStatisticsState = useFetchRequest(
    ENDPOINTS.getAthleteStatistics(),
    {
      onSuccess: onSuccessFetchAthleteStatisticsState,
    },
  );

  const enumSportState = useFetchRequest(ENDPOINTS.enumSportTypes());

  const updateAthleteStatisticsState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setAthleteStatisticsState({
        data,
      });
    },
    [setAthleteStatisticsState],
  );

  return {
    fetchSeasonState,
    unidentifiedActivityState,
    athleteSettingsState,
    fetchAthleteStatisticsState,
    enumSportState,
    updateAthleteStatisticsState,
  };
};
