import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { ErrorBox, TextMaskCustom } from '../atoms';
import { SimpleAlertDialog } from '../molecules';
import {
  AutoSuggest,
  Field,
  FeelingField,
  Slider,
  RadioField,
} from '../organisms';
import {
  useRequest,
  translatedValidations,
  formatSecondsToTime,
  formatDateTime,
  secondsToHms,
  convertToKM,
  validateTimeInZones,
  validatePace,
} from '../utils';
import ENDPOINTS from '../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const ActivityForm = ({ removeActivity, ...activity }) => {
  const [open, setOpen] = useState(false);
  const [enumSport, setEnumSport] = useState(undefined);
  const [enumTag, setEnumTag] = useState(undefined);
  const [activityLap, setActivityLap] = useState(undefined);
  const [removeCount, setRemoveCount] = useState(0);
  const [subActivityValues, setSubActivityValues] = useState([
    {
      lap: '',
      type: '',
      timeOffset: '00:00:00',
      startIndex: 0,
      time: secondsToHms(activity.moving_time) || '',
      distance: convertToKM(activity.distance) || '',
      pace: secondsToHms(activity.pace) || '',
      avgHr: activity.average_hr || '',
      maxHr: activity.max_hr || '',
      avgCadence: activity.avg_cadence || '',
      elevationGain: activity.elevation_gain || '',
      enableHrZ: false,
      zone1: secondsToHms(activity.time_zone1) || '',
      zone2: secondsToHms(activity.time_zone2) || '',
      zone3: secondsToHms(activity.time_zone3) || '',
      zone4: secondsToHms(activity.time_zone4) || '',
      zone5: secondsToHms(activity.time_zone5) || '',
      tag: [],
    },
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();
  const classes = useStyles();
  const updateActivityState = useRequest();
  const deleteActivityState = useRequest();
  const enumSportState = useRequest();
  const activityLapState = useRequest();
  const enumTagState = useRequest();
  const subActivityState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const updateEnumSportState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setEnumSport({
        data,
      });
    },
    [],
  );

  const updateActivityLapState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setActivityLap({
        data: {
          laps: data.laps.map((lap) => {
            return {
              label:
                lap.label +
                ' ' +
                (formatSecondsToTime(lap.moving_time) || '00:00:00') +
                (lap.distance
                  ? ' / ' +
                    Number.parseFloat(lap.distance / 1000).toFixed(1) +
                    'km'
                  : ''),
              value: lap.value,
              startIndex: lap.start_index,
              timeOffset: lap.time_offset,
            };
          }),
        },
      });
    },
    [],
  );

  const updateEnumTagState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setEnumTag({
        data,
      });
    },
    [],
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
    enumSportState.request(ENDPOINTS.enumSportTypes(), {
      method: 'GET',
      onSuccess: (response) => updateEnumSportState(response),
    });
    enumTagState.request(ENDPOINTS.enumTags(), {
      method: 'GET',
      onSuccess: (response) => updateEnumTagState(response),
    });
    activityLapState.request(ENDPOINTS.getActivityLap(activity.activity_id), {
      method: 'GET',
      onSuccess: (response) => updateActivityLapState(response),
    });
  }, [
    enumSportState,
    updateEnumSportState,
    enumTagState,
    updateEnumTagState,
    activityLapState,
    activity.activity_id,
    updateActivityLapState,
  ]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const {
    object,
    requiredString,
    string,
    number,
    requiredNumber,
    url,
    time,
    requiredTime,
  } = translatedValidations(t);

  const [hrLockState, setHrLockState] = useState({
    enabledHR: false,
  });

  const handleChangeEnableHR = () => {
    setHrLockState({
      enabledHR: !hrLockState.enabledHR,
    });
  };

  const onSubmitMemoized = useCallback(
    ({
      activityId,
      name,
      description,
      tracking,
      map,
      result,
      feeling,
      exertion,
      calories,
      temperature,
      weather,
      subactivity,
      type,
    }) => {
      updateActivityState.request(ENDPOINTS.updateActivity(), {
        method: 'PUT',
        onSuccess: () => {
          handleClose();
          removeActivity(activityId);
          enqueueSnackbar(t('Organisms.ActivityForm.UpdatedActivityAlert'), {
            variant: 'success',
          });
        },
        data: {
          activityId,
          name,
          description,
          tracking,
          map,
          result,
          feeling,
          exertion,
          calories,
          temperature,
          weather,
          subactivity,
          type,
        },
      });
    },
    [updateActivityState, handleClose, removeActivity, enqueueSnackbar, t],
  );

  const lapHandleChange = (
    e,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
  ) => {
    const index = parseInt(e.target.name.split('.')[1]);
    const lap = activityLap.data.laps.find(
      (lap) => lap.value === e.target.value,
    );

    if (values.subactivity.length > 1) {
      if (values.subactivity[index - 1]) {
        const ts1 = values.subactivity[index - 1].timeOffset.split(':');
        const time1 = Date.UTC(1970, 0, 1, ts1[0], ts1[1], ts1[2]) / 1000;
        //TODO: nepoporovnávat index s časem
        if (lap.timeOffset <= time1) {
          enqueueSnackbar(
            t('Organisms.ActivityForm.SubactivityStartEarlierThanPrevious'),
            {
              variant: 'warning',
            },
          );
          return;
        }
      }
      if (values.subactivity[index + 1]) {
        const ts2 = values.subactivity[index + 1].timeOffset.split(':');
        const time2 = Date.UTC(1970, 0, 1, ts2[0], ts2[1], ts2[2]) / 1000;
        //TODO: nepoporovnávat index s časem
        if (lap.timeOffset >= time2) {
          enqueueSnackbar(
            t('Organisms.ActivityForm.SubactivityStartLaterThanNext'),
            {
              variant: 'warning',
            },
          );
          return;
        }
      }
    }
    handleChange(e);
    setFieldValue(`subactivity.${index}.timeOffset`, '');
    setTimeout(() => setFieldTouched(`subactivity.${index}.timeOffset`, true));
    values.subactivity[index].lap = e.target.value;
    values.subactivity[index].startIndex = lap.startIndex;
    values.subactivity[index].timeOffset = '';
    getSubActivityValues(values);
  };

  const timeOffsetHandleChange = (e, setFieldValue, values) => {
    const index = parseInt(e.target.name.split('.')[1]);
    if (values.subactivity.length > 1) {
      const ts3 = values.subactivity[index].timeOffset.split(':');
      const time3 = Date.UTC(1970, 0, 1, ts3[0], ts3[1], ts3[2]) / 1000;
      if (values.subactivity[index - 1]) {
        const ts1 = values.subactivity[index - 1].timeOffset.split(':');
        const time1 = Date.UTC(1970, 0, 1, ts1[0], ts1[1], ts1[2]) / 1000;
        if (time3 <= time1) {
          enqueueSnackbar(
            t('Organisms.ActivityForm.SubactivityStartEarlierThanPrevious'),
            {
              variant: 'warning',
            },
          );
          return;
        }
      }
      if (values.subactivity[index + 1]) {
        const ts2 = values.subactivity[index + 1].timeOffset.split(':');
        const time2 = Date.UTC(1970, 0, 1, ts2[0], ts2[1], ts2[2]) / 1000;
        if (time3 >= time2) {
          enqueueSnackbar(
            t('Organisms.ActivityForm.SubactivityStartLaterThanNext'),
            {
              variant: 'warning',
            },
          );
          return;
        }
      }
    }
    setFieldValue(`subactivity.${index}.lap`, '');
    values.subactivity[index].startIndex = '';
    values.subactivity[index].lap = '';
    getSubActivityValues(values);
  };

  const updateSubActivityValues = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setSubActivityValues(data);
    },
    [],
  );

  const getSubActivityValues = (values) => {
    subActivityState.request(ENDPOINTS.getSubActivityValues(), {
      method: 'POST',
      onSuccess: (response) => updateSubActivityValues(response),
      data: {
        activityId: activity.activity_id,
        subactivity: values.subactivity,
      },
    });
  };

  const AutoSubmitToken = () => {
    // Grab values and submitForm from context
    const { values } = useFormikContext();
    useEffect(() => {
      if (removeCount > 0) {
        console.log(values);
      }
    }, [values]);
    return null;
  };

  const BusinessLogic = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.subactivity.length > 1 && values.subactivity[1].lap) {
        console.log(values.subactivity[1].timeOffset);
        subActivityState.request(ENDPOINTS.getSubActivityValues(), {
          method: 'POST',
          onSuccess: (response) => console.log(response),
          data: {
            activityId: activity.activity_id,
            subactivity: values.subactivity,
          },
        });
      }
    }, [values]);
    return null;
  };

  const weatherOptions = [
    { value: 'clear', label: 'jasno' },
    { value: 'clouds', label: 'oblačno' },
    { value: 'drizzle', label: 'mrholení' },
    { value: 'rain', label: 'déšť' },
    { value: 'thunderstorm', label: 'bouřka' },
    { value: 'snow', label: 'sníh' },
    { value: 'mist', label: 'mlha' },
  ];

  const schema = object({
    name: requiredString,
    description: string,
    type: requiredString,
    tracking: url,
    map: url,
    result: url,
    exertion: number,
    feeling: number,
    calories: number,
    temperature: number,
    weather: string,
    subactivity: Yup.array()
      .of(
        Yup.object().shape({
          lap: number,
          timeOffset: requiredTime,
          type: requiredNumber,
          time: requiredTime,
          distance: number,
          pace: time.test(
            'pace-time-distance',
            t('Organisms.ActivityForm.PaceValidity'),
            validatePace,
          ),
          avgHr: number,
          avgCadence: number,
          elevationGain: number,
          tag: Yup.array().of(
            Yup.object().shape({
              value: requiredNumber,
              title: requiredString,
            }),
          ),
          zone1: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone2: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone3: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone4: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone5: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
        }),
      )
      .min(1, 'The activity must have atleast one subactivity'),
  });

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        className="bg-white"
        onClick={handleClickOpen}
      >
        {t('Organisms.ActivityForm.EditActivity')}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Organisms.ActivityForm.EditActivity')} - {activity.name}
          <div className="card-subtitle">
            {formatDateTime(activity.start_date)}
          </div>
          <div className="flex">
            <div className="px-8 text-11 text-small py-0 border-radius-4 bg-light-primary mr-8 mt-4">
              {formatSecondsToTime(activity.moving_time)}
            </div>
            <div className="px-8 text-11 text-small py-0 border-radius-4 bg-light-primary mr-8 mt-4">
              {activity.distance && convertToKM(activity.distance) + ' km'}
            </div>
          </div>
          {activity.source === 'STRAVA' && (
            <div className="my-8">
              <Chip
                size="small"
                label={activity.source}
                color="secondary"
                variant="outlined"
                href={
                  'https://www.strava.com/activities/' + activity.external_id
                }
                component="a"
                clickable
                target="_blank"
              />
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <>
            {(enumSportState.isLoading || enumTagState.isLoading) && (
              <CircularProgress />
            )}
            <ErrorBox
              errorList={[
                { id: 1, error: enumSportState.error },
                { id: 2, error: enumTagState.error },
                { id: 3, error: updateActivityState.error },
                { id: 4, error: activityLapState.error },
              ]}
            />
            {typeof enumSport !== 'undefined' &&
              typeof enumTag !== 'undefined' &&
              typeof activityLap !== 'undefined' &&
              typeof subActivityValues !== 'undefined' &&
              enumSport.data &&
              enumTag.data &&
              activityLap.data && (
                <>
                  {!activity.has_heartrate && (
                    <Alert variant="outlined" severity="info">
                      {t(
                        'Page.TrainingLog.ActivityDetailPage.NoHeartRateMonitor',
                      )}
                    </Alert>
                  )}
                  {enumSport.data && enumTag.data && (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        activityId: activity.activity_id,
                        name: activity.name,
                        description: activity.description || '',
                        tracking: activity.tracking || '',
                        map: activity.doma || '',
                        result: activity.result || '',
                        calories: activity.calories || '',
                        temperature: activity.temperature || '',
                        weather: activity.weather || '',
                        type: activity.race === true ? 'R' : 'T',
                        feeling: activity.feeling || 0,
                        exertion: activity.exertion || 50,
                        subactivity: subActivityValues || [],
                      }}
                      validationSchema={schema}
                      onSubmit={onSubmitMemoized}
                    >
                      {({
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        values,
                        errors,
                        touched,
                      }) => (
                        <Form className={classes.form}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Field
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label={t('Organisms.ActivityForm.ActivityName')}
                                name="name"
                                inputProps={{ maxLength: 255 }}
                              />
                            </Grid>
                            <Field
                              variant="outlined"
                              fullWidth
                              id="description"
                              multiline
                              placeholder={t(
                                'Organisms.ActivityForm.DescribeTrainingProcess',
                              )}
                              minRows={5}
                              label={t('Organisms.ActivityForm.Description')}
                              name="description"
                            />
                            <Grid item xs={12} sm={6}>
                              <Field
                                variant="outlined"
                                type="url"
                                fullWidth
                                id="tracking"
                                label={t('Organisms.ActivityForm.Tracking')}
                                placeholder={t(
                                  'Organisms.ActivityForm.InsertTrackingLink',
                                )}
                                name="tracking"
                                inputProps={{ maxLength: 255 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                variant="outlined"
                                type="url"
                                fullWidth
                                id="map"
                                label={t('Organisms.ActivityForm.Doma')}
                                placeholder={t(
                                  'Organisms.ActivityForm.InsertMapLink',
                                )}
                                name="map"
                                inputProps={{ maxLength: 255 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                variant="outlined"
                                type="url"
                                fullWidth
                                id="result"
                                label={t('Organisms.ActivityForm.Results')}
                                placeholder={t(
                                  'Organisms.ActivityForm.InsertResultsLink',
                                )}
                                name="result"
                                inputProps={{ maxLength: 255 }}
                              />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                              <Field
                                variant="outlined"
                                fullWidth
                                id="calories"
                                type="number"
                                label={t('Organisms.ActivityForm.Calories')}
                                placeholder="Calories"
                                name="calories"
                                InputProps={{
                                  inputProps: { min: 0, step: 'any' },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      kCal
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                              <Field
                                variant="outlined"
                                fullWidth
                                id="temperature"
                                type="number"
                                label={t('Organisms.ActivityForm.Temperature')}
                                placeholder={t(
                                  'Organisms.ActivityForm.WorkoutWeather',
                                )}
                                name="temperature"
                                InputProps={{
                                  inputProps: {
                                    min: -40,
                                    max: 40,
                                    step: 'any',
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      °C
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                              <Field
                                variant="outlined"
                                fullWidth
                                id="weather"
                                select
                                data={weatherOptions}
                                label={t('Organisms.ActivityForm.Weather')}
                                name="weather"
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <FeelingField
                                id="feeling"
                                name="feeling"
                                label={t('Organisms.ActivityForm.Feeling')}
                                defaultValue={2}
                                required
                                value={values.feeling}
                                onChange={(event, value) =>
                                  setFieldValue('feeling', value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Slider
                                className="ml-20"
                                id="exertion"
                                name="exertion"
                                label={t('Organisms.ActivityForm.Exertion')}
                                defaultValue={50}
                                value={values.exertion}
                                onChange={(event, value) =>
                                  setFieldValue('exertion', value)
                                }
                              />
                            </Grid>
                            <Grid item xs={5} sm={3}>
                              <RadioField
                                id="type"
                                name="type"
                                label={t('Organisms.ActivityForm.Type')}
                                color="primary"
                                data={[
                                  {
                                    value: 'T',
                                    label: t('Organisms.ActivityForm.Training'),
                                  },
                                  {
                                    value: 'R',
                                    label: t('Organisms.ActivityForm.Race'),
                                  },
                                ]}
                              />
                            </Grid>
                          </Grid>
                          <FieldArray
                            name="subactivity"
                            render={(arrayHelpers) => (
                              <div>
                                {values.subactivity &&
                                  values.subactivity.length > 0 &&
                                  values.subactivity.map((element, index) => (
                                    <div
                                      key={index}
                                      className="my-20 p-16 bg-light-stripped-primary"
                                    >
                                      {values.subactivity.length > 1 && (
                                        <h5>
                                          {t(
                                            'Organisms.ActivityForm.SubActivity',
                                          )}{' '}
                                          {index + 1}
                                        </h5>
                                      )}
                                      <Grid container spacing={2}>
                                        {values.subactivity.length > 1 && (
                                          <>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                fullWidth
                                                id={`subactivity.${index}.lap`}
                                                variant="outlined"
                                                select
                                                disabled={index === 0}
                                                name={`subactivity.${index}.lap`}
                                                data={activityLap.data.laps}
                                                label={t(
                                                  'Organisms.ActivityForm.Lap',
                                                )}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  lapHandleChange(
                                                    e,
                                                    handleChange,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    values,
                                                  );
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={index === 0}
                                                type="text"
                                                id={`subactivity.${index}.timeOffset`}
                                                label={t(
                                                  'Organisms.ActivityForm.TimeOffset',
                                                )}
                                                placeholder="HH:MM:SS"
                                                name={`subactivity.${index}.timeOffset`}
                                                InputProps={{
                                                  inputComponent:
                                                    TextMaskCustom,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      h:m:s
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                InputLabelProps={{
                                                  shrink: values.subactivity[
                                                    index
                                                  ].timeOffset
                                                    ? true
                                                    : false,
                                                }}
                                                onBlur={(e) =>
                                                  timeOffsetHandleChange(
                                                    e,
                                                    setFieldValue,
                                                    values,
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6}></Grid>
                                          </>
                                        )}
                                        <Grid item xs={12} sm={3}>
                                          <Field
                                            fullWidth
                                            required
                                            id={`subactivity.${index}.type`}
                                            variant="outlined"
                                            select
                                            name={`subactivity.${index}.type`}
                                            data={enumSport.data.sports}
                                            label={t(
                                              'Organisms.ActivityForm.Sport',
                                            )}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                          <Field
                                            variant="outlined"
                                            fullWidth
                                            required
                                            type="text"
                                            id={`subactivity.${index}.time`}
                                            label={t(
                                              'Organisms.ActivityForm.Time',
                                            )}
                                            placeholder="HH:MM:SS"
                                            name={`subactivity.${index}.time`}
                                            InputProps={{
                                              inputComponent: TextMaskCustom,
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  h:m:s
                                                </InputAdornment>
                                              ),
                                            }}
                                            InputLabelProps={{
                                              shrink: values.subactivity[index]
                                                .time
                                                ? true
                                                : false,
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                          <Field
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            id={`subactivity.${index}.distance`}
                                            label={t(
                                              'Organisms.ActivityForm.Distance',
                                            )}
                                            name={`subactivity.${index}.distance`}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  km
                                                </InputAdornment>
                                              ),
                                              inputProps: {
                                                min: 0,
                                                step: 'any',
                                              },
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                          <Field
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            id={`subactivity.${index}.pace`}
                                            label={t(
                                              'Organisms.ActivityForm.Pace',
                                            )}
                                            placeholder="HH:MM:SS"
                                            name={`subactivity.${index}.pace`}
                                            InputProps={{
                                              inputComponent: TextMaskCustom,
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  m:s
                                                </InputAdornment>
                                              ),
                                            }}
                                            InputLabelProps={{
                                              shrink: values.subactivity[index]
                                                .pace
                                                ? true
                                                : false,
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                          <AutoSuggest
                                            options={enumTag.data.tags}
                                            variant="outlined"
                                            fullWidth
                                            filterSelectedOptions
                                            multiple
                                            id={`subactivity.${index}.tag`}
                                            name={`subactivity.${index}.tag`}
                                            placeholder={t(
                                              'Organisms.ActivityForm.TagPlaceholder',
                                            )}
                                            label={t(
                                              'Organisms.ActivityForm.Tag',
                                            )}
                                            value={
                                              values.subactivity[index].tag
                                            }
                                            onChange={(event, value) =>
                                              setFieldValue(
                                                `subactivity.${index}.tag`,
                                                value,
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                id={`subactivity.${index}.avgHr`}
                                                label={t(
                                                  'Organisms.ActivityForm.AvgHr',
                                                )}
                                                name={`subactivity.${index}.avgHr`}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      bpm
                                                    </InputAdornment>
                                                  ),
                                                  inputProps: {
                                                    min: 0,
                                                    step: 'any',
                                                  },
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                id={`subactivity.${index}.avgCadence`}
                                                label={t(
                                                  'Organisms.ActivityForm.Cadence',
                                                )}
                                                name={`subactivity.${index}.avgCadence`}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      rpm
                                                    </InputAdornment>
                                                  ),
                                                  inputProps: {
                                                    min: 0,
                                                    step: 'any',
                                                  },
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <FormGroup row>
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    hrLockState.enabledHR
                                                  }
                                                  onChange={
                                                    handleChangeEnableHR
                                                  }
                                                  name={`subactivity.${index}.enableHrZ`}
                                                  id={`subactivity.${index}.enableHrZ`}
                                                  color="primary"
                                                />
                                              }
                                              label={t(
                                                'Organisms.ActivityForm.EnabledHR',
                                              )}
                                            />
                                          </FormGroup>
                                        </Grid>
                                        <Collapse
                                          in={hrLockState.enabledHR}
                                          timeout="auto"
                                          style={{ width: '100%' }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                id={`subactivity.${index}.zone1`}
                                                type="text"
                                                label={t(
                                                  'Organisms.ActivityForm.Zone1',
                                                )}
                                                placeholder="HH:MM:SS"
                                                name={`subactivity.${index}.zone1`}
                                                disabled={
                                                  !hrLockState.enabledHR
                                                }
                                                InputProps={{
                                                  inputComponent:
                                                    TextMaskCustom,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      h:m:s
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                InputLabelProps={{
                                                  shrink: values.subactivity[
                                                    index
                                                  ].zone1
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                id={`subactivity.${index}.zone2`}
                                                type="text"
                                                label={t(
                                                  'Organisms.ActivityForm.Zone2',
                                                )}
                                                placeholder="HH:MM:SS"
                                                name={`subactivity.${index}.zone2`}
                                                disabled={
                                                  !hrLockState.enabledHR
                                                }
                                                InputProps={{
                                                  inputComponent:
                                                    TextMaskCustom,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      h:m:s
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                InputLabelProps={{
                                                  shrink: values.subactivity[
                                                    index
                                                  ].zone2
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                id={`subactivity.${index}.zone3`}
                                                type="text"
                                                label={t(
                                                  'Organisms.ActivityForm.Zone3',
                                                )}
                                                placeholder="HH:MM:SS"
                                                name={`subactivity.${index}.zone3`}
                                                disabled={
                                                  !hrLockState.enabledHR
                                                }
                                                InputProps={{
                                                  inputComponent:
                                                    TextMaskCustom,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      h:m:s
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                InputLabelProps={{
                                                  shrink: values.subactivity[
                                                    index
                                                  ].zone3
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={3}></Grid>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                id={`subactivity.${index}.zone4`}
                                                type="text"
                                                label={t(
                                                  'Organisms.ActivityForm.Zone4',
                                                )}
                                                placeholder="HH:MM:SS"
                                                name={`subactivity.${index}.zone4`}
                                                disabled={
                                                  !hrLockState.enabledHR
                                                }
                                                InputProps={{
                                                  inputComponent:
                                                    TextMaskCustom,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      h:m:s
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                InputLabelProps={{
                                                  shrink: values.subactivity[
                                                    index
                                                  ].zone4
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                              <Field
                                                variant="outlined"
                                                fullWidth
                                                id={`subactivity.${index}.zone5`}
                                                type="text"
                                                label={t(
                                                  'Organisms.ActivityForm.Zone5',
                                                )}
                                                placeholder="HH:MM:SS"
                                                name={`subactivity.${index}.zone5`}
                                                disabled={
                                                  !hrLockState.enabledHR
                                                }
                                                InputProps={{
                                                  inputComponent:
                                                    TextMaskCustom,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      h:m:s
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                InputLabelProps={{
                                                  shrink: values.subactivity[
                                                    index
                                                  ].zone5
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                              <Button
                                                fullWidth
                                                name={`subactivity.${index}.resetZones`}
                                                id={`subactivity.${index}.resetZones`}
                                                variant="outlined"
                                                color="secondary"
                                                disabled={
                                                  !hrLockState.enabledHR
                                                }
                                                onClick={() => {
                                                  for (let i = 1; i <= 5; i++) {
                                                    setFieldValue(
                                                      `subactivity.${index}.zone${i}`,
                                                      '00:00:00',
                                                    );
                                                  }
                                                }}
                                              >
                                                {t(
                                                  'Organisms.ActivityForm.ResetZones',
                                                )}
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Collapse>
                                        {errors.subactivity &&
                                          touched.subactivity &&
                                          errors.subactivity[index] &&
                                          errors.subactivity[index].zone1 &&
                                          values.subactivity[index].time &&
                                          hrLockState.enabledHR === false && (
                                            <Grid item xs={12}>
                                              <Alert severity="error">
                                                {
                                                  errors.subactivity[index]
                                                    .zone1
                                                }
                                              </Alert>
                                            </Grid>
                                          )}
                                        {typeof index !== 'undefined' &&
                                          index > 0 && (
                                            <Tooltip
                                              title={t(
                                                'Organisms.ActivityForm.RemoveSubactivity',
                                              )}
                                            >
                                              <IconButton
                                                aria-label="remove-subactivity"
                                                size="medium"
                                                onClick={() => {
                                                  arrayHelpers.remove(index);
                                                  setRemoveCount(
                                                    removeCount + 1,
                                                  );
                                                  values.subactivity.splice(
                                                    index,
                                                    1,
                                                  );
                                                  getSubActivityValues(values);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                      </Grid>
                                    </div>
                                  ))}
                                <Grid item xs={12}>
                                  <Button
                                    name="addSubactivity"
                                    id="addSubActivity"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        lap: '',
                                        type: '',
                                        timeOffset: '',
                                        startIndex: '',
                                        time: '',
                                        distance: '',
                                        pace: '',
                                        avgHr: '',
                                        avgCadence: '',
                                        elevationGain: '',
                                        enableHrZ: false,
                                        zone1: '',
                                        zone2: '',
                                        zone3: '',
                                        zone4: '',
                                        zone5: '',
                                        tag: [],
                                      })
                                    }
                                  >
                                    {/* show this when user has removed all friends from the list */}
                                    {t('Organisms.ActivityForm.AddSubactivity')}
                                  </Button>
                                </Grid>
                              </div>
                            )}
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={11}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={updateActivityState.isLoading}
                              >
                                {t('Organisms.ActivityForm.SaveActivity')}
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <div className={classes.submit}>
                                <SimpleAlertDialog
                                  title={t(
                                    'Organisms.ActivityForm.DeleteActivityConfirm',
                                  )}
                                  message={
                                    t(
                                      'Organisms.ActivityForm.DeleteActivityMessage',
                                    ) +
                                    ' ' +
                                    activity.name
                                  }
                                  handleConfirm={() =>
                                    deleteActivityState.request(
                                      ENDPOINTS.deleteActivity(),
                                      {
                                        method: 'DELETE',
                                        onSuccess: () => {
                                          handleClose();
                                          removeActivity(activity.activity_id);
                                          enqueueSnackbar(
                                            t(
                                              'Organisms.ActivityForm.DeletedActivityAlert',
                                            ),
                                            {
                                              variant: 'success',
                                            },
                                          );
                                        },
                                        onError: (err) => {
                                          enqueueSnackbar('Uh Oh, ' + err, {
                                            variant: 'error',
                                          });
                                        },
                                        data: {
                                          activityId: activity.activity_id,
                                        },
                                      },
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </SimpleAlertDialog>
                              </div>
                            </Grid>
                          </Grid>
                          <AutoSubmitToken previousValue={values} />
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              )}
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};
