import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Button, Grid } from '@material-ui/core';

import { LayoutedTeam, TextFieldWithLabel } from '../molecules';
import { valueContains } from '../utils';

import PATHNAMES from '../pathnames';

export const TeamList = ({ teamList }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filterTeam, setFilterTeam] = useState('');

  return (
    teamList.length !== 0 && (
      <>
        <Grid container spacing={1} className="text-align-right">
          <Grid item xs={12}>
            <TextFieldWithLabel
              id="teamFilter"
              name="teamFilter"
              label={t('Organisms.TeamList.TeamFilter')}
              placeholder={t('Organisms.TeamList.TeamFilterPlaceholder')}
              value={filterTeam}
              onChange={(event) => setFilterTeam(event.target.value)}
            />
          </Grid>
        </Grid>
        <Container component="main">
          <Grid container spacing={2} alignItems="flex-end">
            {teamList
              .filter(({ name }) => valueContains(name, filterTeam))
              .map((team) => (
                <Grid item key={team.team_id} xs={12} md={4}>
                  {team.member[0].user_team.role === 'coach' ? (
                    <LayoutedTeam
                      key={team.team_id}
                      {...team}
                      footer={
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            history.push(PATHNAMES.getTeamDetail(team.team_id))
                          }
                        >
                          {t('Organisms.TeamList.GoToTeamDetailButton')}
                        </Button>
                      }
                    />
                  ) : (
                    <LayoutedTeam key={team.team_id} {...team} />
                  )}
                </Grid>
              ))}
          </Grid>
        </Container>
      </>
    )
  );
};
