import React, { useEffect, useState, useCallback } from 'react';
import { Card, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Field } from '../../../organisms';
import { useRequest, translatedValidations } from '../../../utils';
import ENDPOINTS from '../../../endpoints';
import PATHNAMES from '../../../pathnames';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const PasswordResetPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const resetPasswordState = useRequest();
  const [emailSentState, setEmailSentState] = useState('');

  useEffect(() => {
    if (resetPasswordState.isLoading) {
      setEmailSentState('');
    }
  }, [resetPasswordState.isLoading]);

  const onSubmitMemoized = useCallback(
    ({ email }, { resetForm }) => {
      resetPasswordState.request(ENDPOINTS.resetPassword(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          resetForm();
          setEmailSentState(
            t('Page.Auth.PasswordReset.SuccessfulPasswordResetAlert', {
              data,
            }),
          );
          enqueueSnackbar(
            t('Page.Auth.PasswordReset.SuccessfulPasswordResetAlert', {
              data,
            }),
            {
              variant: 'success',
            },
          );
          history.push(PATHNAMES.passwordResetRequest());
        },
        data: { email },
      });
    },
    [resetPasswordState, t, enqueueSnackbar, history],
  );

  const { object, requiredEmail } = translatedValidations(t);
  const schema = object({
    email: requiredEmail,
  });

  return (
    <div className="signup flex flex-center w-80 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-8 flex flex-center flex-middle h-100">
                <img src="/assets/images/illustrations/dreamer.svg" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={schema}
                  onSubmit={onSubmitMemoized}
                >
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Field
                        className="mb-24 w-100"
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        type="email"
                        label={t('User.Email')}
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <div className={classes.wrapper}>
                      <Button
                        className={classes.submit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={resetPasswordState.isLoading}
                      >
                        {t('Page.Auth.PasswordReset.ResetPassword')}
                      </Button>
                    </div>
                    <span className="ml-16 mr-8">or</span>
                    <Button
                      className="capitalize"
                      onClick={() => history.push(PATHNAMES.signIn())}
                    >
                      {t('Page.Auth.SignIn.SignIn')}
                    </Button>
                  </Form>
                </Formik>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};
