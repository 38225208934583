import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Scrollbar from 'react-perfect-scrollbar';

import { ErrorBox } from '../../../atoms';
import { Field, CheckBoxField } from '../../../organisms';
import { useRequest, translatedValidations } from '../../../utils';
import PATHNAMES from '../../../pathnames';
import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    //backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: 'url(/assets/images/unsplash.jfif)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignUpPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { email } = useParams();
  const registrationState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [emailSentState, setEmailSentState] = useState('');

  useEffect(() => {
    if (registrationState.isLoading) {
      setEmailSentState('');
    }
  }, [registrationState.isLoading]);

  const onSubmitMemoized = useCallback(
    ({ firstName, lastName, email, password, promotion }, { resetForm }) => {
      registrationState.request(ENDPOINTS.signUp(), {
        method: 'POST',
        onSuccess: ({ data: { email } }) => {
          resetForm();
          setEmailSentState(
            t('Page.Auth.SignUp.SuccessfulRegistrationAlert', { email }),
          );
          enqueueSnackbar(
            t('Page.Auth.SignUp.SuccessfulRegistrationAlert', { email }),
            {
              variant: 'success',
            },
          );
          history.push(PATHNAMES.signIn());
        },
        data: { firstName, lastName, email, password, promotion },
      });
    },
    [registrationState, t, enqueueSnackbar, history],
  );

  const {
    object,
    requiredString,
    requiredEmail,
    passwordsDontMatch,
  } = translatedValidations(t);

  const schema = object({
    firstName: requiredString,
    lastName: requiredString,
    email: requiredEmail,
    password: requiredString,
    passwordConfirmation: passwordsDontMatch('password'),
  });

  const prefilledEmail = typeof email === 'undefined' ? '' : email;

  return (
    <Scrollbar>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('Page.Auth.SignUp.CreateAccount')}
            </Typography>
            <ErrorBox errorList={[{ id: 1, error: registrationState.error }]} />
            <Scrollbar className="scrollable-content">
              <Formik
                initialValues={{
                  firstname: '',
                  lastname: '',
                  email: prefilledEmail,
                  password: '',
                  passwordConfirmation: '',
                  promotion: false,
                  privacypolicy: false,
                }}
                validationSchema={schema}
                onSubmit={onSubmitMemoized}
              >
                {({ values }) => (
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          autoComplete="given-name"
                          name="firstName"
                          variant="outlined"
                          required
                          fullWidth
                          id="firstName"
                          label={t('User.Firstname')}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          variant="outlined"
                          required
                          fullWidth
                          id="lastName"
                          label={t('User.Lastname')}
                          name="lastName"
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={t('User.Email')}
                        name="email"
                        autoComplete="email"
                      />
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label={t('User.Password')}
                        type="password"
                        id="password"
                        autoComplete="new-password"
                      />

                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        name="passwordConfirmation"
                        label={t('Page.Auth.SignUp.PasswordConfirmation')}
                        type="password"
                        id="passwordConfirmation"
                        autoComplete="current-password"
                      />

                      <CheckBoxField
                        value={values.promotion}
                        name="promotion"
                        id="promotion"
                        color="primary"
                        label={t('Page.Auth.SignUp.Promotion')}
                      />
                      <CheckBoxField
                        required
                        value={values.privacypolicy}
                        name="privacypolicy"
                        id="privacypolicy"
                        color="primary"
                        label={
                          <>
                            {t('Page.Auth.SignUp.Agreement')}{' '}
                            <Link href={PATHNAMES.privacyPolicy()}>
                              {t('Page.Auth.SignUp.PrivacyPolicy')}
                            </Link>
                            .
                          </>
                        }
                      />
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={registrationState.isLoading}
                    >
                      {t('Page.Auth.SignUp.SignUp')}
                    </Button>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Link href={PATHNAMES.signIn()} variant="body2">
                          {t('Page.Auth.SignUp.HaveAnAccount')}
                        </Link>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Scrollbar>
          </div>
        </Grid>
      </Grid>
    </Scrollbar>
  );
};
