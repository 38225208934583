import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';

import { NotLoggedInPageLayout } from '../../../templates';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    bottom: '0%',
  },
}));

export const PasswordResetRequestPage = () => {
  const errorList = [{ id: 1, error: null }];
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NotLoggedInPageLayout errorList={errorList}>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {t('Page.Auth.PasswordReset.PasswordResetRequestPage.PasswordReset')}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          {t(
            'Page.Auth.PasswordReset.PasswordResetRequestPage.ContinueInEmail',
          )}
        </Typography>
      </Container>
    </NotLoggedInPageLayout>
  );
};
