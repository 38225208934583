import React, { useState, useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { ErrorBox } from '../../atoms';
import { RegenerationLogTable } from '../../organisms';
import { useFetchRequest, useRequest } from '../../utils';

import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
}));

export const RegenerationLog = ({ t }) => {
  const deleteRegenerationState = useRequest();
  const [regenerationLogState, setRegenerationLogState] = useState(undefined);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessFetchRegenerationLogState = (response) => {
    updateRegenerationLogState(response);
  };

  const fetchRegenerationLogState = useFetchRequest(
    ENDPOINTS.getAthleteRegenerationLog(),
    {
      onSuccess: onSuccessFetchRegenerationLogState,
    },
  );

  const updateRegenerationLogState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setRegenerationLogState({
        data,
      });
    },
    [],
  );

  const handleRowDelete = (oldData, resolve) => {
    console.log(oldData);
    deleteRegenerationState.request(ENDPOINTS.deleteRegeneration(), {
      method: 'DELETE',
      onSuccess: () => {
        enqueueSnackbar(
          t('Page.Regeneration.RegenerationLog.RegenerationDeleted'),
          {
            variant: 'success',
          },
        );
        setRegenerationLogState({
          data: regenerationLogState.data.filter(
            (item) => item.regeneration_id !== oldData.regeneration_id,
          ),
        });
        resolve();
      },
      data: {
        regenerationId: oldData.regeneration_id,
      },
    });
  };

  const errorList = [
    {
      id: 1,
      error: fetchRegenerationLogState.error,
    },
  ];
  return (
    <Paper className="mb-20">
      <ErrorBox errorList={errorList} />
      {fetchRegenerationLogState.isLoading && (
        <CircularProgress className={classes.progress} />
      )}
      {typeof regenerationLogState !== 'undefined' &&
        regenerationLogState.data && (
          <RegenerationLogTable
            t={t}
            data={regenerationLogState.data}
            handleRowDelete={handleRowDelete}
          />
        )}
    </Paper>
  );
};
