import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';
import theme from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { TableIcons } from '../atoms';
export const TeamMembersTable = ({
  user,
  t,
  data,
  handleRowUpdate,
  handleRowDelete,
  handleAllSelectedDelete,
}) => {
  const [filtering, setFiltering] = useState(false);

  const columns = [
    {
      title: 'ID',
      width: 10,
      field: 'userId',
      editable: 'never',
      hidden: true,
    },
    {
      title: '',
      field: 'role',
      hidden: false,
      filtering: false,
      sorting: false,
      editable: 'never',
      type: 'string',
      render: (rowData) =>
        rowData.role === 'coach' && <FontAwesomeIcon icon={faUserSecret} />,
      width: 5,
    },
    {
      title: t('User.Lastname'),
      field: 'lastname',
      type: 'string',
      width: 100,
      editable: 'never',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.lastname.localeCompare(b.lastname, 'cz', { sensitivity: 'variant' }),
    },
    {
      title: t('User.Firstname'),
      field: 'firstname',
      type: 'string',
      width: 100,
      editable: 'never',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.firstname.localeCompare(b.firstname, 'cz', {
          sensitivity: 'variant',
        }),
    },
    {
      title: t('User.Nickname'),
      field: 'nickname',
      type: 'string',
      width: 50,
      editable: 'never',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.nickname.localeCompare(b.nickname, 'cz', {
          sensitivity: 'variant',
        }),
    },
    {
      title: t('User.Email'),
      field: 'email',
      type: 'string',
      width: 50,
      editable: 'never',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    {
      title: 'Role',
      field: 'role',
      type: 'string',
      width: 50,
      lookup: { coach: 'trenér', athlete: 'sportovec' },
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        title={t('Page.Team.TeamDetail.Members')}
        columns={columns}
        data={data}
        icons={TableIcons}
        editable={{
          isEditable: (rowData) => rowData.userId !== user.user.userId,
          isDeletable: (rowData) => rowData.userId !== user.user.userId,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        options={{
          actionsColumnIndex: -1,
          tableLayout: 'auto',
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, 50, 100],
          filtering: filtering,
          selection: true,
          selectionProps: (rowData) => ({
            disabled: rowData.userId === user.user.userId,
          }),
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return {
                backgroundColor: '#f2f2f2',
              };
            }
          },
        }}
        actions={[
          {
            icon: () => <FilterList />,
            tooltip: t('DataTable.EnableFilter'),
            isFreeAction: true,
            onClick: () => setFiltering(!filtering),
          },
          {
            tooltip: t('DataTable.RemoveAllSelected'),
            icon: () => <DeleteIcon />,
            onClick: (evt, data) => handleAllSelectedDelete(data),
          },
        ]}
        localization={{
          header: {
            actions: t('DataTable.Actions'),
          },
          body: {
            deleteTooltip: t('DataTable.Delete'),
            editTooltip: t('DataTable.Edit'),
            editRow: {
              deleteText: t('DataTable.DeleteText'),
              cancelTooltip: t('DataTable.CancelTooltip'),
              saveTooltip: t('DataTable.SaveTooltip'),
            },
            filterRow: {
              filterTooltip: t('DataTable.Filter'),
            },
          },
          toolbar: {
            searchTooltip: t('DataTable.SearchTooltip'),
            searchPlaceholder: t('DataTable.SearchPlaceholder'),
            addRemoveColumns: t('DataTable.AddRemoveColumns'),
            showColumnsTitle: t('DataTable.ShowColumnsTitle'),
            showColumnsAriaLabel: t('DataTable.ShowColumnsTitle'),
            nRowsSelected: t('DataTable.NRowsSelected'),
          },
        }}
      />
    </MuiThemeProvider>
  );
};
