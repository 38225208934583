import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Field, DatePickerField } from '../../organisms';
import { useRequest } from '../../utils';
import ENDPOINTS from '../../endpoints';

export const NewTeamField = ({ t, sportState }) => {
  const { values, setFieldValue, handleChange } = useFormikContext();
  const seasonPeriodState = useRequest();

  const updatePeriodValues = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setFieldValue(`startDate`, data.start_date);
      setFieldValue(`endDate`, data.end_date);
    },
    [setFieldValue],
  );

  const handleSportChange = (e) => {
    handleChange(e);
    seasonPeriodState.request(
      ENDPOINTS.getSeasonPeriodForSport(e.target.value),
      {
        method: 'GET',
        onSuccess: (response) => updatePeriodValues(response),
      },
    );
  };
  return (
    <>
      <Field
        variant="outlined"
        required
        fullWidth
        type="text"
        id="name"
        label={t('Page.Team.NewTeamForm.Name')}
        placeholder="Zadej název týmu"
        name="name"
      />
      <Field
        variant="outlined"
        required
        fullWidth
        select
        id="sport"
        label={t('Organisms.ActivityForm.Sport')}
        name="sport"
        data={sportState.data.sports}
        onChange={handleSportChange}
      />
      <Field
        variant="outlined"
        fullWidth
        multiline
        type="text"
        id="description"
        label={t('Page.Team.NewTeamForm.Description')}
        placeholder="Zadej popis týmu"
        name="description"
      />
      <DatePickerField
        fullWidth
        required
        type="date"
        id="startDate"
        name="startDate"
        animateYearScrolling={true}
        showTodayButton
        label={t('Page.Team.NewTeamForm.StartDate')}
        inputVariant="outlined"
        format="dd.MM.yyyy"
        placeholder="dd.mm.rrrr"
        value={values.startDate}
        onChange={(value) => setFieldValue('startDate', value)}
      />
      <DatePickerField
        fullWidth
        required
        type="date"
        id="endDate"
        name="endDate"
        animateYearScrolling={true}
        showTodayButton
        label={t('Page.Team.NewTeamForm.EndDate')}
        inputVariant="outlined"
        format="dd.MM.yyyy"
        placeholder="dd.mm.rrrr"
        disablePast
        value={values.endDate}
        onChange={(value) => setFieldValue('endDate', value)}
      />
      <Field
        variant="outlined"
        required
        fullWidth
        multiline
        type="text"
        id="emails"
        label={t('Page.Team.NewTeamForm.Emails')}
        placeholder={t('Page.Team.NewTeamForm.EmailsPlaceholder')}
        name="emails"
      />
    </>
  );
};
