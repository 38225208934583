import React, { useState } from 'react';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { TableIcons, ErrorBox } from '../../../atoms';
import {
  useFetchRequest,
  formatSecondsToTime,
  convertToKM,
} from '../../../utils';
import PATHNAMES from '../../../pathnames';
import ENDPOINTS from '../../../endpoints';

export const TeamOverview = ({ teamId, history, location }) => {
  const { t } = useTranslation();

  const teamStatsState = useFetchRequest(ENDPOINTS.getTeamStats(teamId));

  const [filtering, setFiltering] = useState(false);

  const page = new URLSearchParams(location.search).get('page');
  const [pageState, setPageState] = useState(page);

  const errorList = [
    {
      id: 1,
      error: teamStatsState.error,
    },
  ];

  const columns = [
    { title: 'ID', field: 'userId', hidden: true },
    {
      title: '',
      field: 'role',
      hidden: false,
      filtering: false,
      sorting: false,
      type: 'string',
      render: (rowData) =>
        rowData.role === 'coach' && <FontAwesomeIcon icon={faUserSecret} />,
      width: 5,
    },
    {
      title: t('User.Firstname'),
      field: 'firstname',
      type: 'string',
      align: 'left',
      customSort: (a, b) =>
        a.firstname.localeCompare(b.firstname, 'cz', {
          sensitivity: 'variant',
        }),
    },
    {
      title: t('User.Lastname'),
      field: 'lastname',
      type: 'string',
      align: 'left',
      customSort: (a, b) =>
        a.lastname.localeCompare(b.lastname, 'cz', { sensitivity: 'variant' }),
    },
    {
      title: t('Organisms.TrainingLogTable.Time'),
      field: 'time',
      type: 'numeric',
      filtering: false,
      render: (rowData) => rowData.time && formatSecondsToTime(rowData.time),
      align: 'left',
    },
    {
      title: t('Organisms.TrainingLogTable.Distance'),
      field: 'distance',
      type: 'numeric',
      filtering: false,
      render: (rowData) =>
        rowData.distance && convertToKM(rowData.distance) + ' km',
      align: 'left',
    },
    {
      title: t('Organisms.WeekSummaryTable.Count'),
      field: 'count',
      type: 'numeric',
      filtering: false,
      align: 'left',
    },
  ];

  return (
    <>
      {teamStatsState.data && (
        <div className="mt-20">
          <ErrorBox errorList={errorList} />
          <MaterialTable
            title={t('Page.Team.TeamDetail.Members')}
            columns={columns}
            data={teamStatsState.data.stats}
            icons={TableIcons}
            options={{
              actionsColumnIndex: -1,
              initialPage: pageState && pageState - 1,
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, 100],
              filtering: filtering,
              exportButton: true,
              grouping: false,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: '#f2f2f2' };
                }
              },
            }}
            onChangePage={(currentPage) => {
              setPageState(currentPage + 1);
              let currentUrlParams = new URLSearchParams(location.search);
              currentUrlParams.set('page', currentPage + 1);
              history.push({
                search: currentUrlParams.toString(),
              });
            }}
            onRowClick={(event, rowData) => {
              // Get your id from rowData and use with link.
              history.push(
                PATHNAMES.getTeamMemberDetail(teamId, rowData.userId, 'stats'),
              );
              event.stopPropagation();
            }}
            actions={[
              {
                icon: () => <FilterList />,
                tooltip: t('DataTable.EnableFilter'),
                isFreeAction: true,
                onClick: () => setFiltering(!filtering),
              },
            ]}
            localization={{
              header: {
                actions: t('DataTable.Actions'),
              },
              body: {
                deleteTooltip: t('DataTable.Delete'),
                editTooltip: t('DataTable.Edit'),
                editRow: {
                  deleteText: t('DataTable.DeleteText'),
                  cancelTooltip: t('DataTable.CancelTooltip'),
                  saveTooltip: t('DataTable.SaveTooltip'),
                },
                toolbar: {
                  searchTooltip: t('DataTable.SearchTooltip'),
                  searchPlaceholder: t('DataTable.SearchPlaceholder'),
                },
                filterRow: {
                  filterTooltip: t('DataTable.Filter'),
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};
