import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';
import { useHistory } from 'react-router-dom';
import PATHNAMES from '../pathnames';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(5),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export const SpeedDials = () => {
  const history = useHistory();
  const classes = useStyles();
  const [direction, setDirection] = useState('up');
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);

  const actions = [
    {
      action: () => history.push(PATHNAMES.activityManual()),
      icon: <AddCircleIcon />,
      name: 'Přidat aktivitu',
    },
    {
      action: () => history.push(PATHNAMES.sicknessCreate()),
      icon: <LocalHotelIcon />,
      name: 'Přidat nemoc',
    },
    {
      action: () => history.push(PATHNAMES.regenerationCreate()),
      icon: <BatteryCharging90Icon />,
      name: 'Přidat regeneraci',
    },
  ];

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      hidden={hidden}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction={direction}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.action}
        />
      ))}
    </SpeedDial>
  );
};
