import React from 'react';
import { ProportionStackedPiece } from '../atoms';

export const ProportionStackedBar = ({ width, data = [] }) => {
  const breakData = [];
  const unitWidth = width / 100;
  let startX = 0;

  for (let i = 0; i < data.length; i++) {
    const bar = data[i];
    let pieceWidth = bar.ratio * unitWidth;
    breakData.push({
      id: bar.id,
      label: bar.text + ': ' + Number(bar.ratio).toFixed(2) + '%',
      xAxis: startX,
      width: pieceWidth,
      backgroundColor: bar.color,
    });
    startX += pieceWidth;
  }

  return (
    <div
      style={{
        position: 'relative',
        boxSizing: 'border-box',
        display: 'inline-flex',
        verticalAlign: 'middle',
        justifyContent: 'center',
        borderRadius: '16px',
      }}
    >
      {breakData.map((piece, index) => (
        <ProportionStackedPiece key={index} data={piece} />
      ))}
    </div>
  );
};
