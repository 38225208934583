import React, { useCallback } from 'react';
import Avatar from '@material-ui/core/Avatar';
import SyncIcon from '@material-ui/icons/Sync';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBox } from '../../atoms';
import { useFetchRequest } from '../../utils';
import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const TokenPage = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  const onSuccess = useCallback(() => {
    history.push(PATHNAMES.dashboard());
  }, [history]);

  const stravaTokenState = useFetchRequest(ENDPOINTS.stravaTokenExchange(), {
    method: 'POST',
    data: {
      code: query.get('code'),
      scope: query.get('scope'),
    },
    onSuccess,
  });

  const errorList = [{ id: 1, error: stravaTokenState.error }];

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SyncIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Page.Upload.TokenPage.ConnectStrava')}
        </Typography>
        {stravaTokenState.isLoading && <CircularProgress />}
        <ErrorBox errorList={errorList} />
        {stravaTokenState.error && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(PATHNAMES.upload())}
          >
            {t('Page.Upload.TokenPage.BackSyncMethods')}
          </Button>
        )}
      </div>
    </Container>
  );
};
