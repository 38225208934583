import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../atoms';
import { Field } from '../../organisms';
import { useRequest, translatedValidations } from '../../utils';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  button: {
    margin: theme.spacing(0, 0, 2),
  },
  text: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export const NewHrZoneForm = ({ setHeartRateZonesState }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const storeHeartZoneState = useRequest();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { object, requiredNumber } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ zone1, zone2, zone3, zone4, zone5, maxHr }) => {
      storeHeartZoneState.request(ENDPOINTS.storeHeartZoneSettings(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: {
              data: {
                zone_id,
                zone_1,
                zone_2,
                zone_3,
                zone_4,
                zone_5,
                max_hr,
                created_at,
              },
            },
          },
        }) => {
          handleClose();
          setHeartRateZonesState((prevState) => ({
            data: [
              {
                zone_id: zone_id,
                zone_1: zone_1,
                zone_2: zone_2,
                zone_3: zone_3,
                zone_4: zone_4,
                zone_5: zone_5,
                max_hr: max_hr,
                created_at: created_at,
              },
              ...prevState.data,
            ],
          }));
          enqueueSnackbar(t('Organisms.ActivityForm.UpdatedActivityAlert'), {
            variant: 'success',
          });
        },
        data: {
          zone1,
          zone2,
          zone3,
          zone4,
          zone5,
          maxHr,
        },
      });
    },
    [
      storeHeartZoneState,
      handleClose,
      enqueueSnackbar,
      t,
      setHeartRateZonesState,
    ],
  );

  const schema = object({
    zone1: requiredNumber,
    zone2: requiredNumber,
    zone3: requiredNumber,
    zone4: requiredNumber,
    zone5: requiredNumber,
    maxHr: requiredNumber,
  });

  return (
    <>
      <div className={classes.submit}>
        <Button
          className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
          color="primary"
          onClick={handleClickOpen}
        >
          {t('Page.Settings.ZoneSettings.NewZoneSettingsButton')}
        </Button>
      </div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Page.Settings.ZoneSettings.ZoneSettingsTitle')}
        </DialogTitle>
        <Formik
          initialValues={{
            zone1: '',
            zone2: '',
            zone3: '',
            zone4: '',
            zone5: '',
            maxHr: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmitMemoized}
        >
          {() => (
            <Form className={classes.form}>
              <DialogContent>
                <ErrorBox
                  errorList={[{ id: 1, error: storeHeartZoneState.error }]}
                />
                <Grid container spacing={2}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="zone1"
                    label={t('Organisms.ActivityForm.Zone1')}
                    name="zone1"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">bpm</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                  />
                  <Typography
                    className={classes.text}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('Page.Settings.ZoneSettings.Zone1Description')}
                  </Typography>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="zone2"
                    label={t('Organisms.ActivityForm.Zone2')}
                    name="zone2"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">bpm</InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    className={classes.text}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('Page.Settings.ZoneSettings.Zone2Description')}
                  </Typography>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="zone3"
                    label={t('Organisms.ActivityForm.Zone3')}
                    name="zone3"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">bpm</InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    className={classes.text}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('Page.Settings.ZoneSettings.Zone3Description')}
                  </Typography>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="zone4"
                    label={t('Organisms.ActivityForm.Zone4')}
                    name="zone4"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">bpm</InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    className={classes.text}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('Page.Settings.ZoneSettings.Zone4Description')}
                  </Typography>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="zone5"
                    label={t('Organisms.ActivityForm.Zone5')}
                    name="zone5"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">bpm</InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    className={classes.text}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('Page.Settings.ZoneSettings.Zone5Description')}
                  </Typography>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="maxHr"
                    label={t('Page.Settings.ZoneSettings.MaxHr')}
                    name="maxHr"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">bpm</InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    className={classes.text}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t('Page.Settings.ZoneSettings.MaxHrDescription')}
                  </Typography>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={storeHeartZoneState.isLoading}
                    >
                      {t('Page.Settings.ZoneSettings.SaveZoneSettings')}
                    </Button>
                    <Button onClick={handleClose} fullWidth variant="outlined">
                      {t('Page.Settings.ZoneSettings.Cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
