import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import SidenavTheme from './SidenavTheme';
import { Brand } from './Brand';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  Switch,
  withStyles,
  Tooltip,
  IconButton,
  MuiThemeProvider,
} from '@material-ui/core';
import { Sidenav } from './Sidenav';

import { useAuth } from '../utils';
import PATHNAMES from '../pathnames';

const IconButtonWhite = withStyles((theme) => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: 'transparent',
    padding: '5px',
  },
}))(IconButton);

export const Layout1Sidenav = ({
  history,
  handleSidenavToggle,
  sidebarMode,
}) => {
  const { user, signout, privileges, avatar } = useAuth();
  const { t } = useTranslation();

  const imgAvatarSource = avatar
    ? `data:image/jpeg;base64,${avatar}`
    : '/assets/images/user-1.png';

  const logoutCallback = useCallback(() => {
    signout();
    history.push(PATHNAMES.signIn());
  }, [history, signout]);
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <SidenavTheme />
        <div className="sidenav">
          <div className="sidenav__hold">
            {
              <Fragment>
                <Brand>
                  <Switch
                    className="show-on-lg"
                    onChange={handleSidenavToggle}
                    checked={sidebarMode.sidenavToggleChecked}
                    color="primary"
                  />
                </Brand>
                <Sidenav privileges={privileges}>
                  <div className="sidenav__user">
                    <div className="username-photo">
                      <img src={imgAvatarSource} alt="user" />
                    </div>
                    <div className="ml-8">
                      <span className="username font-weight-normal">
                        {user && user.firstName} {user && user.lastName}
                      </span>
                      <div className="user__menu">
                        <Tooltip title={t('Organisms.LayoutSidenav.Settings')}>
                          <IconButtonWhite
                            aria-label="Settings"
                            size="small"
                            onClick={() => history.push(PATHNAMES.settings())}
                          >
                            <SettingsIcon className="text-white" />
                          </IconButtonWhite>
                        </Tooltip>
                        <Tooltip title={t('Organisms.LayoutSidenav.Profile')}>
                          <IconButtonWhite aria-label="Profile" size="small">
                            <AccountCircleIcon className="text-white" />
                          </IconButtonWhite>
                        </Tooltip>
                        <Tooltip title={t('Organisms.LayoutSidenav.SignOut')}>
                          <IconButtonWhite
                            aria-label="Sign out"
                            size="small"
                            onClick={logoutCallback}
                            className="text-white"
                          >
                            <ExitToAppIcon />
                          </IconButtonWhite>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Sidenav>
              </Fragment>
            }
          </div>
        </div>
      </MuiThemeProvider>
    </>
  );
};
