import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import LogRocket from 'logrocket';
import { Routes } from './Routes';
import { ApiProvider } from './utils/api';
import { AuthProvider } from './utils/auth';
import { ScrollToTop } from './atoms';
import { useDarkMode } from './utils/useDarkMode';
import './styles/_app.scss';

export default function App() {
  const [themeObject, componentMounted] = useDarkMode();
  const themeConfig = createTheme(themeObject);
  LogRocket.init('treninkac/treninkac');

  if (!componentMounted) {
    return <div />;
  }
  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <AllProviders>
        <Routes />
      </AllProviders>
    </ThemeProvider>
  );
}

function AllProviders({ children }) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <SnackbarProvider>
        <AuthProvider>
          <ApiProvider>
            <BrowserRouter>
              <ScrollToTop />
              {children}
            </BrowserRouter>
          </ApiProvider>
        </AuthProvider>
      </SnackbarProvider>
    </Suspense>
  );
}
