import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox, TextMaskCustom } from '../../atoms';
import { Field, DatePickerField, AutoSuggest } from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
  formatDateTime,
  formatSecondsToTime,
} from '../../utils';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const EditRegenerationForm = ({
  regenerationStateData,
  setRegenerationStateData,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();
  const updateRegenerationState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const enumRegenerationState = useFetchRequest(
    ENDPOINTS.enumRegenerationTypes(),
  );
  const activityState = useFetchRequest(ENDPOINTS.getActivitySuggestList());

  const {
    regeneration_id: regenerationId,
    name,
    type_id,
    date,
    duration,
    description,
    activity,
  } = regenerationStateData.data;

  const {
    object,
    requiredString,
    requiredNumber,
    requiredDate,
    string,
    requiredTime,
  } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ regenerationId, name, type, date, duration, description, activity }) => {
      updateRegenerationState.request(ENDPOINTS.updateRegeneration(), {
        method: 'PUT',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          handleClose();
          enqueueSnackbar(t('Organisms.ActivityForm.UpdatedActivityAlert'), {
            variant: 'success',
          });
          setRegenerationStateData({
            data,
          });
        },
        data: {
          regenerationId,
          name,
          type,
          date,
          duration,
          description,
          activity,
        },
      });
    },
    [
      updateRegenerationState,
      handleClose,
      enqueueSnackbar,
      t,
      setRegenerationStateData,
    ],
  );

  const schema = object({
    name: requiredString,
    type: requiredNumber,
    date: requiredDate,
    duration: requiredTime,
    description: string,
    activity: string,
  });

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {t('Page.Sickness.SicknessDetailPage.Edit')}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Page.Regeneration.RegenerationDetailPage.EditRegeneration')} -{' '}
          {name}
          <div className="card-subtitle">{formatDateTime(date)}</div>
        </DialogTitle>
        <DialogContent>
          <ErrorBox
            errorList={[
              { id: 1, error: enumRegenerationState.error },
              { id: 2, error: updateRegenerationState.error },
            ]}
          />
          {enumRegenerationState.isLoading ||
            (activityState.isLoading && <CircularProgress />)}
          {enumRegenerationState.data && activityState.data && (
            <Formik
              initialValues={{
                regenerationId: regenerationId,
                name: name,
                type: type_id,
                date: date,
                duration: formatSecondsToTime(duration),
                description: description,
                activity: activity,
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, values }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label={t('Organisms.ActivityForm.ActivityName')}
                        name="name"
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        select
                        id="type"
                        label={t(
                          'Page.Regeneration.CreateRegenerationPage.Kind',
                        )}
                        name="type"
                        data={enumRegenerationState.data.regenerations}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DatePickerField
                        fullWidth
                        required
                        type="date-time"
                        id="date"
                        name="date"
                        ampm={false}
                        disableFuture
                        animateYearScrolling={true}
                        showTodayButton
                        label={t('Organisms.ActivityForm.Date')}
                        inputVariant="outlined"
                        format="dd/MM/yyyy HH:mm"
                        placeholder="dd/mm/yyyy HH:MM"
                        value={values.date}
                        onChange={(value) => setFieldValue('date', value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        id="duration"
                        label={t(
                          'Page.Regeneration.CreateRegenerationPage.Duration',
                        )}
                        placeholder="HH:MM:SS"
                        name="duration"
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              h:m:s
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Field
                      variant="outlined"
                      fullWidth
                      id="description"
                      multiline
                      placeholder="Sem rozepiš průběh regenerace ..."
                      rows={5}
                      label={t('Organisms.ActivityForm.Description')}
                      name="description"
                    />
                    <Grid item xs={12}>
                      <AutoSuggest
                        options={activityState.data}
                        variant="outlined"
                        fullWidth
                        id="activity"
                        name="activity"
                        placeholder={t(
                          'Page.Regeneration.CreateRegenerationPage.SelectActivityPlaceholder',
                        )}
                        label={t(
                          'Page.Regeneration.CreateRegenerationPage.ConnectWithActivity',
                        )}
                        value={values.activity}
                        onChange={(event, value) =>
                          setFieldValue('activity', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="flex flex-end">
                        <Button
                          onClick={() => handleClose()}
                          variant="outlined"
                        >
                          {t('Page.Settings.ZoneSettings.Cancel')}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="ml-8"
                          disabled={updateRegenerationState.isLoading}
                        >
                          {t(
                            'Page.Regeneration.CreateRegenerationPage.SaveRegeneration',
                          )}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
