import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Field, DatePickerField, AutoSuggest } from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
} from '../../utils';
import { TextMaskCustom } from '../../atoms';
import { LoggedInPageLayout } from '../../templates';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export const CreateRegenerationPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const createRegenerationState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const {
    object,
    requiredString,
    requiredNumber,
    requiredDate,
    string,
    requiredTime,
  } = translatedValidations(t);

  const regenerationState = useFetchRequest(ENDPOINTS.enumRegenerationTypes());
  const activityState = useFetchRequest(ENDPOINTS.getActivitySuggestList());

  const errorList = [
    {
      id: 1,
      error: regenerationState.error,
    },
    { id: 2, error: createRegenerationState.error },
    {
      id: 3,
      error: activityState.error,
    },
  ];

  const onSubmitMemoized = useCallback(
    ({ name, type, date, duration, description, activity }, { resetForm }) => {
      createRegenerationState.request(ENDPOINTS.storeRegeneration(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          enqueueSnackbar(
            t(
              'Page.Regeneration.CreateRegenerationPage.CreateRegenerationAlert',
            ),
            {
              variant: 'success',
            },
          );
          resetForm();
          history.push(PATHNAMES.getRegenerationDetail(data.regeneration_id));
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          name,
          type,
          date,
          utcOffset: new Date().getTimezoneOffset() * -1 * 60,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          duration,
          description,
          activity,
        },
      });
    },
    [createRegenerationState, enqueueSnackbar, t, history],
  );

  const schema = object({
    name: requiredString,
    type: requiredNumber,
    date: requiredDate,
    duration: requiredTime,
    description: string,
    activity: string,
  });

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <Button
            onClick={() =>
              history.push(PATHNAMES.getTrainingLog('regeneration'))
            }
          >
            <ArrowBackIosIcon fontSize="small" />
            {t('Page.TrainingLog.ActivityDetailPage.AllActivities')}
          </Button>
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t(
                'Page.Regeneration.CreateRegenerationPage.CreateRegenerationTitle',
              )}
            </Typography>
          </div>
          {regenerationState.isLoading ||
            (activityState.isLoading && <CircularProgress />)}
          {regenerationState.data && activityState.data && (
            <Formik
              initialValues={{
                name: '',
                type: '',
                date: null,
                duration: '',
                description: '',
                activity: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, values }) => (
                <Paper className="p-40">
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Field
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          label={t('Organisms.ActivityForm.ActivityName')}
                          name="name"
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          variant="outlined"
                          required
                          fullWidth
                          select
                          id="type"
                          label={t(
                            'Page.Regeneration.CreateRegenerationPage.Kind',
                          )}
                          name="type"
                          data={regenerationState.data.regenerations}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePickerField
                          fullWidth
                          required
                          type="date-time"
                          id="date"
                          name="date"
                          ampm={false}
                          disableFuture
                          animateYearScrolling={true}
                          showTodayButton
                          label={t('Organisms.ActivityForm.Date')}
                          inputVariant="outlined"
                          format="dd/MM/yyyy HH:mm"
                          placeholder="dd/mm/yyyy HH:MM"
                          value={values.date}
                          onChange={(value) => setFieldValue('date', value)}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          variant="outlined"
                          fullWidth
                          required
                          type="text"
                          id="duration"
                          label={t(
                            'Page.Regeneration.CreateRegenerationPage.Duration',
                          )}
                          placeholder="HH:MM:SS"
                          name="duration"
                          InputProps={{
                            inputComponent: TextMaskCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                h:m:s
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="description"
                        multiline
                        placeholder="Sem rozepiš průběh regenerace ..."
                        rows={5}
                        label={t('Organisms.ActivityForm.Description')}
                        name="description"
                      />
                      <Grid item xs={12}>
                        <AutoSuggest
                          options={activityState.data}
                          variant="outlined"
                          fullWidth
                          id="activity"
                          name="activity"
                          placeholder={t(
                            'Page.Regeneration.CreateRegenerationPage.SelectActivityPlaceholder',
                          )}
                          label={t(
                            'Page.Regeneration.CreateRegenerationPage.ConnectWithActivity',
                          )}
                          value={values.activity}
                          onChange={(event, value) =>
                            setFieldValue('activity', value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="flex flex-end">
                          <Button
                            onClick={() => history.goBack()}
                            variant="outlined"
                          >
                            {t('Page.Settings.ZoneSettings.Cancel')}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="ml-8"
                            disabled={createRegenerationState.isLoading}
                          >
                            {t(
                              'Page.Regeneration.CreateRegenerationPage.SaveRegeneration',
                            )}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                </Paper>
              )}
            </Formik>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
