import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import theme from 'theme';
import { TableIcons } from '../atoms';
import { formatDateWithDay } from '../utils';
export const UsersTable = ({ t, data }) => {
  const [filtering, setFiltering] = useState(false);

  const columns = [
    { title: 'ID', width: 10, field: 'id', hidden: false },
    {
      title: t('User.Lastname'),
      field: 'lastname',
      type: 'string',
      width: 100,
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.lastname.localeCompare(b.lastname, 'cz', {
          sensitivity: 'variant',
        }),
    },
    {
      title: t('User.Firstname'),
      field: 'firstname',
      type: 'string',
      width: 100,
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.firstname.localeCompare(b.firstname, 'cz', {
          sensitivity: 'variant',
        }),
    },
    {
      title: t('User.Nickname'),
      field: 'nickname',
      type: 'string',
      width: 25,
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    {
      title: t('User.Email'),
      field: 'email',
      type: 'string',
      width: 75,
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    {
      title: 'Aktivní',
      field: 'active',
      type: 'boolean',
      filtering: false,
      width: 10,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('Page.Administration.RegistrationDate'),
      field: 'created_at',
      type: 'date',
      render: (rowData) => formatDateWithDay(rowData.created_at),
      width: 150,
      cellStyle: { whiteSpace: 'nowrap' },
    },
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        title={t('Page.Administration.UsersList')}
        columns={columns}
        data={data}
        icons={TableIcons}
        options={{
          actionsColumnIndex: -1,
          tableLayout: 'fixed',
          pageSize: 50,
          pageSizeOptions: [5, 10, 20, 50, 100],
          filtering: filtering,
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return {
                backgroundColor: '#f2f2f2',
              };
            }
          },
        }}
        actions={[
          {
            icon: () => <FilterList />,
            tooltip: t('DataTable.EnableFilter'),
            isFreeAction: true,
            onClick: () => setFiltering(!filtering),
          },
        ]}
        localization={{
          header: {
            actions: t('DataTable.Actions'),
          },
          body: {
            deleteTooltip: t('DataTable.Delete'),
            editTooltip: t('DataTable.Edit'),
            editRow: {
              deleteText: t('DataTable.DeleteText'),
              cancelTooltip: t('DataTable.CancelTooltip'),
              saveTooltip: t('DataTable.SaveTooltip'),
            },
            toolbar: {
              searchTooltip: t('DataTable.SearchTooltip'),
              searchPlaceholder: t('DataTable.SearchPlaceholder'),
            },
            filterRow: {
              filterTooltip: t('DataTable.Filter'),
            },
          },
        }}
      />
    </MuiThemeProvider>
  );
};
