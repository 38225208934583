import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { BaseSettings } from './BaseSettings';
import { PasswordSettings } from './PasswordSettings';
import { AthleteSettings } from './AthleteSettings';
import { ZoneSettings } from './ZoneSettings';

import { LoggedInPageLayout } from '../../templates';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

export const SettingsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const errorList = [
    {
      id: 1,
      error: null,
    },
  ];

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Settings.SettingsPage.MyAccountTitle')}
            </Typography>
          </div>
          <Grid container spacing={2} className="flex-stretch">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {/* Top Selling Products */}
              <BaseSettings />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* Top Selling Products */}
              <PasswordSettings />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* Top Selling Products */}
              <AthleteSettings />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {/* Top Selling Products */}
              <ZoneSettings />
            </Grid>
          </Grid>
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
