import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { CheckBoxField, Field, DatePickerField } from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
} from '../../utils';
import { LoggedInPageLayout } from '../../templates';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export const CreateSicknessPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const createSicknessState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const {
    object,
    requiredString,
    requiredNumber,
    requiredDate,
    string,
    boolean,
  } = translatedValidations(t);

  const sicknessState = useFetchRequest(ENDPOINTS.enumSicknessTypes());

  const errorList = [
    {
      id: 1,
      error: sicknessState.error,
    },
    { id: 2, error: createSicknessState.error },
  ];

  const onSubmitMemoized = useCallback(
    (
      { problem, type, date_from, date_to, description, alternativeTraining },
      { resetForm },
    ) => {
      createSicknessState.request(ENDPOINTS.storeSickness(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          enqueueSnackbar(
            t('Page.Sickness.CreateSicknessPage.CreateSicknessAlert'),
            {
              variant: 'success',
            },
          );
          resetForm();
          history.push(PATHNAMES.getSicknessDetail(data.sickness_id));
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          problem,
          type,
          date_from,
          date_to,
          description,
          alternativeTraining,
        },
      });
    },
    [createSicknessState, enqueueSnackbar, t, history],
  );

  const schema = object({
    problem: requiredString,
    type: requiredNumber,
    date_from: requiredDate,
    date_to: requiredDate.min(
      yup.ref('date_from'),
      "end date can't be before start date",
    ),
    description: string,
    alternativeTraining: boolean,
  });

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <Button
            onClick={() => history.push(PATHNAMES.getTrainingLog('sickness'))}
          >
            <ArrowBackIosIcon fontSize="small" />
            {t('Page.TrainingLog.ActivityDetailPage.AllActivities')}
          </Button>
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Sickness.CreateSicknessPage.CreateSicknessTitle')}
            </Typography>
          </div>
          {sicknessState.isLoading && <CircularProgress />}
          {sicknessState.data && (
            <Formik
              initialValues={{
                problem: '',
                type: '',
                date_from: null,
                date_to: null,
                description: '',
                alternativeTraining: false,
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, values }) => (
                <Paper className="p-40">
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Field
                          variant="outlined"
                          required
                          fullWidth
                          id="problem"
                          label={t('Page.Sickness.CreateSicknessPage.Problem')}
                          name="problem"
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          variant="outlined"
                          required
                          fullWidth
                          select
                          id="type"
                          label={t(
                            'Page.Sickness.CreateSicknessPage.Diagnosis',
                          )}
                          name="type"
                          data={sicknessState.data.sicknesses}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePickerField
                          fullWidth
                          required
                          type="date"
                          id="date_from"
                          name="date_from"
                          disableFuture
                          animateYearScrolling={true}
                          showTodayButton
                          label={t('Page.Sickness.CreateSicknessPage.DateFrom')}
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          placeholder="dd/mm/yyyy"
                          value={values.date_from}
                          onChange={(value) =>
                            setFieldValue('date_from', value)
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePickerField
                          fullWidth
                          required
                          type="date"
                          id="date_to"
                          name="date_to"
                          disableFuture
                          animateYearScrolling={true}
                          showTodayButton
                          label={t('Page.Sickness.CreateSicknessPage.DateTo')}
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          placeholder="dd/mm/yyyy"
                          value={values.date_to}
                          onChange={(value) => setFieldValue('date_to', value)}
                        />
                      </Grid>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="description"
                        multiline
                        placeholder="Sem rozepiš omezení a průběh nemoci / zranění ..."
                        rows={5}
                        label={t('Organisms.ActivityForm.Description')}
                        name="description"
                      />
                      <CheckBoxField
                        value={values.alternativeTraining}
                        name="alternativeTraining"
                        id="alternativeTraining"
                        color="primary"
                        label={t(
                          'Page.Sickness.CreateSicknessPage.AlternativeTraining',
                        )}
                      />
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={createSicknessState.isLoading}
                        >
                          {t('Page.Sickness.CreateSicknessPage.SaveSickness')}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={() => history.goBack()}
                          fullWidth
                          variant="outlined"
                        >
                          {t('Page.Settings.ZoneSettings.Cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </Paper>
              )}
            </Formik>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
