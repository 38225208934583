import React, { useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link, MuiThemeProvider, IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import Chip from '@material-ui/core/Chip';
import PoolIcon from '@material-ui/icons/Pool';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkiingNordic, faClone } from '@fortawesome/free-solid-svg-icons';
import theme from 'theme';
import { TableIcons } from '../atoms';
import { MultipleActivityForm, ProportionSubActivityChart } from './';
import {
  formatDateWithDay,
  formatSecondsToTime,
  convertSecondsToMMSSS,
  convertToKM,
} from '../utils';
import PATHNAMES from '../pathnames';
export const TrainingLogTable = ({ t, data, history }) => {
  const [filtering, setFiltering] = useState(false);
  const [selection, setSelection] = useState(false);

  const location = useLocation();
  const personalPrivileges = matchPath(location.pathname, {
    path: '/activities/:kind',
    exact: true,
    strict: false,
  });

  const page = new URLSearchParams(location.search).get('page');
  const [pageState, setPageState] = useState(page);

  const columns = [
    { title: 'ID', field: 'activityId', hidden: true },
    {
      title: 'Sport',
      field: 'sport',
      type: 'string',
      render: (rowData) =>
        rowData.sport === 'run' ? (
          <DirectionsRunIcon />
        ) : rowData.sport === 'swim' ? (
          <PoolIcon />
        ) : rowData.sport === 'ride' ? (
          <DirectionsBikeIcon />
        ) : rowData.sport === 'workout' ||
          rowData.sport === 'weighttraining' ? (
          <FitnessCenterIcon />
        ) : rowData.sport === 'hike' ? (
          <DirectionsWalkIcon />
        ) : rowData.sport === 'nordicski' ? (
          <FontAwesomeIcon icon={faSkiingNordic} />
        ) : (
          rowData.sport
        ),
      width: 50,
      filtering: false,
    },
    {
      title: t('Organisms.TrainingLogTable.Date'),
      field: 'startDate',
      type: 'date',
      render: (rowData) => formatDateWithDay(rowData.startDate),
      width: 150,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('Organisms.TrainingLogTable.Title'),
      field: 'name',
      type: 'string',
      render: (rowData) => (
        <Link href={PATHNAMES.getActivityDetail(rowData.activityId)}>
          {rowData.name}
        </Link>
      ),
      width: 300,
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.name.localeCompare(b.name, 'cz', {
          sensitivity: 'variant',
        }),
    },
    {
      title: t('Organisms.TrainingLogTable.Type'),
      field: 'sport_type.name',
      type: 'string',
      render: (rowData) =>
        rowData.SubActivities.length > 0 ? (
          <ProportionSubActivityChart
            width={100}
            data={rowData.SubActivities}
            totalTime={rowData.time}
          />
        ) : (
          <Chip
            size="small"
            label={t('Page.TrainingLog.TrainingLogPage.Uncategorized')}
            color="secondary"
          />
        ),
      width: 150,
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: t('Organisms.TrainingLogTable.Time'),
      field: 'time',
      type: 'numeric',
      filtering: false,
      render: (rowData) =>
        rowData.time
          ? formatSecondsToTime(rowData.time)
          : formatSecondsToTime(rowData.movingTime),
      width: 100,
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: t('Organisms.TrainingLogTable.Distance'),
      field: 'distance',
      type: 'numeric',
      filtering: false,
      render: (rowData) =>
        rowData.distance
          ? convertToKM(rowData.distance) + ' km'
          : rowData.movingDistance &&
            convertToKM(rowData.movingDistance) + ' km',
      width: 120,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('Organisms.TrainingLogTable.Pace'),
      field: 'pace',
      type: 'numeric',
      render: (rowData) =>
        rowData.pace ? convertSecondsToMMSSS(rowData.pace) + ' /km' : '',
      width: 100,
      filtering: false,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('Organisms.TrainingLogTable.AverageHr'),
      field: 'avgHr',
      type: 'numeric',
      filtering: false,
      render: (rowData) => rowData.avgHr && Math.round(rowData.avgHr) + ' bpm',
      width: 150,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: 'Status',
      field: 'status',
      type: 'numeric',
      hidden: true,
      filtering: false,
    },
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        title={t('Page.TrainingLog.TrainingLogPage.MyActivities')}
        columns={columns}
        data={data}
        icons={TableIcons}
        options={{
          actionsColumnIndex: -1,
          tableLayout: 'fixed',
          initialPage: pageState && pageState - 1,
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, 50, 100],
          filtering: filtering,
          selection: selection,
          columnsButton: true,
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return {
                backgroundColor: '#f2f2f2',
              };
            }
          },
        }}
        onChangePage={(currentPage) => {
          setPageState(currentPage + 1);
          let currentUrlParams = new URLSearchParams(location.search);
          currentUrlParams.set('page', currentPage + 1);
          history.push({
            search: currentUrlParams.toString(),
          });
        }}
        actions={[
          {
            icon: <FilterList />,
            tooltip: t('DataTable.EnableFilter'),
            isFreeAction: true,
            onClick: () => setFiltering(!filtering),
          },
          {
            icon: <FontAwesomeIcon icon={faClone} />,
            tooltip: t('DataTable.MultipleSelection'),
            isFreeAction: true,
            onClick: () => setSelection(!selection),
            hidden: !personalPrivileges,
          },
          {
            icon: 'multiple-edit',
            tooltip: t('DataTable.MultipleUpdate'),
            hidden: !selection,
          },
        ]}
        components={{
          Action: (props) => {
            if (!props.action.hidden) {
              if (props.action.icon === 'multiple-edit') {
                return <MultipleActivityForm activitySelection={props.data} />;
              } else {
                return (
                  <Tooltip title={props.action.tooltip}>
                    <IconButton
                      size="small"
                      onClick={(event) =>
                        props.action.onClick(event, props.data)
                      }
                    >
                      {props.action.icon}
                    </IconButton>
                  </Tooltip>
                );
              }
            } else {
              return null;
            }
          },
        }}
        localization={{
          header: {
            actions: t('DataTable.Actions'),
          },
          body: {
            deleteTooltip: t('DataTable.Delete'),
            editTooltip: t('DataTable.Edit'),
            editRow: {
              deleteText: t('DataTable.DeleteText'),
              cancelTooltip: t('DataTable.CancelTooltip'),
              saveTooltip: t('DataTable.SaveTooltip'),
            },
            filterRow: {
              filterTooltip: t('DataTable.Filter'),
            },
          },
          toolbar: {
            searchTooltip: t('DataTable.SearchTooltip'),
            searchPlaceholder: t('DataTable.SearchPlaceholder'),
            addRemoveColumns: t('DataTable.AddRemoveColumns'),
            showColumnsTitle: t('DataTable.ShowColumnsTitle'),
            showColumnsAriaLabel: t('DataTable.ShowColumnsTitle'),
            nRowsSelected: t('DataTable.NRowsSelected'),
          },
        }}
      />
    </MuiThemeProvider>
  );
};
