import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import { UsersTable } from '../../organisms';
import { useFetchRequest } from '../../utils';
import { LoggedInPageLayout } from '../../templates';

import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

export const AdministrationPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const usersListState = useFetchRequest(ENDPOINTS.getUsersList());

  const errorList = [
    {
      id: 1,
      error: usersListState.error,
    },
  ];
  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <div className="flex justify-between items-center items-center mb-6">
            <Typography variant="h3" component="h3">
              {t('Page.Administration.AdministrationTitle')}
            </Typography>
          </div>
          <div className="m-sm-30">
            <Paper className={classes.paper}>
              {usersListState.isLoading && (
                <CircularProgress className={classes.progress} />
              )}
              {usersListState.data && (
                <UsersTable t={t} data={usersListState.data} />
              )}
            </Paper>
          </div>
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
