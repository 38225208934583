import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  Paper,
  ListItem,
  ListItemText,
  Grid,
  List,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import { SimpleAlertDialog } from '../../molecules';
import {
  useFetchRequest,
  useAuth,
  useRequest,
  formatDateTime,
  formatSecondsToTime,
} from '../../utils';
import { LoggedInPageLayout } from '../../templates';
import { EditRegenerationForm } from './EditRegenerationForm';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    margin: '20px',
  },
}));

export const RegenerationDetailPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { regenerationId } = useParams();
  const history = useHistory();
  const user = useAuth();
  const deleteRegenerationState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [regenerationStateData, setRegenerationStateData] = useState(undefined);

  const onSuccessFetchRegenerationState = (response) => {
    updateRegenerationState(response);
  };

  const fetchRegenerationState = useFetchRequest(
    ENDPOINTS.getRegenerationDetail(regenerationId),
    {
      onSuccess: onSuccessFetchRegenerationState,
    },
  );

  const updateRegenerationState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setRegenerationStateData({
        data,
      });
    },
    [],
  );

  const errorList = [
    {
      id: 1,
      error: fetchRegenerationState.error,
    },
  ];

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          {typeof regenerationStateData !== 'undefined' &&
            regenerationStateData.data &&
            (user.user.userId === regenerationStateData.data.user_id ? (
              <Button
                onClick={() =>
                  history.push(PATHNAMES.getTrainingLog('regeneration'))
                }
              >
                <ArrowBackIosIcon fontSize="small" />
                {t('Page.Regeneration.RegenerationDetailPage.AllRegenerations')}
              </Button>
            ) : (
              <Button onClick={() => history.goBack()}>
                <ArrowBackIosIcon fontSize="small" />
                {t('Page.Regeneration.RegenerationDetailPage.AllRegenerations')}
              </Button>
            ))}
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t(
                'Page.Regeneration.RegenerationDetailPage.RegenerationDetailTitle',
              )}
            </Typography>
          </div>
          {fetchRegenerationState.isLoading && (
            <CircularProgress className={classes.progress} />
          )}
          {typeof regenerationStateData !== 'undefined' &&
            regenerationStateData.data && (
              <>
                <div className="mb-12">
                  {user.user.userId === regenerationStateData.data.user_id && (
                    <>
                      <div className="flex flex-space-between">
                        <div className="flex">
                          <EditRegenerationForm
                            regenerationStateData={regenerationStateData}
                            setRegenerationStateData={setRegenerationStateData}
                          />
                        </div>
                        <SimpleAlertDialog
                          title={t(
                            'Page.Regeneration.RegenerationDetailPage.DeleteRegenerationConfirm',
                          )}
                          message={
                            t(
                              'Page.Regeneration.RegenerationDetailPage.DeleteRegenerationMessage',
                            ) +
                            ' ' +
                            regenerationStateData.data.name
                          }
                          handleConfirm={() =>
                            deleteRegenerationState.request(
                              ENDPOINTS.deleteRegeneration(),
                              {
                                method: 'DELETE',
                                onSuccess: () => {
                                  history.push(
                                    PATHNAMES.getTrainingLog('regeneration'),
                                  );
                                  enqueueSnackbar(
                                    t(
                                      'Page.Regeneration.RegenerationDetailPage.DeletedRegenerationAlert',
                                    ),
                                    {
                                      variant: 'success',
                                    },
                                  );
                                },
                                onError: (err) => {
                                  enqueueSnackbar('Uh Oh, ' + err, {
                                    variant: 'error',
                                  });
                                },
                                data: {
                                  regenerationId: regenerationId,
                                },
                              },
                            )
                          }
                          color="secondary"
                        >
                          {t('Page.Sickness.SicknessDetailPage.Delete')}
                        </SimpleAlertDialog>
                      </div>
                    </>
                  )}
                </div>
                <Paper elevation={0} className="p-20">
                  <ListItem>
                    <ListItemText
                      primary={regenerationStateData.data.name}
                      secondary={t('Organisms.RegenerationLogTable.Name')}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        regenerationStateData.data.regeneration_type.name
                      }
                      secondary={t('Organisms.RegenerationLogTable.Type')}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={formatDateTime(regenerationStateData.data.date)}
                      secondary={t('Organisms.RegenerationLogTable.Date')}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={formatSecondsToTime(
                        regenerationStateData.data.duration,
                      )}
                      secondary={t(
                        'Page.Regeneration.CreateRegenerationPage.Duration',
                      )}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={regenerationStateData.data.description}
                      secondary={t('Organisms.ActivityForm.Description')}
                    />
                  </ListItem>
                </Paper>
                {regenerationStateData.data.activity && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className="mt-12 p-20">
                        <Typography variant="h6">
                          {t(
                            'Page.Regeneration.RegenerationDetailPage.ConnectedActivities',
                          )}
                        </Typography>
                        <List dense={true}>
                          <ListItem
                            dense={true}
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(
                                PATHNAMES.getActivityDetail(
                                  regenerationStateData.data.activity
                                    .activityId,
                                ),
                              )
                            }
                            key={regenerationStateData.data.activity.activityId}
                          >
                            <ListItemText
                              primary={
                                regenerationStateData.data.activity.title +
                                ' - ' +
                                regenerationStateData.data.activity.sport_type
                                  .name +
                                ' (' +
                                formatSecondsToTime(
                                  regenerationStateData.data.activity
                                    .moving_time,
                                ) +
                                ')'
                              }
                              secondary={formatDateTime(
                                regenerationStateData.data.activity.start_date,
                              )}
                            />
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
