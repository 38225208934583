import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { ProportionStackedBar } from '../../../molecules';
import { DoughnutChart } from '../../../organisms';
import {
  convertToKM,
  convertSecondsToMMSSS,
  formatSecondsToTime,
} from '../../../utils';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
}));

export const SubActivityList = ({ subActivityList, totalTime }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subactivity"
      subheader={
        <ListSubheader component="div" id="nested-list-subactivity">
          {t('Page.TrainingLog.ActivityDetailPage.SubActivityTitle')}
        </ListSubheader>
      }
      className={classes.root}
    >
      {subActivityList.map((activity, index) => (
        <SubActivityDetail
          activity={activity}
          key={index}
          t={t}
          classes={classes}
          totalTime={totalTime}
          index={index}
        />
      ))}
    </List>
  );
};

const SubActivityDetail = ({ activity, t, classes, totalTime, index }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <img
            src={`/assets/images/illustrations/icon_${activity.sport_type.abbr.toLowerCase()}.svg`}
            width={40}
            height={40}
            alt=""
            title={activity.sport_type.name}
            className="m-auto"
          />
        </ListItemIcon>
        <ListItemText
          primary={activity.sport_type.name}
          secondary={
            <React.Fragment>
              {formatSecondsToTime(activity.time)}
              {activity.distance &&
                ' | ' + convertToKM(activity.distance) + ' km'}
              {activity.pace && ' | ' + convertSecondsToMMSSS(activity.pace)}
              {activity.elevation_gain &&
                ' | ' + activity.elevation_gain + ' m'}
              {activity.avg_hr && ' | ' + activity.avg_hr + ' bpm'}
            </React.Fragment>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box className={classes.nested}>
            <ProportionSubActivityChart
              width={300}
              data={activity}
              totalTime={totalTime}
              index={index}
            />
            <Grid container spacing={1} className="mt-10">
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  {t('Page.TrainingLog.ActivityDetailPage.Time')}:
                  <strong className="pl-10">
                    {formatSecondsToTime(activity.time)}
                  </strong>
                </Typography>
              </Grid>
              {activity.distance && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {t('Page.TrainingLog.ActivityDetailPage.Distance')}:
                    <strong className="pl-10">
                      {convertToKM(activity.distance) + ' km'}
                    </strong>
                  </Typography>
                </Grid>
              )}
              {activity.pace && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {t('Page.TrainingLog.ActivityDetailPage.Pace')}:
                    <strong className="pl-10">
                      {convertSecondsToMMSSS(activity.pace)}
                    </strong>
                  </Typography>
                </Grid>
              )}
              {activity.elevation_gain && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {t('Page.TrainingLog.ActivityDetailPage.Elevation')}:
                    <strong className="pl-10">
                      {activity.elevation_gain + ' m'}
                    </strong>
                  </Typography>
                </Grid>
              )}
              <Divider />
              {activity.avg_hr && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {t('Page.TrainingLog.ActivityDetailPage.AverageHr')}:
                    <strong className="pl-10">
                      {activity.avg_hr + ' bpm'}
                    </strong>
                  </Typography>
                </Grid>
              )}
              {activity.max_hr && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {t('Page.TrainingLog.ActivityDetailPage.MaxHr')}:
                    <strong className="pl-10">
                      {activity.max_hr + ' bpm'}
                    </strong>
                  </Typography>
                </Grid>
              )}
              {activity.avg_cadence && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {t('Page.TrainingLog.ActivityDetailPage.AverageCadence')}:
                    <strong className="pl-10">
                      {activity.sport_type.kind === 'run'
                        ? activity.avg_cadence * 2 + ' spm'
                        : activity.avg_cadence + ' rpm'}
                    </strong>
                  </Typography>
                </Grid>
              )}
            </Grid>
            {activity.Tags.length > 0 && (
              <>
                <Typography variant="body2" className="pt-8 pb-4">
                  {t('Organisms.ActivityForm.Tag')}:
                </Typography>
                <div className="flex flex-left flex-wrap">
                  {activity.Tags.map((tag) => (
                    <Chip
                      variant="outlined"
                      className="m-4"
                      label={tag.name}
                      key={tag.tag_subactivity.tag_id}
                    />
                  ))}
                </div>
              </>
            )}
            {(activity.time_zone1 ||
              activity.time_zone2 ||
              activity.time_zone3 ||
              activity.time_zone4 ||
              activity.time_zone5) && (
              <DoughnutChart
                data={[
                  {
                    value: activity.time_zone1,
                    name: t('Organisms.WeekSummaryTable.Zone1'),
                  },
                  {
                    value: activity.time_zone2,
                    name: t('Organisms.WeekSummaryTable.Zone2'),
                  },
                  {
                    value: activity.time_zone3,
                    name: t('Organisms.WeekSummaryTable.Zone3'),
                  },
                  {
                    value: activity.time_zone4,
                    name: t('Organisms.WeekSummaryTable.Zone4'),
                  },
                  {
                    value: activity.time_zone5,
                    name: t('Organisms.WeekSummaryTable.Zone5'),
                  },
                ]}
                height="200px"
                color={[
                  theme.palette.primary.light,
                  theme.palette.primary.main,
                  theme.palette.primary.dark,
                  theme.palette.secondary.dark,
                  theme.palette.secondary.main,
                ]}
              />
            )}
          </Box>
        </List>
      </Collapse>
    </>
  );
};

const ProportionSubActivityChart = ({ width, data = [], totalTime, index }) => {
  const barChartData = [
    {
      id: index,
      text: data.sport_type.name + ' ' + formatSecondsToTime(data.time),
      ratio: (data.time * 100) / totalTime,
      color: data.sport_type.color,
    },
  ];
  return <ProportionStackedBar width={width} data={barChartData} />;
};
