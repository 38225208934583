import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Formik, Form, FieldArray } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { TextMaskCustom } from '../../atoms';
import {
  Field,
  RadioField,
  Slider,
  FeelingField,
  DatePickerField,
  AutoSuggest,
} from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
  validateTimeInZones,
  validatePace,
  formatSecondsToTime,
} from '../../utils';
import { LoggedInPageLayout } from '../../templates';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export const ManualActivityPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const createActivityState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [hrLockState, setHrLockState] = useState({
    enabledHR: false,
  });

  const handleChangeEnableHR = () => {
    setHrLockState({
      enabledHR: !hrLockState.enabledHR,
    });
  };

  const {
    object,
    requiredString,
    requiredNumber,
    requiredDate,
    number,
    string,
    time,
    requiredTime,
    url,
  } = translatedValidations(t);

  const subActivityValues = [
    {
      type: '',
      time: '',
      distance: '',
      pace: '',
      avgHr: '',
      maxHr: '',
      avgCadence: '',
      elevationGain: '',
      enableHrZ: false,
      zone1: '',
      zone2: '',
      zone3: '',
      zone4: '',
      zone5: '',
      tag: [],
    },
  ];

  const enumSport = useFetchRequest(ENDPOINTS.enumSportTypes());
  const enumTag = useFetchRequest(ENDPOINTS.enumTags());

  const errorList = [
    {
      id: 1,
      error: enumSport.error,
    },
    {
      id: 2,
      error: enumTag.error,
    },
    { id: 3, error: createActivityState.error },
  ];

  const onSubmitMemoized = useCallback(
    (
      {
        name,
        date,
        description,
        tracking,
        map,
        result,
        feeling,
        exertion,
        calories,
        temperature,
        weather,
        subactivity,
        type,
      },
      { resetForm },
    ) => {
      createActivityState.request(ENDPOINTS.storeActivity(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          enqueueSnackbar(
            t('Page.Activity.ManualActivityPage.CreateActivityAlert'),
            {
              variant: 'success',
            },
          );
          resetForm();
          history.push(PATHNAMES.getActivityDetail(data.activity_id));
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          name,
          date,
          utcOffset: new Date().getTimezoneOffset() * -1 * 60,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          description,
          tracking,
          map,
          result,
          feeling,
          exertion,
          calories,
          temperature,
          weather,
          subactivity,
          type,
        },
      });
    },
    [createActivityState, enqueueSnackbar, t, history],
  );

  const calculatePace = (e, handleChange, setFieldValue, values) => {
    const index = parseInt(e.target.name.split('.')[1]);
    if (values.subactivity[index].time && values.subactivity[index].distance) {
      const ts = values.subactivity[index].time.split(':');
      const time = Date.UTC(1970, 0, 1, ts[0], ts[1], ts[2]) / 1000;
      const pace = time / values.subactivity[index].distance;
      handleChange(e);
      setFieldValue(`subactivity.${index}.pace`, formatSecondsToTime(pace));
    }
  };

  const weatherOptions = [
    { value: 'clear', label: 'jasno' },
    { value: 'clouds', label: 'oblačno' },
    { value: 'drizzle', label: 'mrholení' },
    { value: 'rain', label: 'déšť' },
    { value: 'thunderstorm', label: 'bouřka' },
    { value: 'snow', label: 'sníh' },
    { value: 'mist', label: 'mlha' },
  ];

  const schema = object({
    name: requiredString,
    date: requiredDate,
    description: string,
    type: requiredString,
    tracking: url,
    map: url,
    result: url,
    exertion: number,
    feeling: number,
    calories: number,
    temperature: number,
    weather: string,
    subactivity: Yup.array()
      .of(
        Yup.object().shape({
          type: requiredNumber,
          time: requiredTime,
          distance: number,
          pace: time.test(
            'pace-time-distance',
            t('Organisms.ActivityForm.PaceValidity'),
            validatePace,
          ),
          avgHr: number,
          maxHr: number,
          avgCadence: number,
          elevationGain: number,
          tag: Yup.array().of(
            Yup.object().shape({
              value: requiredNumber,
              title: requiredString,
            }),
          ),
          zone1: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone2: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone3: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone4: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
          zone5: time.test(
            'total-zone-time-gt-time',
            t('Validations.TimeInZones'),
            validateTimeInZones,
          ),
        }),
      )
      .min(1, 'The activity must have atleast one subactivity'),
  });

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <Button
            onClick={() => history.push(PATHNAMES.getTrainingLog('training'))}
          >
            <ArrowBackIosIcon fontSize="small" />
            {t('Page.TrainingLog.ActivityDetailPage.AllActivities')}
          </Button>
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Activity.ManualActivityPage.ManualActivityTitle')}
            </Typography>
          </div>
          {enumSport.isLoading && enumTag.isLoading && <CircularProgress />}
          {enumSport.data && enumTag.data && (
            <Formik
              initialValues={{
                name: '',
                date: null,
                description: '',
                tracking: '',
                map: '',
                result: '',
                calories: '',
                temperature: '',
                weather: '',
                type: 'T',
                feeling: null,
                exertion: 50,
                subactivity: subActivityValues || [],
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, handleChange, values, errors, touched }) => (
                <Paper className="p-40">
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <Field
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          label={t('Organisms.ActivityForm.ActivityName')}
                          name="name"
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <DatePickerField
                          fullWidth
                          required
                          type="date-time"
                          id="date"
                          name="date"
                          ampm={false}
                          disableFuture
                          animateYearScrolling={true}
                          showTodayButton
                          label={t('Organisms.ActivityForm.Date')}
                          inputVariant="outlined"
                          format="dd/MM/yyyy HH:mm"
                          placeholder="dd/mm/yyyy HH:MM"
                          value={values.date}
                          onChange={(value) => setFieldValue('date', value)}
                        />
                      </Grid>
                      <Field
                        variant="outlined"
                        fullWidth
                        id="description"
                        multiline
                        placeholder={t(
                          'Organisms.ActivityForm.DescribeTrainingProcess',
                        )}
                        minRows={5}
                        label={t('Organisms.ActivityForm.Description')}
                        name="description"
                      />
                      <Grid item xs={12} sm={6}>
                        <Field
                          variant="outlined"
                          type="url"
                          fullWidth
                          id="tracking"
                          label={t('Organisms.ActivityForm.Tracking')}
                          placeholder={t(
                            'Organisms.ActivityForm.InsertTrackingLink',
                          )}
                          name="tracking"
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          variant="outlined"
                          type="url"
                          fullWidth
                          id="map"
                          label={t('Organisms.ActivityForm.Doma')}
                          placeholder={t(
                            'Organisms.ActivityForm.InsertMapLink',
                          )}
                          name="map"
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          variant="outlined"
                          type="url"
                          fullWidth
                          id="result"
                          label={t('Organisms.ActivityForm.Results')}
                          placeholder={t(
                            'Organisms.ActivityForm.InsertResultsLink',
                          )}
                          name="result"
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Field
                          variant="outlined"
                          fullWidth
                          id="calories"
                          type="number"
                          label={t('Organisms.ActivityForm.Calories')}
                          placeholder="Calories"
                          name="calories"
                          InputProps={{
                            inputProps: { min: 0, step: 'any' },
                            endAdornment: (
                              <InputAdornment position="end">
                                kCal
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Field
                          variant="outlined"
                          fullWidth
                          id="temperature"
                          type="number"
                          label={t('Organisms.ActivityForm.Temperature')}
                          placeholder={t(
                            'Organisms.ActivityForm.WorkoutWeather',
                          )}
                          name="temperature"
                          InputProps={{
                            inputProps: {
                              min: -40,
                              max: 40,
                              step: 'any',
                            },
                            endAdornment: (
                              <InputAdornment position="end">°C</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Field
                          variant="outlined"
                          fullWidth
                          id="weather"
                          select
                          data={weatherOptions}
                          label={t('Organisms.ActivityForm.Weather')}
                          name="weather"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FeelingField
                          id="feeling"
                          name="feeling"
                          label={t('Organisms.ActivityForm.Feeling')}
                          defaultValue={2}
                          required
                          value={values.feeling}
                          onChange={(event, value) =>
                            setFieldValue('feeling', value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Slider
                          className="ml-20"
                          id="exertion"
                          name="exertion"
                          label={t('Organisms.ActivityForm.Exertion')}
                          defaultValue={50}
                          value={values.exertion}
                          onChange={(event, value) =>
                            setFieldValue('exertion', value)
                          }
                        />
                      </Grid>
                      <Grid item xs={5} sm={3}>
                        <RadioField
                          id="type"
                          name="type"
                          label={t('Organisms.ActivityForm.Type')}
                          color="primary"
                          data={[
                            {
                              value: 'T',
                              label: t('Organisms.ActivityForm.Training'),
                            },
                            {
                              value: 'R',
                              label: t('Organisms.ActivityForm.Race'),
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                    <FieldArray
                      name="subactivity"
                      render={(arrayHelpers) => (
                        <div>
                          {values.subactivity &&
                            values.subactivity.length > 0 &&
                            values.subactivity.map((element, index) => (
                              <div
                                key={index}
                                className="my-20 p-16 bg-light-stripped-primary"
                              >
                                {values.subactivity.length > 1 && (
                                  <h5>
                                    {t('Organisms.ActivityForm.SubActivity')}{' '}
                                    {index + 1}
                                  </h5>
                                )}
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={3}>
                                    <Field
                                      fullWidth
                                      required
                                      id={`subactivity.${index}.type`}
                                      variant="outlined"
                                      select
                                      name={`subactivity.${index}.type`}
                                      data={enumSport.data.sports}
                                      label={t('Organisms.ActivityForm.Sport')}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Field
                                      variant="outlined"
                                      fullWidth
                                      required
                                      type="text"
                                      id={`subactivity.${index}.time`}
                                      label={t('Organisms.ActivityForm.Time')}
                                      placeholder="HH:MM:SS"
                                      name={`subactivity.${index}.time`}
                                      InputProps={{
                                        inputComponent: TextMaskCustom,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            h:m:s
                                          </InputAdornment>
                                        ),
                                      }}
                                      InputLabelProps={{
                                        shrink: values.subactivity[index].time
                                          ? true
                                          : false,
                                      }}
                                      onBlur={(e) => {
                                        handleChange(e);
                                        calculatePace(
                                          e,
                                          handleChange,
                                          setFieldValue,
                                          values,
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Field
                                      variant="outlined"
                                      fullWidth
                                      type="number"
                                      id={`subactivity.${index}.distance`}
                                      label={t(
                                        'Organisms.ActivityForm.Distance',
                                      )}
                                      name={`subactivity.${index}.distance`}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            km
                                          </InputAdornment>
                                        ),
                                        inputProps: {
                                          min: 0,
                                          step: 'any',
                                        },
                                      }}
                                      onBlur={(e) => {
                                        handleChange(e);
                                        calculatePace(
                                          e,
                                          handleChange,
                                          setFieldValue,
                                          values,
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Field
                                      variant="outlined"
                                      fullWidth
                                      type="text"
                                      id={`subactivity.${index}.pace`}
                                      label={t('Organisms.ActivityForm.Pace')}
                                      placeholder="HH:MM:SS"
                                      name={`subactivity.${index}.pace`}
                                      InputProps={{
                                        inputComponent: TextMaskCustom,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            m:s
                                          </InputAdornment>
                                        ),
                                      }}
                                      InputLabelProps={{
                                        shrink: values.subactivity[index].pace
                                          ? true
                                          : false,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <AutoSuggest
                                      options={enumTag.data.tags}
                                      variant="outlined"
                                      fullWidth
                                      filterSelectedOptions
                                      multiple
                                      id={`subactivity.${index}.tag`}
                                      name={`subactivity.${index}.tag`}
                                      placeholder={t(
                                        'Organisms.ActivityForm.TagPlaceholder',
                                      )}
                                      label={t('Organisms.ActivityForm.Tag')}
                                      value={values.subactivity[index].tag}
                                      onChange={(event, value) =>
                                        setFieldValue(
                                          `subactivity.${index}.tag`,
                                          value,
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={6}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          type="number"
                                          id={`subactivity.${index}.avgHr`}
                                          label={t(
                                            'Organisms.ActivityForm.AvgHr',
                                          )}
                                          name={`subactivity.${index}.avgHr`}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                bpm
                                              </InputAdornment>
                                            ),
                                            inputProps: {
                                              min: 0,
                                              step: 'any',
                                            },
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          type="number"
                                          id={`subactivity.${index}.maxHr`}
                                          label={t(
                                            'Organisms.ActivityForm.MaxHr',
                                          )}
                                          name={`subactivity.${index}.maxHr`}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                bpm
                                              </InputAdornment>
                                            ),
                                            inputProps: {
                                              min: 0,
                                              step: 'any',
                                            },
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          type="number"
                                          id={`subactivity.${index}.elevationGain`}
                                          label={t(
                                            'Organisms.ActivityForm.Elevation',
                                          )}
                                          name={`subactivity.${index}.elevationGain`}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                m
                                              </InputAdornment>
                                            ),
                                            inputProps: {
                                              min: 0,
                                              step: 'any',
                                            },
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          type="number"
                                          id={`subactivity.${index}.avgCadence`}
                                          label={t(
                                            'Organisms.ActivityForm.Cadence',
                                          )}
                                          name={`subactivity.${index}.avgCadence`}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                rpm
                                              </InputAdornment>
                                            ),
                                            inputProps: {
                                              min: 0,
                                              step: 'any',
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormGroup row>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={hrLockState.enabledHR}
                                            onChange={handleChangeEnableHR}
                                            name={`subactivity.${index}.enableHrZ`}
                                            id={`subactivity.${index}.enableHrZ`}
                                            color="primary"
                                          />
                                        }
                                        label={t(
                                          'Organisms.ActivityForm.EnabledHR',
                                        )}
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Collapse
                                    in={hrLockState.enabledHR}
                                    timeout="auto"
                                    style={{ width: '100%' }}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={3}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          id={`subactivity.${index}.zone1`}
                                          type="text"
                                          label={t(
                                            'Organisms.ActivityForm.Zone1',
                                          )}
                                          placeholder="HH:MM:SS"
                                          name={`subactivity.${index}.zone1`}
                                          disabled={!hrLockState.enabledHR}
                                          InputProps={{
                                            inputComponent: TextMaskCustom,
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                h:m:s
                                              </InputAdornment>
                                            ),
                                          }}
                                          InputLabelProps={{
                                            shrink: values.subactivity[index]
                                              .zone1
                                              ? true
                                              : false,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          id={`subactivity.${index}.zone2`}
                                          type="text"
                                          label={t(
                                            'Organisms.ActivityForm.Zone2',
                                          )}
                                          placeholder="HH:MM:SS"
                                          name={`subactivity.${index}.zone2`}
                                          disabled={!hrLockState.enabledHR}
                                          InputProps={{
                                            inputComponent: TextMaskCustom,
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                h:m:s
                                              </InputAdornment>
                                            ),
                                          }}
                                          InputLabelProps={{
                                            shrink: values.subactivity[index]
                                              .zone2
                                              ? true
                                              : false,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          id={`subactivity.${index}.zone3`}
                                          type="text"
                                          label={t(
                                            'Organisms.ActivityForm.Zone3',
                                          )}
                                          placeholder="HH:MM:SS"
                                          name={`subactivity.${index}.zone3`}
                                          disabled={!hrLockState.enabledHR}
                                          InputProps={{
                                            inputComponent: TextMaskCustom,
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                h:m:s
                                              </InputAdornment>
                                            ),
                                          }}
                                          InputLabelProps={{
                                            shrink: values.subactivity[index]
                                              .zone3
                                              ? true
                                              : false,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6} sm={3}></Grid>
                                      <Grid item xs={6} sm={3}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          id={`subactivity.${index}.zone4`}
                                          type="text"
                                          label={t(
                                            'Organisms.ActivityForm.Zone4',
                                          )}
                                          placeholder="HH:MM:SS"
                                          name={`subactivity.${index}.zone4`}
                                          disabled={!hrLockState.enabledHR}
                                          InputProps={{
                                            inputComponent: TextMaskCustom,
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                h:m:s
                                              </InputAdornment>
                                            ),
                                          }}
                                          InputLabelProps={{
                                            shrink: values.subactivity[index]
                                              .zone4
                                              ? true
                                              : false,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <Field
                                          variant="outlined"
                                          fullWidth
                                          id={`subactivity.${index}.zone5`}
                                          type="text"
                                          label={t(
                                            'Organisms.ActivityForm.Zone5',
                                          )}
                                          placeholder="HH:MM:SS"
                                          name={`subactivity.${index}.zone5`}
                                          disabled={!hrLockState.enabledHR}
                                          InputProps={{
                                            inputComponent: TextMaskCustom,
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                h:m:s
                                              </InputAdornment>
                                            ),
                                          }}
                                          InputLabelProps={{
                                            shrink: values.subactivity[index]
                                              .zone5
                                              ? true
                                              : false,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6} sm={3}>
                                        <Button
                                          fullWidth
                                          name={`subactivity.${index}.resetZones`}
                                          id={`subactivity.${index}.resetZones`}
                                          variant="outlined"
                                          color="secondary"
                                          disabled={!hrLockState.enabledHR}
                                          onClick={() => {
                                            for (let i = 1; i <= 5; i++) {
                                              setFieldValue(
                                                `subactivity.${index}.zone${i}`,
                                                '00:00:00',
                                              );
                                            }
                                          }}
                                        >
                                          {t(
                                            'Organisms.ActivityForm.ResetZones',
                                          )}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Collapse>
                                  {errors.subactivity &&
                                    touched.subactivity &&
                                    errors.subactivity[index] &&
                                    errors.subactivity[index].zone1 &&
                                    values.subactivity[index].time &&
                                    hrLockState.enabledHR === false && (
                                      <Grid item xs={12}>
                                        <Alert severity="error">
                                          {errors.subactivity[index].zone1}
                                        </Alert>
                                      </Grid>
                                    )}
                                  {typeof index !== 'undefined' && index > 0 && (
                                    <Tooltip
                                      title={t(
                                        'Organisms.ActivityForm.RemoveSubactivity',
                                      )}
                                    >
                                      <IconButton
                                        aria-label="remove-subactivity"
                                        size="medium"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          values.subactivity.splice(index, 1);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </div>
                            ))}
                          <Grid item xs={12}>
                            <Button
                              name="addSubactivity"
                              id="addSubActivity"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                arrayHelpers.push({
                                  type: '',
                                  time: '',
                                  distance: '',
                                  pace: '',
                                  avgHr: '',
                                  maxHr: '',
                                  avgCadence: '',
                                  elevationGain: '',
                                  enableHrZ: false,
                                  zone1: '',
                                  zone2: '',
                                  zone3: '',
                                  zone4: '',
                                  zone5: '',
                                  tag: [],
                                })
                              }
                            >
                              {/* show this when user has removed all friends from the list */}
                              {t('Organisms.ActivityForm.AddSubactivity')}
                            </Button>
                          </Grid>
                        </div>
                      )}
                    />
                    <Grid container spacing={2} className="mt-24">
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={createActivityState.isLoading}
                        >
                          {t('Organisms.ActivityForm.SaveActivity')}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={() => history.goBack()}
                          fullWidth
                          variant="outlined"
                        >
                          {t('Page.Settings.ZoneSettings.Cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </Paper>
              )}
            </Formik>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
