const apiPrefix = '/api';

const enumPrefix = `${apiPrefix}/enum`;

export default {
  login: () => `${apiPrefix}/auth/login`,
  signUp: () => `${apiPrefix}/auth/register-user`,
  activateUser: () => `${apiPrefix}/auth/activate-user`,
  resetPassword: () => `${apiPrefix}/auth/reset-password`,
  resetPasswordConfirmation: () =>
    `${apiPrefix}/auth/reset-password-confirmation`,
  setFavourite: () => `${apiPrefix}/runner/set-favourite`,

  // athlete
  getAthleteStatistics: () => `${apiPrefix}/athlete/athlete-statistics`,
  getAthleteStatisticsPerPeriod: (periodStart, periodEnd) =>
    `${apiPrefix}/athlete/athlete-statistics?periodStart=${periodStart}&periodEnd=${periodEnd}`,
  getAthleteStatisticsPerSeason: (seasonId) =>
    `${apiPrefix}/athlete/athlete-statistics?seasonId=${seasonId}`,

  // activity
  getUnidentifiedActivity: (page) =>
    `${apiPrefix}/athlete/unidentifiedactivity?page=${page}`,
  updateActivity: () => `${apiPrefix}/athlete/update-activity`,
  fullUpdateActivity: () => `${apiPrefix}/athlete/full-update-activity`,
  deleteActivity: () => `${apiPrefix}/athlete/delete-activity`,
  storeActivity: () => `${apiPrefix}/athlete/store-activity`,
  multipleUpdateActivity: () => `${apiPrefix}/athlete/multiple-update-activity`,
  getTrainingLog: () => `${apiPrefix}/athlete/training-log`,
  getActivityDetail: (activityId) =>
    `${apiPrefix}/athlete/activity/${activityId}`,
  getActivityStream: (activityId) =>
    `${apiPrefix}/athlete/activity/${activityId}/stream`,
  getActivityLap: (activityId) =>
    `${apiPrefix}/athlete/activity/${activityId}/laps`,
  getActivityComments: (activityId) =>
    `${apiPrefix}/athlete/activity/${activityId}/comments`,
  addActivityComment: () => `${apiPrefix}/athlete/activity/store-comment`,
  getActivityList: () => `${apiPrefix}/athlete/activity-list`,
  getActivitySuggestList: () => `${apiPrefix}/athlete/training-list`,
  getSubActivityValues: () =>
    `${apiPrefix}/athlete/activity/subactivity-values`,

  // sickness
  getAthleteSicknessLog: () => `${apiPrefix}/athlete/sickness`,
  getSicknessDetail: (id) => `${apiPrefix}/athlete/sickness/${id}`,
  storeSickness: () => `${apiPrefix}/athlete/sickness/store-sickness`,
  updateSicknesss: () => `${apiPrefix}/athlete/sickness/update-sickness`,
  deleteSickness: () => `${apiPrefix}/athlete/sickness/delete-sickness`,
  addAttachmentToSickness: () =>
    `${apiPrefix}/athlete/sickness/store-attachment`,
  getSicknessAllAttachment: (id) =>
    `${apiPrefix}/athlete/sickness/${id}/attachments`,
  getSicknessAttach: (id) => `${apiPrefix}/athlete/sickness/attachment/${id}`,
  deleteSicknessAttachment: () =>
    `${apiPrefix}/athlete/sickness/delete-attachment`,

  // regeneration
  getAthleteRegenerationLog: () => `${apiPrefix}/athlete/regeneration`,
  getRegenerationDetail: (id) => `${apiPrefix}/athlete/regeneration/${id}`,
  storeRegeneration: () =>
    `${apiPrefix}/athlete/regeneration/store-regeneration`,
  updateRegeneration: () =>
    `${apiPrefix}/athlete/regeneration/update-regeneration`,
  deleteRegeneration: () =>
    `${apiPrefix}/athlete/regeneration/delete-regeneration`,

  // strava authorization
  stravaAuthorization: () => `${apiPrefix}/auth/strava/authorize`,
  stravaTokenExchange: () => `${apiPrefix}/auth/strava/exchange-token`,

  // settings
  getSettings: () => `${apiPrefix}/settings/user-profile`,
  updateUserSettings: () => `${apiPrefix}/settings/update-profile`,
  updatePassword: () => `${apiPrefix}/settings/update-password`,
  getAthleteSettings: () => `${apiPrefix}/settings/athlete-profile`,
  updateAthleteSettings: () => `${apiPrefix}/settings/update-athlete`,
  getHeartZonesSettings: () => `${apiPrefix}/settings/heart-zones`,
  storeHeartZoneSettings: () => `${apiPrefix}/settings/store-zone`,
  updateHeartZoneSettings: () => `${apiPrefix}/settings/update-zone`,
  deleteHeartZoneSettings: () => `${apiPrefix}/settings/delete-zone`,
  checkAthleteSettings: () => `${apiPrefix}/settings/check-profile`,
  disconnectStrava: () => `${apiPrefix}/settings/disconnect-strava`,

  // enums
  enumEvents: () => `${enumPrefix}/events`,
  enumSports: () => `${enumPrefix}/sports`,
  enumSportTypes: () => `${enumPrefix}/sport-type`,
  enumSicknessTypes: () => `${enumPrefix}/sickness-type`,
  enumRegenerationTypes: () => `${enumPrefix}/regeneration-type`,
  enumTags: () => `${enumPrefix}/tags`,

  //teams
  getTeamList: () => `${apiPrefix}/team/list`,
  storeNewTeam: () => `${apiPrefix}/team/new-team`,
  updateTeamSettings: () => `${apiPrefix}/team/update-team`,
  addMemberToTeam: () => `${apiPrefix}/team/store-member`,
  addCoachToTeam: () => `${apiPrefix}/team/add-coach`,
  getTeamMembersList: (id) => `${apiPrefix}/team/${id}/member-list`,
  getTeamMembers: (id) => `${apiPrefix}/team/${id}/team-members`,
  updateTeamMemberSettings: (id) => `${apiPrefix}/team/${id}/member-update`,
  deleteTeamMember: (id) => `${apiPrefix}/team/${id}/member-delete`,
  deleteTeamInvitation: (id) => `${apiPrefix}/team/${id}/invitation-delete`,
  deleteTeam: (id) => `${apiPrefix}/team/${id}/team-delete`,
  getTeamDetail: (id) => `${apiPrefix}/team/${id}`,
  getJoinToTeamDetail: (teamHash) =>
    `${apiPrefix}/join-team/join-team-info/${teamHash}`,
  joinToTeam: () => `${apiPrefix}/join-team/join-to-team`,
  getTeamStats: (id) => `${apiPrefix}/team/${id}/stats`,
  getTeamAthleteDetail: (teamId, userId) =>
    `${apiPrefix}/team/detail/${teamId}/athlete/${userId}`,
  getTeamAthleteTrainingLog: (teamId, userId) =>
    `${apiPrefix}/team/detail/${teamId}/athlete/${userId}/training-log`,
  getTeamAthleteCalendar: (teamId, userId) =>
    `${apiPrefix}/team/detail/${teamId}/athlete/${userId}/calendar`,

  //administration
  getUsersList: () => `${apiPrefix}/administration/users`,

  //season
  getSeasonPeriod: () => `${apiPrefix}/season/period`,
  getSeasonPeriodForSport: (sportId) =>
    `${apiPrefix}/season/sport/${sportId}/period`,

  //export
  exportTrainingLog: (memberId) =>
    `${apiPrefix}/export/training-log/${memberId}`,
  exportTrainingLogPerPeriod: (memberId, periodStart, periodEnd) =>
    `${apiPrefix}/export/training-log/${memberId}?periodStart=${periodStart}&periodEnd=${periodEnd}`,
};
