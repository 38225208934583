import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

import { SpeedDials } from '../../atoms';
import { TabPanel, a11yProps } from '../../molecules';
import { TrainingLogTable } from '../../organisms';
import { useFetchRequest } from '../../utils';
import { LoggedInPageLayout } from '../../templates';
import { SicknessLog } from './SicknessLog';
import { RegenerationLog } from './RegenerationLog';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
  tab: {
    '& .MuiBox-root': {
      padding: '0px',
    },
  },
}));

const LinkTab = (props) => {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

export const TrainingLogPage = ({ match }) => {
  const tabNameToIndex = {
    0: 'training',
    1: 'sickness',
    2: 'regeneration',
  };

  const indexToTabName = {
    training: 0,
    sickness: 1,
    regeneration: 2,
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const trainingLogState = useFetchRequest(ENDPOINTS.getTrainingLog());
  const [selectedTab, setSelectedTab] = useState(
    indexToTabName[match.params.kind],
  );

  const handleChange = (event, newValue) => {
    history.push(PATHNAMES.getTrainingLog(tabNameToIndex[newValue]));
    setSelectedTab(newValue);
  };

  const errorList = [
    {
      id: 1,
      error: trainingLogState.error,
    },
  ];
  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <div className="flex justify-between items-center items-center mb-6">
            <Typography variant="h3" component="h3">
              {t('Page.TrainingLog.TrainingLogPage.TrainingLogTitle')}
            </Typography>
          </div>
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
            color="primary"
            onClick={() => history.push(PATHNAMES.activityManual())}
          >
            {t('Page.TrainingLog.TrainingLogPage.AddActivity')}
          </Button>
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
            color="primary"
            onClick={() => history.push(PATHNAMES.sicknessCreate())}
          >
            {t('Page.TrainingLog.TrainingLogPage.AddSickness')}
          </Button>
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
            color="primary"
            onClick={() => history.push(PATHNAMES.regenerationCreate())}
          >
            {t('Page.TrainingLog.TrainingLogPage.AddRegeneration')}
          </Button>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="activity tabs"
            className="my-8"
          >
            <LinkTab
              label={t('Page.TrainingLog.TrainingLogPage.Activity')}
              href="/training"
              {...a11yProps(0)}
            />
            <LinkTab
              label={t('Page.TrainingLog.TrainingLogPage.Sickness')}
              href="/sickness"
              {...a11yProps(1)}
            />
            <LinkTab
              label={t('Page.TrainingLog.TrainingLogPage.Regeneration')}
              href="/regeneration"
              {...a11yProps(2)}
            />
          </Tabs>
          <TabPanel
            value={selectedTab}
            index={0}
            classes={{ root: classes.tab }}
          >
            <Paper className="mb-20">
              {trainingLogState.isLoading && (
                <CircularProgress className={classes.progress} />
              )}
              {trainingLogState.data && (
                <TrainingLogTable
                  t={t}
                  data={trainingLogState.data}
                  history={history}
                />
              )}
            </Paper>
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={1}
            classes={{ root: classes.tab }}
          >
            <SicknessLog t={t} />
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={2}
            classes={{ root: classes.tab }}
          >
            <RegenerationLog t={t} />
          </TabPanel>
        </div>
      </div>
      <SpeedDials />
    </LoggedInPageLayout>
  );
};
