import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CircularProgress, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ErrorBox } from '../../atoms';
import { Field } from '../../organisms';
import { useRequest, translatedValidations } from '../../utils';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
}));

export const PasswordSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { object, requiredString, passwordsDontMatch } = translatedValidations(
    t,
  );

  const [state, setState] = useState({
    showPassword: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const updatePasswordSettingsState = useRequest();

  const onSubmitMemoized = useCallback(
    ({ oldPassword, newPassword }, { resetForm }) => {
      updatePasswordSettingsState.request(ENDPOINTS.updatePassword(), {
        method: 'PUT',
        onSuccess: () => {
          enqueueSnackbar(
            t('Page.Settings.PasswordSettings.PasswordChangedAlert'),
            {
              variant: 'success',
            },
          );
          resetForm();
        },
        data: {
          oldPassword,
          newPassword,
        },
      });
    },
    [updatePasswordSettingsState, enqueueSnackbar, t],
  );

  const schema = object({
    oldPassword: requiredString,
    newPassword: requiredString,
    newPasswordConfirmation: passwordsDontMatch('newPassword'),
  });

  const handleClickShowPassword = () => {
    setState({ showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const visibilityButton = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {state.showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
  return (
    <Card elevation={3} className="p-20 h-100">
      <div className="card-title mb-12">
        {t('Page.Settings.PasswordSettings.PasswordSettingsTitle')}
      </div>
      <ErrorBox
        errorList={[{ id: 1, error: updatePasswordSettingsState.error }]}
      />
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmitMemoized}
      >
        {() => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="oldPassword"
                  label={t('Page.Settings.PasswordSettings.OldPassword')}
                  name="oldPassword"
                  type={state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: visibilityButton,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="newPassword"
                  label={t('Page.Settings.PasswordSettings.NewPassword')}
                  name="newPassword"
                  autoComplete="new-password"
                  type={state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: visibilityButton,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="newPasswordConfirmation"
                  label={t(
                    'Page.Settings.PasswordSettings.NewPasswordConfirmation',
                  )}
                  name="newPasswordConfirmation"
                  autoComplete="confirm-password"
                  type={state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: visibilityButton,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={updatePasswordSettingsState.isLoading}
                  >
                    <VpnKeyIcon />
                    {'   '}
                    {t('Page.Settings.PasswordSettings.SubmitPasswordSettings')}
                  </Button>
                  {updatePasswordSettingsState.isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
