export default {
  empty: () => '/',
  signIn: () => '/signin',
  signUp: () => `${registrationBase}`,
  privacyPolicy: () => '/legal/privacy-policy',
  passwordReset: () => '/password-reset',
  passwordResetConfirmation: () => '/password-reset-confirmation/:userHash',
  getPasswordResetConfirmation: () => '/password-reset-confirmation',
  passwordResetRequest: () => '/password-reset-request',
  landing: () => '/landing',
  dashboard: () => '/dashboard',
  calendar: () => '/calendar',
  trainingLog: () => `${activityBase}/:kind?`,
  getTrainingLog: (kind) => `${activityBase}/${kind}`,
  activityDetail: () => `${activityBase}/training/detail/:activityId`,
  getActivityDetail: (activityId) =>
    `${activityBase}/training/detail/${activityId}`,
  activityManual: () => `/activity/manual`,
  sicknessCreate: () => `${activityBase}/sickness/add`,
  sicknessDetail: () => `${activityBase}/sickness/detail/:sicknessId`,
  getSicknessDetail: (sicknessId) =>
    `${activityBase}/sickness/detail/${sicknessId}`,
  regenerationCreate: () => `${activityBase}/regeneration/add`,
  regenerationDetail: () =>
    `${activityBase}/regeneration/detail/:regenerationId`,
  getRegenerationDetail: (regenerationId) =>
    `${activityBase}/regeneration/detail/${regenerationId}`,
  settings: () => '/settings/profile',
  activateUser: () => `${activateUserBase}/:userHash`,
  getActivateUser: () => activateUserBase,
  team: () => '/team',
  teamDetail: () => '/team/:teamId',
  getTeamDetail: (id) => `/team/${id}`,
  teamSettings: () => '/team/:teamId/settings',
  getTeamSettings: (id) => `/team/${id}/settings`,
  teamMemberDetail: () => `/team/:teamId/athlete/:userId/:view`,
  getTeamMemberDetail: (teamId, userId, view) =>
    `/team/${teamId}/athlete/${userId}/${view}`,
  joinToTeam: () => `${joinToTeamBase}/:teamHash`,
  getJoinToTeam: () => joinToTeamBase,
  upload: () => '/upload/service',
  stravaTokenExchange: () => '/upload/strava/exchange-token',
  stravaGuide: () => '/upload/strava/guide',
  signUpWithPrefilledEmail: () => `${registrationBase}/:email`,
  getSignUpWithPrefilledEmail: (prefilledEmail) =>
    `${registrationBase}/${prefilledEmail}`,
  results: () => '/results',
  competitionDetail: () => '/results/:eventId',
  getCompetitionDetail: (id) => `/results/${id}`,
  administration: () => '/administration',
};

const registrationBase = '/signup';
const activateUserBase = '/activate-user';
const activityBase = '/activities';
const joinToTeamBase = '/join-to-team';
