import { useFetchRequest } from '../../../utils';
import ENDPOINTS from '../../../endpoints';

export const useFetchData = (teamId, userId) => {
  const athleteState = useFetchRequest(
    ENDPOINTS.getTeamAthleteDetail(teamId, userId),
  );
  const enumSportState = useFetchRequest(ENDPOINTS.enumSportTypes());

  return { athleteState, enumSportState };
};
