import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../atoms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
  emailsStringToEmailArray,
} from '../../utils';
import PATHNAMES from '../../pathnames';
import ENDPOINTS from '../../endpoints';

import { NewTeamField } from './NewTeamField';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  text: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export const NewTeamForm = ({ open, handleClose, teamListState }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const storeNewTeamState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const sportState = useFetchRequest(ENDPOINTS.enumSports());

  const {
    object,
    requiredString,
    string,
    date,
    requiredNumber,
    uniqueMinMaxEmails,
  } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ name, description, sport, emails, startDate, endDate }) => {
      storeNewTeamState.request(ENDPOINTS.storeNewTeam(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          handleClose();
          teamListState.data.push();
          enqueueSnackbar(t('Page.Team.NewTeamForm.TeamAdded'), {
            variant: 'success',
          });
          history.push(PATHNAMES.getTeamDetail(data));
        },
        data: {
          name,
          description,
          sport,
          emails: Object.values(emailsStringToEmailArray(emails)),
          startDate,
          endDate,
        },
      });
    },
    [
      storeNewTeamState,
      handleClose,
      enqueueSnackbar,
      t,
      teamListState.data,
      history,
    ],
  );

  const schema = object({
    name: requiredString,
    description: string,
    sport: requiredNumber,
    emails: uniqueMinMaxEmails({ min: 1, max: 100 }),
    startDate: date,
    endDate: date,
  });

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        isDisabled={storeNewTeamState.isLoading}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <ErrorBox
              errorList={[
                { id: 1, error: storeNewTeamState.error },
                { id: 2, error: sportState.error },
              ]}
            />
            <h2 id="transition-modal-title">
              {t('Page.Team.NewTeamForm.FormTitle')}
            </h2>
            <Formik
              initialValues={{
                name: '',
                description: '',
                sport: '',
                emails: '',
                startDate: null,
                endDate: null,
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {() => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <NewTeamField t={t} sportState={sportState} />
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={storeNewTeamState.isLoading}
                      >
                        {t('Page.Team.NewTeamForm.SaveTeam')}
                      </Button>
                      <Button
                        onClick={handleClose}
                        fullWidth
                        variant="outlined"
                      >
                        {t('Page.Settings.ZoneSettings.Cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
