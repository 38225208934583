import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGoogle,
  faFacebookF,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { Link as MaterialLink, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PATHNAMES from '../../pathnames';
import { CopyrightWithBox } from '../../molecules';
import './css/settings.css';
import './css/style.css';

const useStyles = makeStyles((theme) => ({
  heading: {
    [theme.breakpoints.down('lg')]: {
      justify: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      justify: 'start',
    },
  },
  offset2: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '16.666667%',
    },
  },
  offset1: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '8.333333%',
    },
  },
}));

export const LandingPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className="landing-wrapper">
      <header className="site-header">
        <Container>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
            style={{ position: 'relative' }}
          >
            <Grid item sm={4} xs={6} lg={2} xl={2}>
              <div className="brand">
                <Link to={PATHNAMES.empty()}>
                  <img src="logo512.png" alt="Tréninkáč logo" width="50px" />
                </Link>
              </div>
            </Grid>
            <Grid
              item
              sm={1}
              xs={6}
              lg={7}
              xl={6}
              style={{ position: 'static' }}
            >
              <div className="main-navigation">
                <ul className="main-menu">
                  <li className="menu-item has-dropdown">
                    <a href="#features">Co Tréninkáč umí?</a>
                    <ul className="menu-dropdown">
                      <li className="single-item">
                        <Link to="#">
                          <h3>Načítání aktivit z „chytrých hodinek“</h3>
                          <p>včetně využití hodnot SF</p>
                        </Link>
                      </li>
                      <li className="single-item">
                        <Link to="#">
                          <h3>Automatický výpočet zátěže tréninku</h3>
                          <p>dle tréninkových zón</p>
                        </Link>
                      </li>
                      <li className="single-item">
                        <Link to="#">
                          <h3>Sdílení dat další osobě</h3>
                          <p>tréninkové skupiny, osobní trenér</p>
                        </Link>
                      </li>
                      <li className="single-item">
                        <Link to="#">
                          <h3>Zapisovat všechny hodnoty ručně</h3>
                          <p>třeba hned z mobilního telefonu</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="menu-item">
                    <a href="#contact">Kontakt</a>
                  </li>
                </ul>
              </div>
              <div className="mobile-menu"></div>
            </Grid>

            <Grid item sm={7} lg={3} xl={3} display={('none', { sm: 'block' })}>
              <div className="header-btns">
                <div className="btn-1 btn-outlined">
                  <Link to={PATHNAMES.signIn()}>
                    {t('Page.Auth.SignIn.SignIn')}
                  </Link>
                </div>
                <div className="btn-2">
                  <Link to={PATHNAMES.signUp()}>
                    {t('Page.Auth.SignUp.SignUp')}
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </header>

      <div className="hero-area pt-4 pt-lg-5">
        <div className="float-shapes"></div>
        <Container className="mt-24">
          <Grid
            container
            spacing={2}
            direction="row"
            className={classes.heading}
          >
            <Grid item md={8} lg={7} className={classes.offset2}>
              <h1 className="main-title">Lepší místo pro tvoje tréninky.</h1>
            </Grid>
            <Grid item md={8} lg={6} className={classes.offset2}>
              <div className="hero-content-wrapper">
                <div className="main-content">
                  <p>
                    Tréninkáč je elektronický tréninkový deník, který maximálně
                    využívá nové technologie a pracuje s daty z chytrých hodinek
                    &#8212; vedení deníku je tak mnohem jednodušší a
                    pohodlnější.
                  </p>
                </div>
                <div className="compatible">
                  <span>Kompatibilní s:</span>
                  <ul className="compatible-list">
                    <li className="list-item">
                      <MaterialLink
                        href="https://www.strava.com"
                        target="_blank"
                        color="inherit"
                        key="strava"
                      >
                        <img
                          src="/assets/images/logos/strava_symbol_orange.png"
                          alt="Strava"
                          title="STRAVA"
                          width="50px"
                        />
                      </MaterialLink>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item lg={4} className="pt-sm-24">
              <div className="hero-btn">
                <Link
                  to={PATHNAMES.signUp()}
                  className="btn btn--primary btn-height-60"
                >
                  Začít trénovat s tréninkáčem
                </Link>
              </div>
            </Grid>
            <Grid item lg={12}>
              <div className="hero-img">
                <img
                  src="/assets/images/screenshots/laptop_image.png"
                  alt="Mockup Tréninkáč"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <section className="client-section">
        <Container>
          <div className="client-brand-wrapper">
            <div className="single-brand">
              <MaterialLink
                href="https://www.orientacnisporty.cz"
                target="_blank"
                color="inherit"
                key="help"
              >
                <img
                  src="/assets/images/logos/logo_ceskysvazorientacnichsportu.svg"
                  alt="Český svaz orientačních sportů"
                  width="300px"
                />
              </MaterialLink>
            </div>
          </div>
        </Container>
      </section>
      <section className="content-section-01">
        <Container>
          <Grid container spacing={2} direction="row">
            <Grid item lg={5} md={6} sm={8}>
              <div className="content-image">
                <img
                  src="/assets/images/illustrations/data_analysis.png"
                  alt="Data analysis"
                  width="550px"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} className={classes.offset1}>
              <div className="content-right-content">
                <h2>Komplexní data a jednoduchý přístup k tréninkům .</h2>
                <p>
                  Přistupuj ke svým tréninkům odkuliv a měj data stále při sobě.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="content-section-02">
        <Container>
          <Grid container spacing={2} direction="row">
            <Grid item xl={5} lag={5} sm={12} md={6}>
              <div className="content-text content-left-content">
                <h2>Tréninkáč je upravený pro metodiku sportu.</h2>
                {/*
                <div className="content-small-card">
                  <div className="card-icon">
                    <i className="icon icon-users-mm-2"></i>
                  </div>
                  <div className="card-content">
                    <h5>Built for real users</h5>
                    <p>
                      Editing and customizing Essential Landing is easy and
                      fast.
                    </p>
                  </div>
                </div>
                <div className="content-small-card">
                  <div className="card-icon">
                    <i className="icon icon-settings"></i>
                  </div>
                  <div className="card-content">
                    <h5>Easy setup process</h5>
                    <p>
                      Editing and customizing Essential Landing is easy and
                      fast.
                    </p>
                  </div>
                </div>
                */}
              </div>
            </Grid>
            <Grid
              item
              xl={5}
              lg={5}
              sm={10}
              md={6}
              xs={10}
              display={('none', { md: 'block' })}
            >
              <div className="content-image">
                <img
                  src="/assets/images/illustrations/sport.svg"
                  alt="Sport - trail run training"
                  width="500px"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/*
      <section className="content-section-03">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-sm-6 col-md-6 col-10 d-none d-md-block">
              <div className="content-img">
                <img src="./image/content-image-3.png" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-md-6 offset-xl-1 col-sm-12">
              <div className="content-right-content">
                <h2>Never miss a thing</h2>
                <p>
                  Event is not like most tech conferences. We want our
                  presentations to engage the audience, spark discussion and
                  inspire new ideas.
                </p>
                <ul className="content-list">
                  <li>
                    <span>
                      <i className="icon icon-check-2"></i>
                    </span>
                    Unlimited design possibility
                  </li>
                  <li>
                    <span>
                      <i className="icon icon-check-2"></i>
                    </span>
                    Completely responsive
                  </li>
                  <li>
                    <span>
                      <i className="icon icon-check-2"></i>
                    </span>
                    Easy to customize
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title">
                <h2>One Software, Every Solution</h2>
                <p>
                  We designed and tested prototypes that helped identify pain
                  points in the account creation process. Together, we shaped
                  the new standard.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row mb-d-30">
                <div className="col-sm-6 mb--30">
                  <div className="feature-widget">
                    <div className="widget-icon">
                      <i className="icon icon-edit-72"></i>
                    </div>
                    <div className="content">
                      <h5>Easy to Use</h5>
                      <p>
                        Whether it’s a small internal app or a new for millions
                        of customers, our design and development teams.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb--30">
                  <div className="feature-widget">
                    <div className="widget-icon">
                      <i className="icon icon-layers-3"></i>
                    </div>
                    <div className="content">
                      <h5>300+ Blocks</h5>
                      <p>
                        Whether it’s a small internal app or a new for millions
                        of customers, our design and development teams.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb--30">
                  <div className="feature-widget">
                    <div className="widget-icon">
                      <i className="icon icon-tablet-mobile"></i>
                    </div>
                    <div className="content">
                      <h5>100% Responsive</h5>
                      <p>
                        Whether it’s a small internal app or a new for millions
                        of customers, our design and development teams.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb--30">
                  <div className="feature-widget">
                    <div className="widget-icon">
                      <i className="icon icon-book-open-2"></i>
                    </div>
                    <div className="content">
                      <h5>Rich Documentation</h5>
                      <p>
                        Whether it’s a small internal app or a new for millions
                        of customers, our design and development teams.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb--30">
                  <div className="feature-widget">
                    <div className="widget-icon">
                      <i className="icon icon-webpage"></i>
                    </div>
                    <div className="content">
                      <h5>50+ New Pages</h5>
                      <p>
                        Whether it’s a small internal app or a new for millions
                        of customers, our design and development teams.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb--30">
                  <div className="feature-widget">
                    <div className="widget-icon">
                      <i className="icon icon-l-settings"></i>
                    </div>
                    <div className="content">
                      <h5>Quick Setup</h5>
                      <p>
                        Whether it’s a small internal app or a new for millions
                        of customers, our design and development teams.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title">
                <h2>30,000+ Customers Trust Us</h2>
                <p>
                  We designed and tested prototypes that helped identify pain
                  points in the account creation process. Together, we shaped
                  the new standard.
                </p>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-wrapper">
            <div className="row justify-content-center position-relative">
              <div className="col-lg-10 ">
                <div className="testimonial-slider">
                  <div className="single-slide">
                    <div className="testimonial-content">
                      <p>
                        “It’s really helped us grow. I can keep all of the
                        customer’s information in there so if I need to look up
                        something in the future or call them for a follow-up, I
                        have all of that there so it’s really helped us organize
                        the whole business.”
                      </p>
                      <div className="t-small-card">
                        <div className="image">
                          <img src="./image/t6-nav-1.png" alt="" />
                        </div>
                        <div className="content">
                          <h5>Gilbert Palmer</h5>
                          <span>Founder of Crips</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-slide">
                    <div className="testimonial-content">
                      <p>
                        “It’s really helped us grow. I can keep all of the
                        customer’s information in there so if I need to look up
                        something in the future or call them for a follow-up, I
                        have all of that there so it’s really helped us organize
                        the whole business.”
                      </p>
                      <div className="t-small-card">
                        <div className="image">
                          <img src="./image/t6-nav-2.png" alt="" />
                        </div>
                        <div className="content">
                          <h5>Lucy Rios</h5>
                          <span>Design Director at Craze</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-slide">
                    <div className="testimonial-content">
                      <p>
                        “It’s really helped us grow. I can keep all of the
                        customer’s information in there so if I need to look up
                        something in the future or call them for a follow-up, I
                        have all of that there so it’s really helped us organize
                        the whole business.”
                      </p>
                      <div className="t-small-card">
                        <div className="image">
                          <img src="./image/t3-nav-1.png" alt="" />
                        </div>
                        <div className="content">
                          <h5>Sallie Lawson</h5>
                          <span>Owner of Crips</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-btns"></div>
            </div>
          </div>
        </div>
      </section>
      */}
      <section className="cta-section" id="contact">
        <Container>
          <div className="cta-inner-wrapper">
            <Grid container spacing={2} direction="row" justify="center">
              <Grid item xl={7} lg={8} style={{ position: 'relative' }}>
                <div className="cta-content">
                  <span className="title-small-text">
                    Připraven začít používat Tréninkáč?
                  </span>
                  <h2>Chceš se dozvědět víc?</h2>
                  <p>
                    Jsme otevřeni jakýmkoliv dotazům, podnětům na vylepšení a
                    rozšíření. Neváhej nás kontaktovat.
                  </p>
                  <div className="cta-btn">
                    <a
                      href="mailto:treninkac@orientacnisporty.cz"
                      className="btn btn-outlined--white btn-with-icon btn-height-100"
                    >
                      Dozvědět se více
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>

      <section className="footer-section">
        <Container>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
            className="text-center"
          >
            <Grid item lg={2} md={3}>
              <div className="footer-logo">
                <Link to={PATHNAMES.empty()}>
                  <img
                    src="/assets/images/logos/treninkac_logo_text.png"
                    alt="Tréninkáč logo"
                    width="122px"
                  />
                </Link>
              </div>
            </Grid>
            <Grid item lg={6} md={6} className={classes.offset1}>
              <ul className="footer-nav">
                <li className="nav-item">
                  <a className="nav-link active" href="/">
                    O nás
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Funkce
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    Kontakt
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item lg={2} md={3} className={classes.offset1}>
              <ul className="footer-social-list">
                <li>
                  <MaterialLink href="#" color="inherit">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </MaterialLink>
                </li>
                <li>
                  <MaterialLink href="#" color="inherit">
                    <FontAwesomeIcon icon={faInstagram} />
                  </MaterialLink>
                </li>
                <li>
                  <MaterialLink href="#" color="inherit">
                    <FontAwesomeIcon icon={faTwitter} />
                  </MaterialLink>
                </li>
                <li>
                  <MaterialLink href="#" color="inherit">
                    <FontAwesomeIcon icon={faGoogle} />
                  </MaterialLink>
                </li>
              </ul>
            </Grid>
          </Grid>
          <div className="copyright">
            <CopyrightWithBox />
          </div>
        </Container>
      </section>
    </div>
  );
};
