export function validateTimeInZones() {
  const { time, zone1, zone2, zone3, zone4, zone5 } = this.parent;
  const zones = [zone1, zone2, zone3, zone4, zone5];
  if (typeof time === 'undefined') return false;
  const totalZoneTime = zones
    .filter((zone) => typeof zone !== 'undefined' && zone !== 'undefined')
    .reduce((previousValue, currentValue) => {
      const zoneArray = currentValue.split(':'); // splitting the string by colon
      const seconds = zoneArray[0] * 3600 + zoneArray[1] * 60 + +zoneArray[2]; // converting
      return (previousValue = (previousValue || 0) + seconds);
    }, 0);
  const timeArray = time.split(':'); // splitting the string by colon
  const totalSeconds = timeArray[0] * 3600 + timeArray[1] * 60 + +timeArray[2]; // converting
  if (totalZoneTime > totalSeconds) {
    return false;
  } else {
    return true;
  }
}

export function validatePace() {
  const { time, distance, pace } = this.parent;
  if (typeof time === 'undefined') return false;
  if (
    typeof distance === 'undefined' ||
    distance === 0 ||
    typeof pace === 'undefined'
  )
    return true;
  const timeArray = time.split(':'); // splitting the string by colon
  const timeSeconds = timeArray[0] * 3600 + timeArray[1] * 60 + +timeArray[2]; // converting
  const calculatedPace = timeSeconds / distance;
  const paceArray = pace.split(':'); // splitting the string by colon
  const paceSeconds = paceArray[0] * 3600 + paceArray[1] * 60 + +paceArray[2]; // converting
  const percentageDiff = +Math.abs(
    100 - (paceSeconds / calculatedPace) * 100,
  ).toFixed(10);
  if (percentageDiff > 35) {
    return false;
  } else {
    return true;
  }
}
