import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../../atoms';
import { Field } from '../../../organisms';
import {
  useRequest,
  translatedValidations,
  emailsStringToEmailArray,
} from '../../../utils';
import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const AddMemberToTeam = ({ teamId }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();
  const storeMemberState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const { object, uniqueMinMaxEmails } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ teamId, emails }) => {
      storeMemberState.request(ENDPOINTS.addMemberToTeam(), {
        method: 'POST',
        onSuccess: () => {
          handleClose();
          enqueueSnackbar(t('Page.Team.AddMemberToTeam.SuccessAlert'), {
            variant: 'success',
          });
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          teamId,
          emails: Object.values(emailsStringToEmailArray(emails)),
        },
      });
    },
    [storeMemberState, handleClose, enqueueSnackbar, t],
  );

  const schema = object({
    emails: uniqueMinMaxEmails({ min: 1, max: 100 }),
  });

  return (
    <>
      <Button
        className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
        color="primary"
        onClick={handleClickOpen}
      >
        {t('Page.Team.AddMemberToTeam.AddMembers')}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Page.Team.AddMemberToTeam.AddMembers')}
        </DialogTitle>
        <Formik
          initialValues={{
            emails: '',
            teamId: teamId,
          }}
          validationSchema={schema}
          onSubmit={onSubmitMemoized}
        >
          {() => (
            <Form className={classes.form}>
              <DialogContent>
                <ErrorBox
                  errorList={[{ id: 1, error: storeMemberState.error }]}
                />
                <DialogContentText>
                  {t('Page.Team.AddMemberToTeam.FillEmailsDescription')}
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      rows={10}
                      type="text"
                      id="emails"
                      label={t('Page.Team.NewTeamForm.Emails')}
                      placeholder={t('Page.Team.NewTeamForm.EmailsPlaceholder')}
                      name="emails"
                      minWidth="sm"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('Page.Team.AddMemberToTeam.Cancel')}
                </Button>
                <Button
                  type="submit"
                  disabled={storeMemberState.isLoading}
                  color="primary"
                  autoFocus
                >
                  {t('Page.Team.AddMemberToTeam.Send')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
