import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHNAMES from './pathnames';
import {
  ActivateUserPage,
  SignInPage,
  SignUpPage,
  NotFoundPage,
  PasswordResetPage,
  PasswordResetConfirmationPage,
  PasswordResetRequestPage,
  LandingPage,
  DashboardPage,
  TrainingLogPage,
  ActivityDetailPage,
  ManualActivityPage,
  PrivacyPolicyPage,
  UploadPage,
  SettingsPage,
  StravaGuidePage,
  TokenPage,
  TeamPage,
  TeamDetailPage,
  TeamSettingsPage,
  TeamMemberDetailPage,
  JoinToTeamPage,
  CalendarPage,
  SicknessDetailPage,
  CreateSicknessPage,
  RegenerationDetailPage,
  CreateRegenerationPage,
  AdministrationPage,
} from './pages';
import { useAuth, hasPrivilege } from './utils';
export function Routes() {
  return (
    <Switch>
      <Route path={PATHNAMES.signIn()} exact component={SignInPage} />
      <Route path={PATHNAMES.signUp()} exact component={SignUpPage} />
      {/* new email route for prefill email, when go from joinToTeam page and not already registered */}
      <Route
        path={PATHNAMES.signUpWithPrefilledEmail()}
        exact
        component={SignUpPage}
      />
      <Route
        path={PATHNAMES.privacyPolicy()}
        exact
        component={PrivacyPolicyPage}
      />
      <Route path={PATHNAMES.landing()} exact component={LandingPage} />
      <Route
        path={PATHNAMES.passwordReset()}
        exact
        component={PasswordResetPage}
      />
      <Route
        path={PATHNAMES.activateUser()}
        exact
        component={ActivateUserPage}
      />
      <Route path={PATHNAMES.joinToTeam()} exact component={JoinToTeamPage} />
      <Route
        path={PATHNAMES.passwordResetConfirmation()}
        exact
        component={PasswordResetConfirmationPage}
      />
      <Route
        path={PATHNAMES.passwordResetRequest()}
        exact
        component={PasswordResetRequestPage}
      />

      {/* Login required routes */}
      <Redirect exact from={PATHNAMES.empty()} to={PATHNAMES.dashboard()} />
      <PrivateRoute path={PATHNAMES.empty()} exact component={DashboardPage} />

      <PrivateRoute
        path={PATHNAMES.dashboard()}
        exact
        component={DashboardPage}
      />
      <PrivateRoute
        path={PATHNAMES.calendar()}
        exact
        component={CalendarPage}
      />
      <PrivateRoute path={PATHNAMES.team()} exact component={TeamPage} />
      <PrivateRoute
        path={PATHNAMES.teamDetail()}
        exact
        component={TeamDetailPage}
      />
      <PrivateRoute
        path={PATHNAMES.teamSettings()}
        exact
        component={TeamSettingsPage}
      />
      <PrivateRoute
        path={PATHNAMES.teamMemberDetail()}
        exact
        component={TeamMemberDetailPage}
      />
      <Redirect exact from="/activities" to="/activities/training" />
      <PrivateRoute
        path={PATHNAMES.trainingLog()}
        exact
        component={TrainingLogPage}
      />
      <PrivateRoute
        path={PATHNAMES.activityDetail()}
        exact
        component={ActivityDetailPage}
      />
      <PrivateRoute
        path={PATHNAMES.activityManual()}
        exact
        component={ManualActivityPage}
      />
      <PrivateRoute
        path={PATHNAMES.sicknessDetail()}
        exact
        component={SicknessDetailPage}
      />
      <PrivateRoute
        path={PATHNAMES.sicknessCreate()}
        exact
        component={CreateSicknessPage}
      />
      <PrivateRoute
        path={PATHNAMES.regenerationDetail()}
        exact
        component={RegenerationDetailPage}
      />
      <PrivateRoute
        path={PATHNAMES.regenerationCreate()}
        exact
        component={CreateRegenerationPage}
      />
      <PrivateRoute
        path={PATHNAMES.settings()}
        exact
        component={SettingsPage}
      />
      <PrivateRoute path={PATHNAMES.upload()} exact component={UploadPage} />
      <PrivateRoute
        path={PATHNAMES.stravaGuide()}
        exact
        component={StravaGuidePage}
      />
      <PrivateRoute
        path={PATHNAMES.stravaTokenExchange()}
        exact
        component={TokenPage}
      />

      {/* Login and privilege required routes */}

      <PrivilegePrivateRoute
        path={PATHNAMES.administration()}
        exact
        component={AdministrationPage}
        privilege="AdministrationPage"
      />

      {/* Not found route */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to={PATHNAMES.signIn()} />
      }
    />
  );
};

const PrivilegePrivateRoute = ({
  component: Component,
  privilege,
  ...rest
}) => {
  const { token, privileges } = useAuth();

  const routeHasPrivilege = hasPrivilege(privileges, privilege);

  return (
    <Route
      {...rest}
      render={(props) =>
        token && routeHasPrivilege ? (
          <Component privilege={privilege} {...props} />
        ) : (
          <Redirect to={PATHNAMES.signin()} />
        )
      }
    />
  );
};
