import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import csCZ from 'date-fns/locale/cs';

import { LoggedInPageLayout } from '../../templates';
import { useFetchRequest } from '../../utils';
import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    margin: '20px',
  },
}));

const locales = {
  cs: csCZ,
  'en-US': enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const VIEWS = ['month', 'week', 'day', 'agenda'];

const Event = ({ event }) => {
  return (
    <span>
      <strong>{event.title}</strong>
      <p>{event.desc && event.desc}</p>
    </span>
  );
};

export const CalendarPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const activityCalendarState = useFetchRequest(ENDPOINTS.getActivityList());

  const location = useLocation();
  const view = new URLSearchParams(location.search).get('view');
  const date = new URLSearchParams(location.search).get('date');
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  const isValidDate = isValid(parsedDate);
  const [searchState, setSearchState] = useState({
    view: VIEWS.includes(view) ? view : 'month',
    defaultView: 'month',
    date: isValidDate
      ? format(parsedDate, 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
  });

  const errorList = [
    {
      id: 1,
      error: activityCalendarState.error,
    },
  ];

  const mapToRBCFormat = (event) =>
    Object.assign({}, event, {
      title: event.race === true ? '🏆 ' + event.title : event.title,
      start: new Date(event.start),
      end: new Date(event.end),
    });

  const eventStyleGetter = (event) => {
    let backgroundColor;
    if (event.hexColor) {
      backgroundColor = '#' + event.hexColor;
    }
    const style = {
      backgroundColor: backgroundColor,
      display: 'block',
    };
    return {
      style: style,
    };
  };

  const eventSelectGetter = (event) => {
    if (event.type === 'activity') {
      return history.push(PATHNAMES.getActivityDetail(event.id));
    }
    if (event.type === 'sickness') {
      return history.push(PATHNAMES.getSicknessDetail(event.id));
    }
    if (event.type === 'regeneration') {
      return history.push(PATHNAMES.getRegenerationDetail(event.id));
    }
  };

  const handleViewChange = useCallback(
    (currentView) => {
      setSearchState((prevState) => ({ ...prevState, view: currentView }));
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('view', currentView);
      history.push({
        search: currentUrlParams.toString(),
      });
    },
    [setSearchState, history, location],
  );

  const handleDateChange = useCallback(
    (currentDate) => {
      setSearchState((prevState) => ({
        ...prevState,
        date: format(currentDate, 'yyyy-MM-dd'),
      }));
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('date', format(currentDate, 'yyyy-MM-dd'));
      history.push({
        search: currentUrlParams.toString(),
      });
    },
    [setSearchState, history, location],
  );

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.CalendarPage.CalendarTitle')}
            </Typography>
          </div>
          {activityCalendarState.isLoading && (
            <CircularProgress className={classes.progress} />
          )}
          {activityCalendarState.data && (
            <Paper elevation={0} className="p-20">
              <Calendar
                culture="cs"
                localizer={localizer}
                events={activityCalendarState.data.activityList.map(
                  mapToRBCFormat,
                )}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                onSelectEvent={eventSelectGetter}
                eventPropGetter={eventStyleGetter}
                defaultView={searchState.defaultView}
                view={searchState.view}
                onView={handleViewChange}
                date={new Date(searchState.date)}
                onNavigate={handleDateChange}
                components={{
                  event: Event,
                }}
              />
            </Paper>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
