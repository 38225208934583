import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export const ProportionStackedPiece = ({ data }) => {
  return (
    <Tooltip title={data.label} arrow>
      <div
        style={{
          position: 'absolute',
          backgroundColor: data.backgroundColor,
          height: 20,
          left: data.xAxis,
          bottom: -10,
          width: data.width,
          index: 100,
        }}
      ></div>
    </Tooltip>
  );
};
