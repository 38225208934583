import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Grid, Button, CircularProgress } from '@material-ui/core';
import { Field, CheckBoxField } from '../../../organisms';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import LogRocket from 'logrocket';
import Scrollbar from 'react-perfect-scrollbar';

import { useAuth, useRequest, translatedValidations } from '../../../utils';
import PATHNAMES from '../../../pathnames';
import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export const SignInPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { signin } = useAuth();
  const loginState = useRequest();

  const onSuccess = useCallback(
    ({ data: { results } }) => {
      const token = results.data.token;
      const user = results.data.user;
      const privileges = results.data.privileges;
      const avatar = results.data.avatar;
      signin({ token, user, privileges, avatar });
      LogRocket.identify(user.userId, {
        name: user.lastName + ' ' + user.firstName,
      });
      history.push(PATHNAMES.dashboard());
    },
    [signin, history],
  );

  const onSubmitMemoized = useCallback(
    ({ email, password, remember }) => {
      loginState.request(ENDPOINTS.login(), {
        method: 'POST',
        onSuccess,
        data: { email, password, remember },
      });
    },
    [loginState, onSuccess],
  );

  const { object, requiredString, requiredEmail } = translatedValidations(t);

  const schema = object({
    email: requiredEmail,
    password: requiredString,
  });

  return (
    <Scrollbar>
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img src="/assets/images/illustrations/dreamer.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <Formik
                    initialValues={{ email: '', password: '', remember: false }}
                    validationSchema={schema}
                    onSubmit={onSubmitMemoized}
                  >
                    <Form className={classes.form}>
                      <Field
                        className="mb-24 w-100"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        type="email"
                        label={t('User.Email')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={loginState.error}
                      />
                      <Field
                        className="mb-24 w-100"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('User.Password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                      <CheckBoxField
                        className="mb-8"
                        value="remember"
                        name="remember"
                        id="remember"
                        color="primary"
                        label={t('Page.Auth.SignIn.RememberMe')}
                      />
                      <div className="flex flex-middle mb-8">
                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loginState.isLoading}
                          >
                            {t('Page.Auth.SignIn.SignIn')}
                          </Button>
                          {loginState.isLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                        <span className="ml-16 mr-8">
                          {t('Page.Auth.SignIn.Or')}
                        </span>
                        <Button
                          className="capitalize"
                          onClick={() => history.push(PATHNAMES.signUp())}
                        >
                          {t('Page.Auth.SignUp.SignUp')}
                        </Button>
                      </div>
                      <Button
                        className="text-primary"
                        onClick={() => history.push(PATHNAMES.passwordReset())}
                      >
                        {t('Page.Auth.SignIn.PasswordReset?')}
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </Scrollbar>
  );
};
