import React from 'react';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import { BarChart } from '.';
import { formatSecondsToTime } from '../utils';

export const CycleBarChart = ({ data, teamPeriod, enumSports }) => {
  const countCycles =
    differenceInCalendarWeeks(
      parseISO(teamPeriod.periodEnd),
      parseISO(teamPeriod.periodStart),
      1,
    ) / 4;
  const xAxisCategory = Array.from({ length: countCycles }, (_, i) => i + 1);
  const seriesData = [];
  enumSports.map((sport) => {
    const sportSum = [];
    for (let index = 1; index <= countCycles; index++) {
      const cycle = data[index];
      if (cycle && cycle !== undefined) {
        const result = cycle.find(({ type_id }) => type_id === sport.value);
        if (result && result !== undefined) {
          sportSum.push(result.time);
        } else {
          sportSum.push(0);
        }
      } else {
        sportSum.push(0);
      }
    }
    seriesData.push({
      name: sport.label,
      type: 'bar',
      stack: 'stackbar',
      barGap: 0,
      data: sportSum,
      label: {
        show: false,
        formatter: function (value) {
          return formatSecondsToTime(value);
        },
      },
    });
  });
  return (
    <BarChart
      height="400px"
      data={seriesData}
      xAxisData={xAxisCategory}
      color={[
        '#ae2012',
        '#ff4d6d',
        '#f77f00',
        '#fcbf49',
        '#a7c957',
        '#386641',
        '#3c1642',
        '#5fa8d3',
        '#edddd4',
        '#9a8c98',
        '#772e25',
        '#006d77',
      ]}
    />
  );
};
