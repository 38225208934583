import React from 'react';
import { Link } from '@material-ui/core';
import PATHNAMES from '../pathnames';

export const Brand = ({ children }) => {
  return (
    <div className="flex flex-middle flex-space-between brand-area">
      <div className="flex flex-middle brand">
        <Link href={PATHNAMES.dashboard()}>
          <img src="/assets/images/logos/logo.png" alt="company-logo" />
        </Link>
        <span className="brand__text">Tréninkáč</span>
      </div>
      {children}
    </div>
  );
};
