import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../../atoms';
import { TeamMembersTable } from '../../../organisms';
import { useFetchRequest, useRequest, useAuth } from '../../../utils';
import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
}));

export const TeamMemberSettings = ({ teamId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useAuth();

  const updateMemberState = useRequest();
  const deleteMemberState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [teamMemberState, setTeamMemberState] = useState(undefined);

  const onSuccessFetchTeamMemberState = (response) => {
    updateTeamMemberState(response);
  };

  const fetchTeamMembersState = useFetchRequest(
    ENDPOINTS.getTeamMembers(teamId),
    {
      onSuccess: onSuccessFetchTeamMemberState,
    },
  );

  const updateTeamMemberState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setTeamMemberState({
        data,
      });
    },
    [],
  );

  const handleRowUpdate = (newData, oldData, resolve) => {
    updateMemberState.request(ENDPOINTS.updateTeamMemberSettings(teamId), {
      method: 'PUT',
      onSuccess: () => {
        enqueueSnackbar(t('Page.Team.TeamSettings.MemberUpdated'), {
          variant: 'success',
        });
        const role = newData.role;
        setTeamMemberState({
          data: teamMemberState.data.map((item) =>
            item.userId === oldData.userId ? { ...item, role } : item,
          ),
        });
        resolve();
      },
      data: {
        memberId: oldData.userId,
        role: newData.role,
      },
    });
  };
  const handleRowDelete = (oldData, resolve) => {
    console.log(oldData);
    deleteMemberState.request(ENDPOINTS.deleteTeamMember(teamId), {
      method: 'DELETE',
      onSuccess: () => {
        enqueueSnackbar(t('Page.Team.TeamSettings.MemberDeleted'), {
          variant: 'success',
        });
        setTeamMemberState({
          data: teamMemberState.data.filter(
            (item) => item.userId !== oldData.userId,
          ),
        });
        resolve();
      },
      data: {
        memberId: oldData.userId,
      },
    });
  };

  const handleAllSelectedDelete = (data) => {
    console.log(data);
    if (data && data.length > 0) {
      data.map((row) => {
        if (row.userId !== user.user.userId) {
          deleteMemberState.request(ENDPOINTS.deleteTeamMember(teamId), {
            method: 'DELETE',
            onSuccess: () => {
              enqueueSnackbar(t('Page.Team.TeamSettings.MemberDeleted'), {
                variant: 'success',
              });
              setTeamMemberState({
                data: teamMemberState.data.filter(
                  (item) => item.userId !== row.userId,
                ),
              });
            },
            data: {
              memberId: row.userId,
            },
          });
        }
      });
    }
  };

  return (
    <Paper className={classes.paper}>
      <ErrorBox errorList={[{ id: 1, error: fetchTeamMembersState.error }]} />
      {fetchTeamMembersState.isLoading && (
        <CircularProgress className={classes.progress} />
      )}
      {typeof teamMemberState !== 'undefined' && teamMemberState.data && (
        <TeamMembersTable
          user={user}
          t={t}
          data={teamMemberState.data}
          handleRowUpdate={handleRowUpdate}
          handleRowDelete={handleRowDelete}
          handleAllSelectedDelete={handleAllSelectedDelete}
        />
      )}
    </Paper>
  );
};
