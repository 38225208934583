import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../../atoms';
import { AutoSuggest } from '../../../organisms';
import {
  useRequest,
  translatedValidations,
  useFetchRequest,
} from '../../../utils';
import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SetCoachPrivileges = ({ teamId }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();
  const storeMemberState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const membersState = useFetchRequest(ENDPOINTS.getTeamMembersList(teamId));

  const { object, requiredString } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ teamId, member }) => {
      storeMemberState.request(ENDPOINTS.addCoachToTeam(), {
        method: 'POST',
        onSuccess: () => {
          handleClose();
          enqueueSnackbar(t('Page.Team.SetCoachPrivileges.SuccessAlert'), {
            variant: 'success',
          });
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          teamId,
          member,
        },
      });
    },
    [storeMemberState, handleClose, enqueueSnackbar, t],
  );

  const schema = object({
    member: requiredString,
  });

  return (
    <>
      <Button
        className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
        color="primary"
        onClick={handleClickOpen}
      >
        {t('Page.Team.SetCoachPrivileges.AddCoach')}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Page.Team.SetCoachPrivileges.AddCoach')}
        </DialogTitle>
        {membersState.isLoading && <CircularProgress />}
        {membersState.data && (
          <Formik
            initialValues={{
              member: '',
              teamId: teamId,
            }}
            validationSchema={schema}
            onSubmit={onSubmitMemoized}
          >
            {({ setFieldValue, values }) => (
              <Form className={classes.form}>
                <DialogContent>
                  <ErrorBox
                    errorList={[
                      { id: 1, error: storeMemberState.error },
                      { id: 2, error: membersState.error },
                    ]}
                  />
                  <DialogContentText>
                    {t('Page.Team.SetCoachPrivileges.SelectMemberDescription')}
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <AutoSuggest
                        options={membersState.data}
                        style={{ width: 300 }}
                        required
                        fullWidth
                        id="member"
                        name="member"
                        placeholder={t(
                          'Page.Team.SetCoachPrivileges.SelectCoachPlaceholder',
                        )}
                        label={t('Page.Team.SetCoachPrivileges.MemberList')}
                        value={values.member}
                        onChange={(event, value) =>
                          setFieldValue('member', value)
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    {t('Page.Team.AddMemberToTeam.Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={storeMemberState.isLoading}
                    color="primary"
                    autoFocus
                  >
                    {t('Page.Team.SetCoachPrivileges.GrantPrivileges')}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
};
