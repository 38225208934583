import React, { useState, useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { ErrorBox } from '../../atoms';
import { SicknessLogTable } from '../../organisms';
import { useFetchRequest, useRequest } from '../../utils';

import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
}));

export const SicknessLog = ({ t }) => {
  const deleteSicknessState = useRequest();
  const [sicknessLogState, setSicknessLogState] = useState(undefined);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessFetchSicknessLogState = (response) => {
    updateSicknessLogState(response);
  };

  const fetchSicknessLogState = useFetchRequest(
    ENDPOINTS.getAthleteSicknessLog(),
    {
      onSuccess: onSuccessFetchSicknessLogState,
    },
  );

  const updateSicknessLogState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setSicknessLogState({
        data,
      });
    },
    [],
  );

  const handleRowDelete = (oldData, resolve) => {
    console.log(oldData);
    deleteSicknessState.request(ENDPOINTS.deleteSickness(), {
      method: 'DELETE',
      onSuccess: () => {
        enqueueSnackbar(t('Page.Sickness.SicknessLog.SicknessDeleted'), {
          variant: 'success',
        });
        setSicknessLogState({
          data: sicknessLogState.data.filter(
            (item) => item.sickness_id !== oldData.sickness_id,
          ),
        });
        resolve();
      },
      data: {
        sicknessId: oldData.sickness_id,
      },
    });
  };

  const errorList = [
    {
      id: 1,
      error: fetchSicknessLogState.error,
    },
  ];
  return (
    <Paper className="mb-20">
      <ErrorBox errorList={errorList} />
      {fetchSicknessLogState.isLoading && (
        <CircularProgress className={classes.progress} />
      )}
      {typeof sicknessLogState !== 'undefined' && sicknessLogState.data && (
        <SicknessLogTable
          t={t}
          data={sicknessLogState.data}
          handleRowDelete={handleRowDelete}
        />
      )}
    </Paper>
  );
};
