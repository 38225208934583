import React, { useState } from 'react';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserInjured } from '@fortawesome/free-solid-svg-icons';
import { faHeadSideCough } from '@fortawesome/free-solid-svg-icons';

import { TableIcons } from '../atoms';
import { formatDate } from '../utils';

import PATHNAMES from '../pathnames';

export const SicknessLogTable = ({ t, data, handleRowDelete }) => {
  const [filtering, setFiltering] = useState(false);

  const columns = [
    { title: 'ID', field: 'sickness_id', hidden: true },
    {
      title: t('Organisms.SicknessLogTable.DateFrom'),
      field: 'date_from',
      type: 'date',
      render: (rowData) => formatDate(rowData.date_from),
      width: 50,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('Organisms.SicknessLogTable.DateTo'),
      field: 'date_to',
      type: 'date',
      render: (rowData) => formatDate(rowData.date_to),
      width: 50,
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('Organisms.SicknessLogTable.Problem'),
      field: 'problem',
      type: 'string',
      width: 300,
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      customSort: (a, b) =>
        a.problem.localeCompare(b.problem, 'cz', {
          sensitivity: 'variant',
        }),
      render: (rowData) => (
        <Link href={PATHNAMES.getSicknessDetail(rowData.sickness_id)}>
          {rowData.problem}
        </Link>
      ),
    },
    {
      title: 'Typ',
      field: 'type',
      type: 'string',
      render: (rowData) =>
        rowData.sickness_type.kind === 'disease' ? (
          <FontAwesomeIcon icon={faHeadSideCough} />
        ) : rowData.sickness_type.kind === 'injury' ? (
          <FontAwesomeIcon icon={faUserInjured} />
        ) : (
          rowData.sickness_type.kind
        ),
      width: 50,
      filtering: false,
    },
    {
      title: t('Organisms.SicknessLogTable.Type'),
      field: 'sport_type.name',
      type: 'string',
      render: (rowData) =>
        rowData.type_id ? (
          <Chip
            size="small"
            label={rowData.sickness_type.name}
            color="primary"
          />
        ) : (
          <Chip
            size="small"
            label={t('Page.TrainingLog.TrainingLogPage.Uncategorized')}
            color="secondary"
          />
        ),
      width: 150,
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];

  return (
    <MaterialTable
      title={t('Organisms.SicknessLogTable.MySicknesses')}
      columns={columns}
      data={data}
      icons={TableIcons}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve);
          }),
      }}
      options={{
        actionsColumnIndex: -1,
        tableLayout: 'fixed',
        pageSize: 20,
        pageSizeOptions: [5, 10, 20, 50, 100],
        filtering: filtering,
        rowStyle: (x) => {
          if (x.tableData.id % 2) {
            return {
              backgroundColor: '#f2f2f2',
            };
          }
        },
      }}
      actions={[
        {
          icon: () => <FilterList />,
          tooltip: t('DataTable.EnableFilter'),
          isFreeAction: true,
          onClick: () => setFiltering(!filtering),
        },
      ]}
      localization={{
        header: {
          actions: t('DataTable.Actions'),
        },
        body: {
          deleteTooltip: t('DataTable.Delete'),
          editTooltip: t('DataTable.Edit'),
          editRow: {
            deleteText: t('DataTable.DeleteText'),
            cancelTooltip: t('DataTable.CancelTooltip'),
            saveTooltip: t('DataTable.SaveTooltip'),
          },
          filterRow: {
            filterTooltip: t('DataTable.Filter'),
          },
        },
        toolbar: {
          searchTooltip: t('DataTable.SearchTooltip'),
          searchPlaceholder: t('DataTable.SearchPlaceholder'),
          addRemoveColumns: t('DataTable.AddRemoveColumns'),
          showColumnsTitle: t('DataTable.ShowColumnsTitle'),
          showColumnsAriaLabel: t('DataTable.ShowColumnsTitle'),
          nRowsSelected: t('DataTable.NRowsSelected'),
        },
      }}
    />
  );
};
