import React from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { merge } from 'lodash';

import { EchartTheme } from '../theme/EchartTheme';
import theme from '../theme';

const defaultOption = {
  grid: {
    top: 16,
    left: 48,
    right: 62,
    bottom: 32,
  },
  tooltip: {
    show: true,
    trigger: 'axis',

    axisPointer: {
      type: 'cross',
      lineStyle: {
        opacity: 0,
      },
    },
    crossStyle: {
      color: '#000',
    },
  },
  series: [
    {
      areaStyle: {},
      smooth: true,
      lineStyle: {
        width: 2,
      },
    },
  ],
  xAxis: {
    show: true,
    type: 'category',
    showGrid: false,
    boundaryGap: false,
    axisLabel: {
      color: '#ccc',
      margin: 20,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    min: 0,
    max: 300,
    axisLabel: {
      color: '#ccc',
      margin: 10,
      fontSize: 13,
      fontFamily: 'roboto',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, .1)',
      },
    },

    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
};

export const ModifiedAreaChart = ({ height, option }) => {
  echarts.registerTheme('echarts-theme', EchartTheme(theme));

  return (
    <ReactEcharts
      style={{ height: height }}
      option={merge({}, defaultOption, option)}
    />
  );
};
