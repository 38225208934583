import React, { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Typography,
  Card,
  Box,
  Slider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import WorkIcon from '@material-ui/icons/Work';
import TimerIcon from '@material-ui/icons/Timer';
import TimelineIcon from '@material-ui/icons/Timeline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SpeedIcon from '@material-ui/icons/Speed';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import CloudIcon from '@material-ui/icons/Cloud';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { deepOrange } from '@material-ui/core/colors';
import { Formik, Form } from 'formik';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';

import {
  Map,
  MarkerLayer,
  Marker,
  MouseControl,
  KeyboardControl,
  ZoomControl,
  PathLayer,
  Path,
} from 'react-mapycz';

import { SimpleCard } from '../../../atoms';
import { SimpleAlertDialog } from '../../../molecules';
import {
  DoughnutChart,
  ModifiedAreaChart,
  Field,
  EditActivityForm,
  ActivityForm,
  ProportionSubActivityChart,
} from '../../../organisms';
import {
  useFetchRequest,
  formatDateTime,
  convertToKM,
  convertMinutes,
  convertSecondsToMMSSS,
  formatSecondsToTime,
  useRequest,
  translatedValidations,
  useAuth,
} from '../../../utils';
import { LoggedInPageLayout } from '../../../templates';
import { SubActivityList } from './SubActivityList';

import ENDPOINTS from '../../../endpoints';
import PATHNAMES from '../../../pathnames';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: '600px',
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: theme.spacing(1),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  lapsContainer: {
    maxHeight: '400px',
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
}));

function IconContainer(props) {
  const styles = props.className.split(' ');
  const { value, ...other } = props;
  return (
    <span {...other} className="mx-4">
      <img
        src={satisfactionIcons[value].icon}
        alt={satisfactionIcons[value].label}
        title={satisfactionIcons[value].label}
        width="50px"
        className={
          styles.includes('MuiRating-iconEmpty') ? 'satisfaction-empty' : 'none'
        }
      />
    </span>
  );
}

const satisfactionIcons = {
  0: {
    icon: '/assets/images/illustrations/angry.svg',
    label: 'Very Dissatisfied',
  },
  1: {
    icon: '/assets/images/illustrations/angry.svg',
    label: 'Very Dissatisfied',
  },
  2: {
    icon: '/assets/images/illustrations/sad.svg',
    label: 'Dissatisfied',
  },
  3: {
    icon: '/assets/images/illustrations/neutral.svg',
    label: 'Neutral',
  },
  4: {
    icon: '/assets/images/illustrations/happy.svg',
    label: 'Satisfied',
  },
  5: {
    icon: '/assets/images/illustrations/very-happy.svg',
    label: 'Very Satisfied',
  },
};

export const ActivityDetailPage = () => {
  const { t } = useTranslation();
  const { activityId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const user = useAuth();

  const [commentsStateData, setCommentsStateData] = useState(undefined);
  const [activityStateData, setActivityStateData] = useState(undefined);

  const onSuccessFetchCommentsState = (response) => {
    updateCommentsState(response);
  };

  const onSuccessFetchActivityState = (response) => {
    updateActivityState(response);
  };

  const fetchActivityState = useFetchRequest(
    ENDPOINTS.getActivityDetail(activityId),
    {
      onSuccess: onSuccessFetchActivityState,
    },
  );

  const activityStreamState = useFetchRequest(
    ENDPOINTS.getActivityStream(activityId),
  );

  const fetchCommentState = useFetchRequest(
    ENDPOINTS.getActivityComments(activityId),
    {
      onSuccess: onSuccessFetchCommentsState,
    },
  );

  const updateCommentsState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setCommentsStateData({
        data,
      });
    },
    [],
  );

  const updateActivityState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setActivityStateData({
        data,
      });
    },
    [],
  );

  const errorList = [
    {
      id: 1,
      error: fetchActivityState.error,
    },
    {
      id: 2,
      error: activityStreamState.error,
    },
    {
      id: 3,
      error: fetchCommentState.error,
    },
  ];

  const addCommentState = useRequest();
  const deleteActivityState = useRequest();
  const { enqueueSnackbar } = useSnackbar();
  const { object, requiredString } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ comment }, { resetForm }) => {
      addCommentState.request(ENDPOINTS.addActivityComment(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: {
              data: { comment_id, message, user },
            },
          },
        }) => {
          resetForm();
          setCommentsStateData((prevState) => ({
            data: [
              ...prevState.data,
              {
                commentId: comment_id,
                message: message,
                user: user,
              },
            ],
          }));
        },
        data: {
          comment,
          activityId: activityStateData.data.activityId,
        },
      });
    },
    [addCommentState, activityStateData, setCommentsStateData],
  );

  const schema = object({
    comment: requiredString,
  });

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          {typeof activityStateData !== 'undefined' &&
            activityStateData.data &&
            (user.user.userId === activityStateData.data.userId ? (
              <Button
                onClick={() =>
                  history.push(PATHNAMES.getTrainingLog('training'))
                }
              >
                <ArrowBackIosIcon fontSize="small" />
                {t('Page.TrainingLog.ActivityDetailPage.AllActivities')}
              </Button>
            ) : (
              <Button onClick={() => history.goBack()}>
                <ArrowBackIosIcon fontSize="small" />
                {t('Page.TrainingLog.ActivityDetailPage.AllActivities')}
              </Button>
            ))}
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.TrainingLog.ActivityDetailPage.ActivityDetailTitle')}
            </Typography>
          </div>
          {typeof activityStateData !== 'undefined' &&
            activityStateData.data &&
            user.user.userId === activityStateData.data.userId &&
            (activityStateData.data.status < 9 ||
              activityStateData.data.status === 10) && (
              <div className="flex flex-space-between">
                <EditActivityForm
                  activityStateData={activityStateData}
                  setActivityStateData={setActivityStateData}
                />
                <SimpleAlertDialog
                  color="secondary"
                  title={t('Organisms.ActivityForm.DeleteActivityConfirm')}
                  message={
                    t('Organisms.ActivityForm.DeleteActivityMessage') +
                    ' ' +
                    activityStateData.data.name
                  }
                  handleConfirm={() =>
                    deleteActivityState.request(ENDPOINTS.deleteActivity(), {
                      method: 'DELETE',
                      onSuccess: () => {
                        history.push(PATHNAMES.getTrainingLog('training'));
                        enqueueSnackbar(
                          t('Organisms.ActivityForm.DeletedActivityAlert'),
                          {
                            variant: 'success',
                          },
                        );
                      },
                      onError: (err) => {
                        enqueueSnackbar('Uh Oh, ' + err, {
                          variant: 'error',
                        });
                      },
                      data: {
                        activityId: activityId,
                      },
                    })
                  }
                >
                  {t('Page.Sickness.SicknessDetailPage.Delete')}
                </SimpleAlertDialog>
              </div>
            )}
          {typeof activityStateData !== 'undefined' &&
            activityStateData.data &&
            user.user.userId === activityStateData.data.userId &&
            activityStateData.data.status >= 100 && (
              <ActivityForm
                removeActivity={() => null}
                {...activityStateData.data}
              />
            )}
          <div className="pt-20">
            {fetchActivityState.isLoading && (
              <CircularProgress className={classes.progress} />
            )}
            {typeof activityStateData !== 'undefined' &&
              activityStateData.data && (
                <>
                  {activityStateData.data.flagged === true && (
                    <div className={classes.alert}>
                      <Alert variant="outlined" severity="warning">
                        The activity has been flagged.
                      </Alert>
                    </div>
                  )}
                  {activityStateData.data.hasHeartRate === false && (
                    <div className={classes.alert}>
                      <Alert
                        variant="outlined"
                        severity="info"
                        className="bg-white"
                      >
                        {t(
                          'Page.TrainingLog.ActivityDetailPage.NoHeartRateMonitor',
                        )}
                      </Alert>
                    </div>
                  )}
                  <Grid container spacing={2} className="flex-stretch">
                    <Grid item xs={12}>
                      <Card className="px-24 py-20 h-100">
                        <div className="card-title">
                          {activityStateData.data.name}
                        </div>
                        <div className="card-subtitle mb-8">
                          {formatDateTime(activityStateData.data.startDate)}
                        </div>
                        {activityStateData.data.SubActivities.length > 0 ? (
                          <ProportionSubActivityChart
                            width={310}
                            data={activityStateData.data.SubActivities}
                            totalTime={activityStateData.data.time}
                          />
                        ) : (
                          <Chip
                            className="mr-4"
                            size="small"
                            label={t(
                              'Page.TrainingLog.ActivityDetailPage.WithoutCategory',
                            )}
                            color="secondary"
                          />
                        )}
                        {activityStateData.data.race === true && (
                          <Chip
                            className="mr-4"
                            size="small"
                            label={t('Organisms.ActivityForm.Race')}
                          />
                        )}
                        <div className="my-8">
                          {activityStateData.data.source === 'STRAVA' ? (
                            <Chip
                              size="small"
                              label={activityStateData.data.source}
                              color="secondary"
                              variant="outlined"
                              href={
                                'https://www.strava.com/activities/' +
                                activityStateData.data.externalId
                              }
                              component="a"
                              clickable
                              target="_blank"
                            />
                          ) : (
                            <Chip
                              size="small"
                              label={activityStateData.data.source}
                              color="secondary"
                              variant="outlined"
                            />
                          )}
                        </div>
                        {activityStateData.data.doma && (
                          <Button
                            className="mr-4"
                            variant="contained"
                            color="primary"
                            size="small"
                            href={activityStateData.data.doma}
                            target="_blank"
                          >
                            {t('Page.TrainingLog.ActivityDetailPage.Map')}
                          </Button>
                        )}
                        {activityStateData.data.tracking && (
                          <Button
                            className="mr-4"
                            variant="contained"
                            color="primary"
                            size="small"
                            href={activityStateData.data.tracking}
                            target="_blank"
                          >
                            {t('Page.TrainingLog.ActivityDetailPage.GPS')}
                          </Button>
                        )}
                        {activityStateData.data.result && (
                          <Button
                            className="mr-4"
                            variant="contained"
                            color="primary"
                            size="small"
                            href={activityStateData.data.result}
                            target="_blank"
                          >
                            {t('Page.TrainingLog.ActivityDetailPage.Results')}
                          </Button>
                        )}
                        <Grid container spacing={2} className="flex-stretch">
                          <Grid item xs={12} sm={12} md={5} lg={6}>
                            <List className={classes.list}>
                              <Grid container>
                                <Grid item sm={6} xs={12}>
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <TimelineIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.distance
                                          ? convertToKM(
                                              activityStateData.data.distance,
                                            ) + ' km'
                                          : activityStateData.data
                                              .movingDistance &&
                                            convertToKM(
                                              activityStateData.data
                                                .movingDistance,
                                            ) + ' km'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.Distance',
                                      )}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <TimerIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ActivityTimeText
                                      activityStateData={activityStateData}
                                      t={t}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <SpeedIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.pace &&
                                        convertSecondsToMMSSS(
                                          activityStateData.data.pace,
                                        ) + ' min/km'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.Pace',
                                      )}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <FilterHdrIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.elevationGain &&
                                        activityStateData.data.elevationGain +
                                          ' m'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.Elevation',
                                      )}
                                    />
                                  </ListItem>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <FavoriteIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.avgHr &&
                                        Math.round(
                                          activityStateData.data.avgHr,
                                        ) + ' bpm'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.AverageHr',
                                      )}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <FitnessCenterIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.maxHr &&
                                        activityStateData.data.maxHr + ' bpm'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.MaxHr',
                                      )}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <WorkIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.calories &&
                                        activityStateData.data.calories +
                                          ' kCal'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.Calories',
                                      )}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <LineStyleIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.avgCadence &&
                                        (activityStateData.data.sport === 'run'
                                          ? Math.round(
                                              activityStateData.data
                                                .avgCadence * 2,
                                            ) + ' spm'
                                          : activityStateData.data.avgCadence +
                                            ' rpm')
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.AverageCadence',
                                      )}
                                    />
                                  </ListItem>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Divider variant="inset" component="li" />
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar>
                                        {activityStateData.data.weather ? (
                                          <Weather
                                            weather={
                                              activityStateData.data.weather
                                            }
                                          />
                                        ) : (
                                          <WbSunnyIcon />
                                        )}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        activityStateData.data.temperature &&
                                        activityStateData.data.temperature +
                                          ' °C'
                                      }
                                      secondary={t(
                                        'Page.TrainingLog.ActivityDetailPage.Temperature',
                                      )}
                                    />
                                  </ListItem>
                                </Grid>
                              </Grid>
                            </List>
                            {activityStateData.data.feeling > 0 && (
                              <Box
                                component="fieldset"
                                borderColor="transparent"
                              >
                                <Typography component="legend">
                                  {t('Organisms.ActivityForm.Feeling')}
                                </Typography>
                                <Rating
                                  name="feeling"
                                  readOnly
                                  value={activityStateData.data.feeling}
                                  getLabelText={(value) =>
                                    satisfactionIcons[value].label
                                  }
                                  IconContainerComponent={IconContainer}
                                />
                              </Box>
                            )}
                            {activityStateData.data.exertion && (
                              <Box
                                component="fieldset"
                                borderColor="transparent"
                              >
                                <Typography component="legend">
                                  {t('Organisms.ActivityForm.Exertion')}
                                </Typography>
                                <Box maxWidth="290px">
                                  <Slider
                                    name="exertion"
                                    disabled
                                    defaultValue={
                                      activityStateData.data.exertion &&
                                      activityStateData.data.exertion
                                    }
                                    aria-labelledby="disabled-slider"
                                  />
                                </Box>
                              </Box>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={12} md={7} lg={6}>
                            <Box title="Popis" className="px-24 py-20">
                              <div className="card-title">
                                {t(
                                  'Page.TrainingLog.ActivityDetailPage.Description',
                                )}
                              </div>
                              {activityStateData.data.description}
                            </Box>
                            <Box title="Popis" className="px-8 py-20">
                              <SubActivityList
                                subActivityList={
                                  activityStateData.data.SubActivities
                                }
                                totalTime={activityStateData.data.time}
                              />
                            </Box>
                          </Grid>
                          {activityStateData.data.resultList && (
                            <Grid item xs={12}>
                              <TableContainer className={classes.lapsContainer}>
                                <Table
                                  stickyHeader
                                  className={classes.table}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.Competition',
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.Discipline',
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.Place',
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.Time',
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.Loss',
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.Winner',
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          'Page.TrainingLog.ActivityDetailPage.WinningTime',
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      key={
                                        activityStateData.data.resultList
                                          .result_id
                                      }
                                    >
                                      <TableCell component="th" scope="row">
                                        <Link
                                          href={
                                            activityStateData.data.resultList
                                              .url
                                          }
                                        >
                                          {
                                            activityStateData.data.resultList
                                              .competition
                                          }
                                        </Link>
                                      </TableCell>
                                      <TableCell>
                                        {
                                          activityStateData.data.resultList
                                            .discipline
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {activityStateData.data.resultList
                                          .place + '.'}
                                      </TableCell>
                                      <TableCell>
                                        {formatSecondsToTime(
                                          activityStateData.data.resultList
                                            .time,
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {'+ ' +
                                          formatSecondsToTime(
                                            activityStateData.data.resultList
                                              .loss,
                                          )}
                                      </TableCell>
                                      <TableCell>
                                        {
                                          activityStateData.data.resultList
                                            .winner
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {formatSecondsToTime(
                                          activityStateData.data.resultList
                                            .winning_time,
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )}
                        </Grid>
                      </Card>
                    </Grid>
                    {activityStreamState.data &&
                      activityStateData.data.startLatitude && (
                        <Grid item xs={12} lg={6}>
                          <Card>
                            <Map
                              height="400px"
                              center={{
                                lat: activityStateData.data.startLatitude,
                                lng: activityStateData.data.startLongitude,
                              }}
                            >
                              <KeyboardControl />
                              <ZoomControl />
                              <MouseControl
                                zoom={true}
                                pan={true}
                                wheel={false}
                              />
                              <MarkerLayer>
                                <Marker
                                  coords={{
                                    lat: activityStateData.data.startLatitude,
                                    lng: activityStateData.data.startLongitude,
                                  }}
                                />
                              </MarkerLayer>
                              <PathLayer>
                                <Path coords={activityStreamState.data.path} />
                              </PathLayer>
                            </Map>
                          </Card>
                        </Grid>
                      )}
                    {activityStateData.data.laps &&
                      activityStateData.data.laps.length > 1 && (
                        <Grid item xs={12} lg={6}>
                          <Card>
                            <TableContainer className={classes.lapsContainer}>
                              <Table
                                stickyHeader
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      {t(
                                        'Page.TrainingLog.ActivityDetailPage.Lap',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t(
                                        'Page.TrainingLog.ActivityDetailPage.Distance',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t(
                                        'Page.TrainingLog.ActivityDetailPage.Time',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t(
                                        'Page.TrainingLog.ActivityDetailPage.Pace',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t(
                                        'Page.TrainingLog.ActivityDetailPage.Elevation',
                                      )}
                                    </TableCell>
                                    <TableCell align="center">HR</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {activityStateData.data.laps.map((row) => (
                                    <TableRow key={row.lap_id}>
                                      <TableCell component="th" scope="row">
                                        {row.name}
                                      </TableCell>
                                      <TableCell align="right">
                                        {convertToKM(row.distance)}{' '}
                                        <small>km</small>
                                      </TableCell>
                                      <TableCell align="right">
                                        {formatSecondsToTime(row.moving_time)}
                                      </TableCell>
                                      <TableCell align="right">
                                        {convertMinutes(row.pace)}{' '}
                                        <small>/km</small>
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.elevation_gain} <small>m</small>
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.heartrate} <small>bpm</small>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Card>
                        </Grid>
                      )}
                    {activityStreamState.data &&
                      activityStreamState.data.path.length > 2 && (
                        <Grid item xs={12}>
                          <Card>
                            <div className="pb-86 pt-30 px-30 bg-primary">
                              <ModifiedAreaChart
                                height="280px"
                                option={{
                                  legend: {
                                    data: [
                                      t(
                                        'Page.TrainingLog.ActivityDetailPage.HeartRate',
                                      ),
                                      t(
                                        'Page.TrainingLog.ActivityDetailPage.Elevation',
                                      ),
                                    ],
                                    textStyle: { color: '#fff' },
                                    padding: [0, 0, 0, 32],
                                  },
                                  series: [
                                    {
                                      name: t(
                                        'Page.TrainingLog.ActivityDetailPage.HeartRate',
                                      ),
                                      data: activityStreamState.data.heartRate,
                                      type: 'line',
                                      yAxisIndex: 1,
                                      areaStyle: {},
                                      lineStyle: {
                                        color: '#e71d36',
                                      },
                                    },
                                    {
                                      name: t(
                                        'Page.TrainingLog.ActivityDetailPage.Elevation',
                                      ),
                                      data: activityStreamState.data.altitude,
                                      type: 'line',
                                      areaStyle: {},
                                    },
                                  ],
                                  xAxis: [
                                    {
                                      name: 'Distance',
                                      data: activityStreamState.data.distance,
                                      type: 'category',
                                      axisLabel: {
                                        formatter: '{value} km',
                                        textStyle: { color: '#fff' },
                                      },
                                    },
                                  ],
                                  yAxis: [
                                    {
                                      type: 'value',
                                      name: 'Elevation',
                                      min: Math.round(
                                        Math.min(
                                          ...activityStreamState.data.altitude,
                                        ),
                                      ),
                                      max: Math.round(
                                        Math.max(
                                          ...activityStreamState.data.altitude,
                                        ),
                                      ),
                                      axisLabel: {
                                        formatter: '{value} m',
                                        textStyle: { color: '#fff' },
                                      },
                                    },
                                    {
                                      type: 'value',
                                      name: 'HR',
                                      min: 50,
                                      max: 250,
                                      interval: 50,
                                      showGrid: false,
                                      axisLine: {
                                        show: 'false',
                                      },
                                      splitLine: {
                                        show: false,
                                      },
                                      axisLabel: {
                                        formatter: '{value} bpm',
                                        textStyle: { color: '#fff' },
                                      },
                                    },
                                  ],
                                }}
                              ></ModifiedAreaChart>
                            </div>
                          </Card>
                        </Grid>
                      )}
                    <Grid item xs={12} lg={6}>
                      <SimpleCard
                        title={t(
                          'Page.TrainingLog.ActivityDetailPage.Comments',
                        )}
                        actionButton={false}
                      >
                        {typeof commentsStateData !== 'undefined' &&
                          commentsStateData.data && (
                            <>
                              {commentsStateData.data.map((comment) => (
                                <div className="flex mb-8">
                                  <Avatar className={classes.orange}>M</Avatar>
                                  <div className="relative top-20">
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      {comment.user.firstname}
                                    </Typography>
                                    <div className="bg-primary border-radius-8 text-left text-white p-24 w-100">
                                      {comment.message}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                        <Divider />
                        <Formik
                          initialValues={{
                            comment: '',
                          }}
                          validationSchema={schema}
                          onSubmit={onSubmitMemoized}
                        >
                          {() => (
                            <Form className={classes.form}>
                              <Grid container spacing={1}>
                                <Grid item xs={11}>
                                  <Field
                                    variant="outlined"
                                    required
                                    fullWidth
                                    multiline
                                    type="text"
                                    id="comment"
                                    label="Comment"
                                    placeholder="Write a text"
                                    name="comment"
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    type="submit"
                                    color="primary"
                                    aria-label="add comment"
                                    disabled={addCommentState.isLoading}
                                  >
                                    <SendIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </SimpleCard>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SimpleCard className="px-24 py-16 mb-16">
                        <div className="card-title">
                          {t('Page.Dashboard.TimeInZones')}
                        </div>
                        <div className="card-subtitle">dle srdeční zátěže</div>
                        {activityStateData.data && (
                          <DoughnutChart
                            data={[
                              {
                                value: activityStateData.data.timeZone1,
                                name: t('Organisms.WeekSummaryTable.Zone1'),
                              },
                              {
                                value: activityStateData.data.timeZone2,
                                name: t('Organisms.WeekSummaryTable.Zone2'),
                              },
                              {
                                value: activityStateData.data.timeZone3,
                                name: t('Organisms.WeekSummaryTable.Zone3'),
                              },
                              {
                                value: activityStateData.data.timeZone4,
                                name: t('Organisms.WeekSummaryTable.Zone4'),
                              },
                              {
                                value: activityStateData.data.timeZone5,
                                name: t('Organisms.WeekSummaryTable.Zone5'),
                              },
                            ]}
                            height="300px"
                            color={[
                              theme.palette.primary.light,
                              theme.palette.primary.main,
                              theme.palette.primary.dark,
                              theme.palette.secondary.dark,
                              theme.palette.secondary.main,
                            ]}
                          />
                        )}
                      </SimpleCard>
                    </Grid>
                  </Grid>
                </>
              )}
          </div>
        </div>
      </div>
    </LoggedInPageLayout>
  );
};

const ActivityTimeText = ({ activityStateData, t }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ListItemText
        className="cursor-pointer"
        primary={
          activityStateData.data.time
            ? formatSecondsToTime(activityStateData.data.time)
            : formatSecondsToTime(activityStateData.data.movingTime)
        }
        secondary={t('Page.TrainingLog.ActivityDetailPage.Time')}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className="p-8">
          {activityStateData.data.time && (
            <Typography variant="body2">
              {t('Page.TrainingLog.ActivityDetailPage.TrUnitTime')}:{' '}
              <strong>
                {formatSecondsToTime(activityStateData.data.time)}
              </strong>
            </Typography>
          )}
          {activityStateData.data.movingTime && (
            <Typography variant="body2">
              {t('Page.TrainingLog.ActivityDetailPage.MovingTime')}:{' '}
              <strong>
                {formatSecondsToTime(activityStateData.data.movingTime)}
              </strong>
            </Typography>
          )}
          {activityStateData.data.elapsedTime && (
            <Typography variant="body2">
              {t('Page.TrainingLog.ActivityDetailPage.ElapsedTime')}:{' '}
              <strong>
                {formatSecondsToTime(activityStateData.data.elapsedTime)}
              </strong>
            </Typography>
          )}
        </Box>
      </Popover>
    </>
  );
};
const Weather = ({ weather }) => {
  if (weather === 'clear') return <WbSunnyIcon />;
  if (weather === 'few_clouds') return <Brightness6Icon />;
  if (
    weather === 'clouds' ||
    weather === 'drizzle' ||
    weather === 'rain' ||
    weather === 'thunderstorm' ||
    weather === 'mist'
  )
    return <CloudIcon />;
  if (weather === 'snow') return <AcUnitIcon />;
  return <WbSunnyIcon />;
};
