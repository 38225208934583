import React from 'react';
import Box from '@material-ui/core/Box';
import { ProportionStackedBar } from '../molecules';
import { formatSecondsToTime } from '../utils';

export const ProportionSubActivityChart = ({ width, data = [], totalTime }) => {
  const barChartData = data
    .sort((a, b) => a.time_offset - b.time_offset)
    .map(({ sub_id: id, sport_type: sportType, time }) => ({
      id,
      text: sportType.name + ' ' + formatSecondsToTime(time),
      ratio: (time * 100) / totalTime,
      color: sportType.color,
    }));
  return (
    <Box className="my-8">
      <ProportionStackedBar width={width} data={barChartData} />
    </Box>
  );
};
