import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useFetchData } from './hooks';
import { AthleteBaseInfo } from './AthleteBaseInfo';
import { AthleteTrainingLog } from './AthleteTrainingLog';
import { AthleteCalendar } from './AthleteCalendar';
import { TabPanel, a11yProps } from '../../../molecules';
import {
  CycleSummaryTable,
  WeekSummaryTable,
  DoughnutChart,
  CycleBarChart,
} from '../../../organisms';
import { LoggedInPageLayout } from '../../../templates';
import theme from 'theme';
import PATHNAMES from '../../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  tab: {
    '& .MuiBox-root': {
      padding: '0px',
    },
  },
}));

export const TeamMemberDetailPage = () => {
  const tabNameToIndex = {
    0: 'stats',
    1: 'activities',
    2: 'calendar',
  };

  const indexToTabName = {
    stats: 0,
    activities: 1,
    calendar: 2,
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const { teamId, userId, view } = useParams();
  const history = useHistory();

  const [selectedTab, seSelectedTab] = useState(indexToTabName[view]);

  const handleChange = (event, newValue) => {
    history.push(
      PATHNAMES.getTeamMemberDetail(teamId, userId, tabNameToIndex[newValue]),
    );
    seSelectedTab(newValue);
  };

  const { athleteState, enumSportState } = useFetchData(teamId, userId);

  const errorList = [
    { id: 1, error: athleteState.error },
    { id: 2, error: enumSportState.error },
  ];

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <Button onClick={() => history.push(PATHNAMES.getTeamDetail(teamId))}>
            <ArrowBackIosIcon fontSize="small" />
            {t('Page.Team.TeamMemberDetail.Back')}
          </Button>
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Team.TeamMemberDetail.TeamMemberDetailTitle')}
            </Typography>
          </div>
          {athleteState.isLoading && <CircularProgress />}
          {athleteState.data && enumSportState.data && (
            <>
              <AthleteBaseInfo
                t={t}
                athleteInfo={athleteState.data.athleteInfo}
                athleteSummary={athleteState.data.athleteSummary}
              />
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="simple tabs team"
                className="my-8"
              >
                <Tab
                  label={t('Page.Team.TeamMemberDetail.Statistics')}
                  {...a11yProps(0)}
                />
                <Tab
                  label={t('Page.Team.TeamMemberDetail.Activities')}
                  {...a11yProps(1)}
                />
                <Tab
                  label={t('Molecules.VerticalNav.Calendar')}
                  {...a11yProps(2)}
                />
              </Tabs>
              <TabPanel
                value={selectedTab}
                index={0}
                classes={{ root: classes.tab }}
              >
                <Grid container spacing={2} className="flex-stretch">
                  <Grid item xs={12}>
                    <WeekSummaryTable
                      t={t}
                      sumByWeek={athleteState.data.sumByWeek}
                      currentWeek={athleteState.data.currentWeek}
                    ></WeekSummaryTable>
                  </Grid>
                  <Grid item lg={4} md={3} sm={12} xs={12}>
                    <Card className="px-24 py-16 h-100">
                      <div className="card-title">
                        {t('Page.Team.TeamMemberDetail.TimeInZones')}
                      </div>
                      <div className="card-subtitle">
                        {t('Organisms.WeekSummaryTable.CurrentWeek')}
                      </div>
                      {athleteState.data.timeInZones.zone1 ||
                      athleteState.data.timeInZones.zone2 ||
                      athleteState.data.timeInZones.zone3 ||
                      athleteState.data.timeInZones.zone4 ||
                      athleteState.data.timeInZones.zone5 ? (
                        <DoughnutChart
                          data={[
                            {
                              value: athleteState.data.timeInZones.zone1,
                              name: t('Organisms.WeekSummaryTable.Zone1'),
                            },
                            {
                              value: athleteState.data.timeInZones.zone2,
                              name: t('Organisms.WeekSummaryTable.Zone2'),
                            },
                            {
                              value: athleteState.data.timeInZones.zone3,
                              name: t('Organisms.WeekSummaryTable.Zone3'),
                            },
                            {
                              value: athleteState.data.timeInZones.zone4,
                              name: t('Organisms.WeekSummaryTable.Zone4'),
                            },
                            {
                              value: athleteState.data.timeInZones.zone5,
                              name: t('Organisms.WeekSummaryTable.Zone5'),
                            },
                          ]}
                          height="400px"
                          color={[
                            theme.palette.primary.light,
                            theme.palette.primary.main,
                            theme.palette.primary.dark,
                            theme.palette.secondary.dark,
                            theme.palette.secondary.main,
                          ]}
                        />
                      ) : (
                        <div>
                          <div className="p-32 flex flex-center flex-middle h-100">
                            <img
                              src="/assets/images/illustrations/heart-rate.svg"
                              alt="Heart"
                              width="100px"
                            />
                          </div>
                          <Typography className="text-center text-small">
                            {t('Page.Dashboard.NoHRData')}
                          </Typography>
                        </div>
                      )}
                    </Card>
                  </Grid>
                  <Grid item lg={8} md={9} sm={12} xs={12}>
                    <Card className="px-24 py-16">
                      <div className="card-title">
                        {t('Page.Team.TeamMemberDetail.CycleChart')}
                      </div>
                      <div className="card-subtitle">
                        {t('Organisms.CycleSummaryTable.CurrentPeriod')}
                      </div>
                      <CycleBarChart
                        data={athleteState.data.sumByCycle}
                        teamPeriod={athleteState.data.teamInfo}
                        enumSports={enumSportState.data.sports}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <CycleSummaryTable
                      t={t}
                      sumByCycle={athleteState.data.sumByCycle}
                      teamInfo={athleteState.data.teamInfo}
                      enumSport={enumSportState.data.sports}
                      sumByWeeks={athleteState.data.sumByWeeks}
                      cycleOverview={athleteState.data.cycleOverview}
                      weekOverview={athleteState.data.weekOverview}
                      sumRegByCycle={athleteState.data.sumRegByCycle}
                      sumRegByWeek={athleteState.data.sumRegByWeek}
                      sickDaysInCycles={athleteState.data.sickDaysInCycles}
                      memberId={userId}
                    ></CycleSummaryTable>
                  </Grid>
                  {athleteState.data.oProportionCycle.length >= 2 && (
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Card className="px-24 py-16">
                        <div className="card-title">
                          {t('Page.Dashboard.OrienteeringProportion')}
                        </div>
                        <div className="card-subtitle">
                          {t('Page.Dashboard.CurrentCycle')}
                        </div>

                        <DoughnutChart
                          data={[
                            {
                              value: athleteState.data.oProportionCycle[0].time,
                              name: t('Organisms.Metrics.Run'),
                            },
                            {
                              value: athleteState.data.oProportionCycle[1].time,
                              name: t('Organisms.Metrics.oRun'),
                            },
                          ]}
                          height="400px"
                          color={[
                            theme.palette.primary.main,
                            theme.palette.secondary.main,
                          ]}
                        />
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <AthleteTrainingLog
                  teamId={teamId}
                  userId={userId}
                  t={t}
                  history={history}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={2}>
                <AthleteCalendar teamId={teamId} userId={userId} t={t} />
              </TabPanel>
            </>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
