import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../atoms';
import { Field, FeelingField, RadioField, Slider } from './';
import { useRequest, translatedValidations } from '../utils';
import ENDPOINTS from '../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const MultipleActivityForm = ({ activitySelection }) => {
  const [open, setOpen] = useState(false);
  const [enumSportType, setEnumSportType] = useState(undefined);
  const enumSportState = useRequest();
  const updateActivityState = useRequest();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const updateEnumSportState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setEnumSportType({
        data,
      });
    },
    [],
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
    enumSportState.request(ENDPOINTS.enumSportTypes(), {
      method: 'GET',
      onSuccess: (response) => updateEnumSportState(response),
    });
  }, [enumSportState, updateEnumSportState]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    object,
    requiredString,
    string,
    requiredNumber,
    number,
    requiredArrayOfNumbers,
  } = translatedValidations(t);

  const activityList = activitySelection.map(({ activityId }) => activityId);

  const onSubmitMemoized = useCallback(
    ({ activityList, sportType, description, feeling, exertion, type }) => {
      updateActivityState.request(ENDPOINTS.multipleUpdateActivity(), {
        method: 'POST',
        onSuccess: (response) => {
          handleClose();
          console.log(response);
          if (response.status === 202) {
            const errors = response.data.results.data;
            enqueueSnackbar(
              t('Organisms.MultipleActivityForm.WarningAlert', { errors }),
              {
                variant: 'warning',
              },
            );
          } else {
            enqueueSnackbar(t('Organisms.MultipleActivityForm.SuccessAlert'), {
              variant: 'success',
            });
          }
          history.go(0);
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: { activityList, sportType, description, feeling, exertion, type },
      });
    },
    [updateActivityState, handleClose, enqueueSnackbar, t, history],
  );

  const schema = object({
    activityList: requiredArrayOfNumbers,
    sportType: requiredNumber,
    description: string,
    type: requiredString,
    exertion: number,
    feeling: number,
  });

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        style={{ textTransform: 'none' }}
        size="small"
        onClick={handleClickOpen}
        className="ml-4"
      >
        {t('Organisms.MultipleActivityForm.MultipleUpdate')}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Organisms.MultipleActivityForm.MultipleUpdateTitle')}
        </DialogTitle>
        <DialogContent>
          <ErrorBox
            errorList={[
              { id: 1, error: updateActivityState.error },
              { id: 2, error: enumSportState.error },
            ]}
          />
          <DialogContentText>
            {t('Organisms.MultipleActivityForm.MultipleUpdateDescription')}
          </DialogContentText>
          <div className="flex flex-center flex-wrap">
            {activitySelection.map((activity) => {
              return (
                <Chip
                  className="m-4"
                  label={activity.name}
                  key={activity.activity_id}
                  color={activity.type_id !== null ? 'primary' : undefined}
                />
              );
            })}
          </div>
          {typeof enumSportType !== 'undefined' && enumSportType.data && (
            <Formik
              initialValues={{
                activityList: activityList,
                sportType: '',
                type: 'T',
                feeling: 0,
                exertion: 50,
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {({ setFieldValue, values }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        id="sportType"
                        variant="outlined"
                        fullWidth
                        select
                        name="sportType"
                        required
                        data={enumSportType.data.sports}
                        label={t('Organisms.ActivityForm.Sport')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        type="text"
                        id="description"
                        label={t('Organisms.ActivityForm.Description')}
                        placeholder={t(
                          'Organisms.ActivityForm.DescriptionPlaceholder',
                        )}
                        name="description"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FeelingField
                        id="feeling"
                        name="feeling"
                        label={t('Organisms.ActivityForm.Feeling')}
                        defaultValue={2}
                        required
                        value={values.feeling}
                        onChange={(event, value) =>
                          setFieldValue('feeling', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Slider
                        className="ml-20"
                        id="exertion"
                        name="exertion"
                        label={t('Organisms.ActivityForm.Exertion')}
                        defaultValue={50}
                        value={values.exertion}
                        onChange={(event, value) =>
                          setFieldValue('exertion', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <RadioField
                        id="type"
                        name="type"
                        label={t('Organisms.ActivityForm.Type')}
                        color="primary"
                        data={[
                          {
                            value: 'T',
                            label: t('Organisms.ActivityForm.Training'),
                          },
                          {
                            value: 'R',
                            label: t('Organisms.ActivityForm.Race'),
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      {t('Molecules.SimpleAlertDialog.Cancel')}
                    </Button>
                    <Button
                      type="submit"
                      disabled={updateActivityState.isLoading}
                      color="primary"
                      autoFocus
                    >
                      {t('Molecules.SimpleAlertDialog.Confirm')}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
