import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { secondsToHms } from 'utils';

export const BarChart = ({ height, color = [], data = [], xAxisData = [] }) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        animation: true,
      },
      formatter: function (params) {
        var colorSpan = (color) =>
          '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
          color +
          '"></span>';
        let rez = '<p>' + params[0].axisValue + '</p>';
        params.forEach((item) => {
          var xx =
            colorSpan(item.color) +
            ' ' +
            item.seriesName +
            ': ' +
            secondsToHms(item.data) +
            '<br />';
          rez += xx;
        });

        return rez;
      },
    },
    legend: {},
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        dataView: { show: false, readOnly: true, title: 'Zobrazit data' },
        magicType: {
          show: true,
          type: ['line', 'bar', 'stack', 'tiled'],
          title: {
            line: 'Spojnicový graf',
            bar: 'Sloupcový graf',
            stack: 'Kumulovaný graf',
            tiled: 'Tiled graf',
          },
        },
        restore: { show: true, title: 'Obnovit' },
        saveAsImage: { show: true, title: 'Uložit jako obrázek' },
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: function (value) {
            const date = secondsToHms(value, 'hm');
            return date;
          },
        },
      },
    ],
    series: data,
  };

  return (
    <ReactEcharts
      style={{ height: height }}
      option={{
        ...option,
        color: [...color],
      }}
    />
  );
};
