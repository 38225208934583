import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { ErrorBox } from '../../../atoms';
import { TrainingLogTable } from '../../../organisms';
import { useFetchRequest } from '../../../utils';

import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

export const AthleteTrainingLog = ({ teamId, userId, t, history }) => {
  const classes = useStyles();
  const trainingLogState = useFetchRequest(
    ENDPOINTS.getTeamAthleteTrainingLog(teamId, userId),
  );

  const errorList = [
    {
      id: 1,
      error: trainingLogState.error,
    },
  ];
  return (
    <Paper className={classes.paper}>
      <ErrorBox errorList={errorList} />
      {trainingLogState.isLoading && (
        <CircularProgress className={classes.progress} />
      )}
      {trainingLogState.data && (
        <TrainingLogTable
          t={t}
          data={trainingLogState.data}
          history={history}
        />
      )}
    </Paper>
  );
};
