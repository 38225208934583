import React, { useState, useCallback } from 'react';
import { Card, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableFooter from '@material-ui/core/TableFooter';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TextWithTooltip } from '../atoms';
import {
  formatSecondsToTime,
  secondsToHms,
  convertToKM,
  getTotals,
  getTotalsInWeek,
  getTotalsInCycle,
  getTotalsInCycleSum,
  useRequest,
} from '../utils';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import getISOWeeksInYear from 'date-fns/getISOWeeksInYear';
import getWeek from 'date-fns/getWeek';
import ENDPOINTS from '../endpoints';

const CycleTotals = ({
  enumSport,
  sumByCycle,
  cycleOverview,
  sumRegByCycle,
  sickDays,
  t,
}) => {
  const cycleData = Object.values(cycleOverview);
  const cycleDetailData = Object.values(sumByCycle);
  const cycleRegenerationData = Object.values(sumRegByCycle);

  return (
    <TableRow>
      <TableCell component="th" scope="row" colSpan={2}>
        &#8721; celkem
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.DayLoadCount')}
          label="DL"
          arrow="arrow"
        >
          {getTotalsInCycle(cycleData, 'day_load')}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.ActivityCountText')}
          label="TU"
          arrow="arrow"
        >
          {getTotalsInCycle(cycleData, 'activity_count')}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.RaceCountText')}
          label="RC"
          arrow="arrow"
        >
          {getTotalsInCycle(cycleData, 'raceCount')}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        {getTotalsInCycle(sickDays, 'sickdays')}
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.RegenerationTime')}
          label="RE"
          arrow="arrow"
        >
          {secondsToHms(
            getTotalsInCycleSum(cycleRegenerationData, 'regenerationTime'),
            'hm',
          )}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.RunTime')}
          label="RT"
          arrow="arrow"
        >
          {secondsToHms(
            getTotalsInCycleSum(cycleDetailData, 'time', 'run'),
            'hm',
          )}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.RunDistance')}
          label="RD"
          arrow="arrow"
        >
          {roundedToFixed(
            convertToKM(
              getTotalsInCycleSum(cycleDetailData, 'distance', 'run'),
            ),
            1,
          )}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        <TextWithTooltip
          text={t('Organisms.CycleSummaryTable.TimeLoad')}
          label="TL"
          arrow="arrow"
        >
          {secondsToHms(getTotalsInCycleSum(cycleDetailData, 'time'), 'hm')}
        </TextWithTooltip>
      </TableCell>
      <TableCell align="right">
        {secondsToHms(getTotalsInCycleSum(cycleDetailData, 'zone1'), 'hms')}
      </TableCell>
      <TableCell align="right">
        {secondsToHms(getTotalsInCycleSum(cycleDetailData, 'zone2'), 'hms')}
      </TableCell>
      <TableCell align="right">
        {secondsToHms(getTotalsInCycleSum(cycleDetailData, 'zone3'), 'hms')}
      </TableCell>
      <TableCell align="right">
        {secondsToHms(getTotalsInCycleSum(cycleDetailData, 'zone4'), 'hms')}
      </TableCell>
      <TableCell align="right">
        {secondsToHms(getTotalsInCycleSum(cycleDetailData, 'zone5'), 'hms')}
      </TableCell>
      <SumColumnsBySport enumSport={enumSport} data={cycleDetailData} />
    </TableRow>
  );
};

export const CycleSummaryTable = ({
  sumByCycle,
  teamInfo,
  enumSport,
  t,
  sumByWeeks,
  cycleOverview,
  weekOverview,
  sumRegByCycle,
  sumRegByWeek,
  sickDaysInCycles,
  memberId,
}) => {
  const [anchorCycleMenu, setCycleMenu] = useState(null);

  const handleClickCycleMenu = (event) => {
    setCycleMenu(event.currentTarget);
  };
  const handleCloseCycleMenu = () => {
    setCycleMenu(null);
  };
  const exportDataState = useRequest();

  const onSuccess = useCallback(({ data }) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'training-log.csv');
    document.body.appendChild(link);
    link.click();
    handleCloseCycleMenu();
  }, []);

  const handleClickExportData = useCallback(() => {
    exportDataState.request(
      ENDPOINTS.exportTrainingLogPerPeriod(
        memberId,
        teamInfo.periodStart,
        teamInfo.periodEnd,
      ),
      {
        method: 'GET',
        responseType: 'blob',
        onSuccess,
      },
    );
  }, [exportDataState, onSuccess, memberId, teamInfo]);
  return (
    <Card className="px-24 py-16">
      <div className="card-title flex justify-between">
        {t('Organisms.CycleSummaryTable.CycleSummary')}
        <IconButton aria-label="more" onClick={handleClickCycleMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu-cycle-data"
          anchorEl={anchorCycleMenu}
          keepMounted
          open={Boolean(anchorCycleMenu)}
          onClose={handleCloseCycleMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClickExportData}>
            <CloudDownloadIcon />
            &nbsp;{t('Organisms.CycleSummaryTable.ExportTrainingLog')}
          </MenuItem>
        </Menu>
      </div>
      <div className="card-subtitle mt--24 mb-24">
        {t('Organisms.CycleSummaryTable.CurrentPeriod')}
      </div>
      <TableContainer>
        <Table size="small" aria-label="cycle summary">
          <TableHead>
            <TableRow>
              <TableCell className="p-0" />
              <TableCell>{t('Organisms.CycleSummaryTable.Cycle')}</TableCell>
              <TableCell align="right">
                <TextWithTooltip text="Dnů zátěže" label="DL" arrow="arrow">
                  {t('Organisms.CycleSummaryTable.DayLoad')}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text="Počet fází" label="AC" arrow="arrow">
                  {t('Organisms.CycleSummaryTable.ActivityCount')}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text="Počet závodů" label="RC" arrow="arrow">
                  {t('Organisms.CycleSummaryTable.RaceCount')}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text="Dnů nemoci" label="NE" arrow="arrow">
                  {t('Organisms.CycleSummaryTable.NE')}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip
                  text="Hodin regenerace"
                  label="RE"
                  arrow="arrow"
                >
                  {t('Organisms.CycleSummaryTable.RE')}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="center" colSpan={2}>
                {t('Organisms.CycleSummaryTable.Run')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.CycleSummaryTable.Load')}
              </TableCell>
              <TableCell align="center">
                <TextWithTooltip text={t('Zones.JOGG')} arrow="arrow">
                  Klus
                </TextWithTooltip>
              </TableCell>
              <TableCell align="center">
                <TextWithTooltip text={t('Zones.AP1')} arrow="arrow">
                  AP1
                </TextWithTooltip>
              </TableCell>
              <TableCell align="center">
                <TextWithTooltip text={t('Zones.AP2')} arrow="arrow">
                  AP2
                </TextWithTooltip>
              </TableCell>
              <TableCell align="center">
                <TextWithTooltip text={t('Zones.AP3')} arrow="arrow">
                  AP3
                </TextWithTooltip>
              </TableCell>
              <TableCell align="center">
                <TextWithTooltip text={t('Zones.ANP')} arrow="arrow">
                  ANP
                </TextWithTooltip>
              </TableCell>
              {enumSport && getSportTypeColumns(enumSport)}
            </TableRow>
          </TableHead>
          <TableBody>
            {getTableRows(
              sumByCycle,
              teamInfo,
              t,
              enumSport,
              sumByWeeks,
              cycleOverview,
              weekOverview,
              sumRegByCycle,
              sumRegByWeek,
              sickDaysInCycles,
            )}
          </TableBody>
          <TableFooter>
            <CycleTotals
              sumByCycle={sumByCycle}
              cycleOverview={cycleOverview}
              enumSport={enumSport}
              sumRegByCycle={sumRegByCycle}
              sickDays={sickDaysInCycles}
              t={t}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  );
};

const Row = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow className="cursor-pointer" onClick={() => setOpen(!open)}>
        <TableCell className="p-0">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.index}.
        </TableCell>
        <TableCell align="right">{props.overview.day_load}</TableCell>
        <TableCell align="right">{props.overview.activity_count}</TableCell>
        <TableCell align="right">{props.overview.raceCount}</TableCell>
        <TableCell align="right">
          <TextWithTooltip
            text={props.t('Organisms.CycleSummaryTable.SickDays')}
            arrow="arrow"
          >
            {props.sickDays}
          </TextWithTooltip>
        </TableCell>
        <TableCell align="right">
          <TextWithTooltip
            text={props.t('Organisms.CycleSummaryTable.RegenerationTime')}
            arrow="arrow"
          >
            {secondsToHms(props.regenerationOverview.regenerationTime, 'hm')}
          </TextWithTooltip>
        </TableCell>
        <TableCell align="right">
          {secondsToHms(props.totalRunDuration, 'hm')}
        </TableCell>
        <TableCell align="right">
          {roundedToFixed(convertToKM(props.totalRunDistance), 1)}
        </TableCell>
        <TableCell align="right">
          {secondsToHms(props.totalPhysicalLoad, 'hm')}
        </TableCell>
        <TableCell align="right">
          <TextWithTooltip text={props.t('Zones.JOGG')} arrow="arrow">
            {formatSecondsToTime(props.timeZone1)}
          </TextWithTooltip>
        </TableCell>
        <TableCell align="right">
          <TextWithTooltip text={props.t('Zones.AP1')} arrow="arrow">
            {formatSecondsToTime(props.timeZone2)}
          </TextWithTooltip>
        </TableCell>
        <TableCell align="right">
          <TextWithTooltip text={props.t('Zones.AP2')} arrow="arrow">
            {formatSecondsToTime(props.timeZone3)}
          </TextWithTooltip>
        </TableCell>
        <TableCell align="right">
          <TextWithTooltip text={props.t('Zones.AP3')} arrow="arrow">
            {formatSecondsToTime(props.timeZone4)}
          </TextWithTooltip>
        </TableCell>
        <TableCell align="right">
          <TextWithTooltip text={props.t('Zones.ANP')} arrow="arrow">
            {formatSecondsToTime(props.timeZone5)}
          </TextWithTooltip>
        </TableCell>
        {getDetailColumns(props.enumSport, props.cycle)}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}
          colSpan={20}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2} boxShadow={2} p={2}>
              <div className="card-subtitle">Detail po týdnech</div>
              <Table size="small" aria-label="weeks" className="my-8">
                <TableHead>
                  <TableRow>
                    <TableCell>Týden</TableCell>
                    <TableCell align="right" width="30px">
                      <TextWithTooltip
                        text="Dnů zátěže"
                        label="DL"
                        arrow="arrow"
                      >
                        {props.t('Organisms.CycleSummaryTable.DayLoad')}
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="right" width="30px">
                      <TextWithTooltip
                        text="Počet fází"
                        label="AC"
                        arrow="arrow"
                      >
                        {props.t('Organisms.CycleSummaryTable.ActivityCount')}
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="right" width="30px">
                      <TextWithTooltip
                        text="Počet závodů"
                        label="RC"
                        arrow="arrow"
                      >
                        {props.t('Organisms.CycleSummaryTable.RaceCount')}
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="right" width="30px">
                      <TextWithTooltip
                        text="Dnů nemoci"
                        label="NE"
                        arrow="arrow"
                      >
                        {props.t('Organisms.CycleSummaryTable.NE')}
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="right" width="30px">
                      <TextWithTooltip
                        text="Hodin regenerace"
                        label="RE"
                        arrow="arrow"
                      >
                        {props.t('Organisms.CycleSummaryTable.RE')}
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      {props.t('Organisms.CycleSummaryTable.Run')}
                    </TableCell>
                    <TableCell align="center">
                      {props.t('Organisms.CycleSummaryTable.Load')}
                    </TableCell>
                    <TableCell align="center">
                      <TextWithTooltip
                        text={props.t('Zones.JOGG')}
                        arrow="arrow"
                      >
                        Klus
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <TextWithTooltip
                        text={props.t('Zones.AP1')}
                        arrow="arrow"
                      >
                        AP1
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <TextWithTooltip
                        text={props.t('Zones.AP2')}
                        arrow="arrow"
                      >
                        AP2
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <TextWithTooltip
                        text={props.t('Zones.AP3')}
                        arrow="arrow"
                      >
                        AP3
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <TextWithTooltip
                        text={props.t('Zones.ANP')}
                        arrow="arrow"
                      >
                        ANP
                      </TextWithTooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getCycleByWeeks(
                    props.index,
                    props.teamPeriod,
                    props.sumByWeeks[props.index],
                    props.weekOverview[props.index],
                    props.sumRegByWeek[props.index],
                    props.sickDaysInCycles,
                    props.t,
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const getCycleByWeeks = (
  cycleNr,
  teamPeriod,
  sumByWeeks,
  weekOverview,
  sumRegByWeek,
  sickDaysInCycles,
  t,
) => {
  const periodStartWeek = getWeek(parseISO(teamPeriod.periodStart), {
    weekStartsOn: 1,
    firstWeekContainsDate: 4,
  });
  const weeksInYear = getISOWeeksInYear(parseISO(teamPeriod.periodStart));
  let sequenceWeekNr = 4 * (cycleNr - 1) + 1;
  const cycleStart = periodStartWeek + (cycleNr - 1) * 4;
  const weeks = [];
  if (sumByWeeks) {
    sumByWeeks
      .sort((a, b) => (a.week > b.week ? 1 : -1))
      .map((item) => {
        const findItem = weeks.find((x) => x === item.week);
        if (!findItem) weeks.push(item.week);
      });
    const weekRows = [];
    for (let index = 0; index < 4; index++) {
      let week = cycleStart + index;
      if (week > weeksInYear) week = week - weeksInYear;
      const findWeek = weeks.find((x) => x === week);
      if (findWeek) {
        const overview = weekOverview.find((x) => x.week === week);
        const totalPhysicalLoad = getTotalsInWeek(
          sumByWeeks,
          'time',
          week,
          'run',
        );
        const totalRunDistance = getTotalsInWeek(
          sumByWeeks,
          'distance',
          week,
          'run',
        );
        const totalRunDuration = getTotalsInWeek(sumByWeeks, 'time', week);
        const timeZone1 = getTotalsInWeek(sumByWeeks, 'zone1', week, 'run');
        const timeZone2 = getTotalsInWeek(sumByWeeks, 'zone2', week, 'run');
        const timeZone3 = getTotalsInWeek(sumByWeeks, 'zone3', week, 'run');
        const timeZone4 = getTotalsInWeek(sumByWeeks, 'zone4', week, 'run');
        const timeZone5 = getTotalsInWeek(sumByWeeks, 'zone5', week, 'run');
        const regenerationTime = sumRegByWeek
          ? getTotalsInWeek(sumRegByWeek, 'regenerationTime', week)
          : 0;
        const sickDaysCycle = sickDaysInCycles.find((x) => x.cycle === cycleNr);
        let weekSickDays = 0;
        if (sickDaysCycle) {
          const sickDaysWeek = sickDaysCycle.weeks.find(
            (x) => x.week === sequenceWeekNr,
          );
          weekSickDays =
            sickDaysWeek &&
            typeof sickDaysWeek !== 'undefined' &&
            sickDaysWeek.sickdays;
        }
        sequenceWeekNr++;
        weekRows.push(
          <TableRow key={index}>
            <TableCell scope="row">{index + 1}.</TableCell>
            <TableCell align="right">{overview && overview.day_load}</TableCell>
            <TableCell align="right">
              {overview && overview.activity_count}
            </TableCell>
            <TableCell align="right">
              {overview && overview.raceCount}
            </TableCell>
            <TableCell align="right">
              <TextWithTooltip
                text={t('Organisms.CycleSummaryTable.SickDays')}
                arrow="arrow"
              >
                {weekSickDays}
              </TextWithTooltip>
            </TableCell>
            <TableCell align="right">
              <TextWithTooltip
                text={t('Organisms.CycleSummaryTable.RegenerationTime')}
                arrow="arrow"
              >
                {secondsToHms(regenerationTime, 'hm')}
              </TextWithTooltip>
            </TableCell>
            <TableCell align="center">
              {secondsToHms(totalPhysicalLoad, 'hm')}
            </TableCell>
            <TableCell align="center">
              {roundedToFixed(convertToKM(totalRunDistance), 1)}
            </TableCell>
            <TableCell align="center">
              {secondsToHms(totalRunDuration, 'hm')}
            </TableCell>
            <TableCell align="center">
              {formatSecondsToTime(timeZone1)}
            </TableCell>
            <TableCell align="center">
              {formatSecondsToTime(timeZone2)}
            </TableCell>
            <TableCell align="center">
              {formatSecondsToTime(timeZone3)}
            </TableCell>
            <TableCell align="center">
              {formatSecondsToTime(timeZone4)}
            </TableCell>
            <TableCell align="center">
              {formatSecondsToTime(timeZone5)}
            </TableCell>
          </TableRow>,
        );
      } else {
        weekRows.push(
          <TableRow key={index}>
            <TableCell scope="row">{index + 1}.</TableCell>
            <TableCell align="right" colSpan={13} />
          </TableRow>,
        );
      }
    }
    return weekRows;
  }
};

const getTableRows = (
  data,
  teamPeriod,
  t,
  enumSport,
  sumByWeeks,
  cycleOverview,
  weekOverview,
  sumRegByCycle,
  sumRegByWeek,
  sickDaysInCycles,
) => {
  const countCycles =
    differenceInCalendarWeeks(
      parseISO(teamPeriod.periodEnd),
      parseISO(teamPeriod.periodStart),
      1,
    ) / 4;
  const cycleRows = [];
  for (let index = 1; index <= countCycles; index++) {
    const cycle = data[index];
    if (cycle && typeof cycle !== 'undefined') {
      const overview = cycleOverview[index][0];
      const totalPhysicalLoad = getTotals(cycle, 'time');
      const totalRunDistance = getTotals(cycle, 'distance', 'run');
      const totalRunDuration = getTotals(cycle, 'time', 'run');
      const timeZone1 = getTotals(cycle, 'zone1', 'run');
      const timeZone2 = getTotals(cycle, 'zone2', 'run');
      const timeZone3 = getTotals(cycle, 'zone3', 'run');
      const timeZone4 = getTotals(cycle, 'zone4', 'run');
      const timeZone5 = getTotals(cycle, 'zone5', 'run');
      const regenerationOverview = sumRegByCycle[index]
        ? sumRegByCycle[index][0]
        : { regenerationTime: 0 };
      //debugger;
      const sickDaysInCycle = sickDaysInCycles.find(
        (sickness) => sickness.cycle === index,
      );
      const sickDays = sickDaysInCycle ? sickDaysInCycle.sickdays : 0;
      cycleRows.push(
        <Row
          key={index}
          index={index}
          overview={overview}
          totalPhysicalLoad={totalPhysicalLoad}
          totalRunDistance={totalRunDistance}
          totalRunDuration={totalRunDuration}
          timeZone1={timeZone1}
          timeZone2={timeZone2}
          timeZone3={timeZone3}
          timeZone4={timeZone4}
          timeZone5={timeZone5}
          cycle={cycle}
          t={t}
          enumSport={enumSport}
          sumByWeeks={sumByWeeks}
          teamPeriod={teamPeriod}
          weekOverview={weekOverview}
          regenerationOverview={regenerationOverview}
          sumRegByWeek={sumRegByWeek}
          sickDaysInCycles={sickDaysInCycles}
          sickDays={sickDays}
        ></Row>,
      );
    } else {
      cycleRows.push(
        <TableRow key={index}>
          <TableCell />
          <TableCell component="th" scope="row">
            {index}.
          </TableCell>
          <TableCell align="right" colSpan={35} />
        </TableRow>,
      );
    }
  }
  return cycleRows;
};
const getDetailColumns = (enumSport, data) => {
  const cycleColumns = [];
  enumSport.map((sport) => {
    const column = data.find((element) => element.abbr === sport.abbr);
    if (column) {
      cycleColumns.push(
        <>
          {column.abbr === 'BS' && (
            <>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'BD' && (
            <>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'BP' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="BS" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="BS" arrow="arrow">
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'BT' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="BT" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="BT" arrow="arrow">
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'MP' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="MP" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="MP" arrow="arrow">
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'MR' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="MR" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="MR" arrow="arrow">
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'K' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="K" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="K" arrow="arrow">
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'P' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="P" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="P" arrow="arrow">
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'OS' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="OS" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'SS' && (
            <>
              <TableCell align="right">
                <TextWithTooltip text={column.name} label="SS" arrow="arrow">
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'JAA' && (
            <>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {formatSecondsToTime(column.time)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
          {column.abbr === 'L' && (
            <>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {secondsToHms(column.time, 'hms')}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip
                  text={column.name}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {column.distance
                    ? roundedToFixed(convertToKM(column.distance), 1)
                    : 0.0}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
        </>,
      );
    } else {
      const span = getColSpan(sport);
      cycleColumns.push(
        <>
          <TableCell align="right" colSpan={span}></TableCell>
        </>,
      );
    }
  });
  return cycleColumns;
};
function getSportTypeColumns(enumSport) {
  const sportTypeColumns = [];
  enumSport.map((sport) => {
    const span = getColSpan(sport);
    sportTypeColumns.push(
      <>
        <TableCell align="center" colSpan={span}>
          <TextWithTooltip text={sport.label} arrow="arrow">
            {sport.abbr}
          </TextWithTooltip>
        </TableCell>
      </>,
    );
  });

  return sportTypeColumns;
}
function getColSpan(sport) {
  let span = 1;
  if (
    sport.kind === 'run' ||
    sport.kind === 'ride' ||
    sport.kind === 'swim' ||
    sport.kind === 'nordicski' ||
    sport.kind === 'other'
  ) {
    span = 2;
  }
  return span;
}

function roundedToFixed(_float, _digits) {
  const rounded = Math.pow(10, _digits);
  return (Math.round(_float * rounded) / rounded).toFixed(_digits);
}

const SumColumnsBySport = ({ enumSport, data }) => {
  const sumColumns = [];
  enumSport.map((sport) => {
    const span = getColSpan(sport);
    const sumSportTime = getTotalsInCycleSum(data, 'time', '', sport.value);
    const sumSportDistance = getTotalsInCycleSum(
      data,
      'distance',
      '',
      sport.value,
    );
    if (sumSportTime) {
      sumColumns.push(
        <>
          {span === 1 && (
            <TableCell align="right" colSpan={span}>
              <TextWithTooltip
                text={sport.label}
                label={sport.abbr}
                arrow="arrow"
              >
                {secondsToHms(sumSportTime)}
              </TextWithTooltip>
            </TableCell>
          )}
          {span === 2 && (
            <>
              <TableCell align="right">
                <TextWithTooltip
                  text={sport.label}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {secondsToHms(sumSportTime)}
                </TextWithTooltip>
              </TableCell>
              <TableCell align="right">
                <TextWithTooltip
                  text={sport.label}
                  label={sport.abbr}
                  arrow="arrow"
                >
                  {roundedToFixed(
                    convertToKM(sumSportDistance && sumSportDistance),
                    1,
                  )}
                </TextWithTooltip>
              </TableCell>
            </>
          )}
        </>,
      );
    } else {
      sumColumns.push(
        <>
          <TableCell align="right" colSpan={span}></TableCell>
        </>,
      );
    }
  });
  return sumColumns;
};
