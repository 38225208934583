import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import templateSettings from './templateSettings';
import { Layout1Topbar } from './Layout1TopBar';
import { Layout1Sidenav } from './Layout1Sidenav';
import Scrollbar from 'react-perfect-scrollbar';
import { Footer } from './Footer';

import { ErrorBox } from '../atoms';
import { classList } from './templateUtils';
import { isMdScreen } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
  },
}));

export function LoggedInPageLayout({ children, errorList }) {
  const history = useHistory();
  const classes = useStyles();
  const [sidebarMode, setSidebarMode] = useState({
    mode: templateSettings.leftSidebar.mode,
    sidenavToggleChecked: true,
    show: false,
  });

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', listenWindowResize);
    }
    if (isMdScreen()) {
      setSidebarMode({ mode: 'close' });
    }
    return () => {
      if (window) {
        window.removeEventListener('resize', listenWindowResize);
      }
    };
  }, []);

  const listenWindowResize = () => {
    if (templateSettings.leftSidebar.show) {
      let mode = isMdScreen() ? 'close' : 'full';
      setSidebarMode({ mode: mode });
    }
  };

  const handleSidenavToggle = (event) => {
    let sidenavToggleChecked = event.target.checked;
    let mode = sidenavToggleChecked ? 'full' : 'compact';
    setSidebarMode({ mode, sidenavToggleChecked });
  };

  const handleSidebarToggle = () => {
    let mode;
    if (isMdScreen()) {
      mode = sidebarMode.mode === 'close' ? 'mobile' : 'close';
    } else {
      mode = sidebarMode.mode === 'full' ? 'close' : 'full';
    }
    setSidebarMode({ mode });
  };

  let layoutClasses = {
    [classes.layout]: true,
    [`layout1 sidenav-${sidebarMode.mode} theme-dark flex`]: true,
    'topbar-fixed': templateSettings.topbar.fixed,
  };
  return (
    <div className={classList(layoutClasses)}>
      <Layout1Sidenav
        history={history}
        sidebarMode={sidebarMode}
        handleSidenavToggle={handleSidenavToggle}
      />

      <div className="content-wrap position-relative">
        <Layout1Topbar
          history={history}
          handleSidebarToggle={handleSidebarToggle}
          className="elevation-z8"
        />

        <Scrollbar className="scrollable-content">
          <ErrorBox className="pb-3 m-6" errorList={errorList} />
          <div className="content">{children}</div>
          <div className="my-auto" />
          <Footer />
        </Scrollbar>
      </div>
    </div>
  );
}

LoggedInPageLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default LoggedInPageLayout;
