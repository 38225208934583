import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFetchData } from './hooks';
import { TeamList } from '../../organisms';
import { LoggedInPageLayout } from '../../templates';
import { NewTeamForm } from './NewTeamForm';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

export const TeamPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { teamListState } = useFetchData();

  const errorList = [{ id: 1, error: teamListState.error }];

  const [open, setOpen] = useState(false);

  const handleOpenTeamForm = () => {
    setOpen(true);
  };

  const handleCloseTeamForm = () => {
    setOpen(false);
  };

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Team.TeamTitle')}
            </Typography>
          </div>
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
            color="primary"
            onClick={handleOpenTeamForm}
          >
            {t('Page.Team.AddNewTeamButton')}
          </Button>
          <NewTeamForm
            open={open}
            handleClose={handleCloseTeamForm}
            teamListState={teamListState}
          />
          <div className="m-sm-30">
            {teamListState.isLoading && <CircularProgress />}
            {teamListState.data && teamListState.data !== null && (
              <TeamList teamList={teamListState.data} />
            )}
          </div>
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
