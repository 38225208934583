import React from 'react';
import MaskedInput from 'react-text-mask';

export const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
      placeholder="HH:MM:SS"
      placeholderChar={'\u2000'}
    />
  );
};
