import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import TouchRipple from '@material-ui/core/ButtonBase';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import { hasPrivilege } from '../utils';

export const VerticalNav = ({ privileges }) => {
  const { t } = useTranslation();
  const hasAdministrationPagePrivilege = hasPrivilege(
    privileges,
    'AdministrationPage',
  );
  return (
    <>
      <NavLink key="1" to="/dashboard" className="nav-item">
        <TouchRipple key="Dashboard" name="child" className="w-100">
          <DashboardIcon />
          <span className="text-middle pl-20 item-text">
            {t('Molecules.VerticalNav.Dashboard')}
          </span>
          <div className="mx-auto"></div>
        </TouchRipple>
      </NavLink>
      <NavLink key="2" to="/activities" className="nav-item">
        <TouchRipple key="Training log" name="child" className="w-100">
          {(() => {
            if (true) {
              return <AssignmentIcon />;
            } else {
              return <span className="item-icon icon-text">rtrt</span>;
            }
          })()}
          <span className="text-middle pl-20 item-text">
            {t('Molecules.VerticalNav.TrainingLog')}
          </span>
          <div className="mx-auto"></div>
        </TouchRipple>
      </NavLink>
      <NavLink key="3" to="/calendar" className="nav-item">
        <TouchRipple key="Teams" name="child" className="w-100">
          <EventIcon />
          <span className="text-middle pl-20 item-text">
            {t('Molecules.VerticalNav.Calendar')}
          </span>
          <div className="mx-auto"></div>
        </TouchRipple>
      </NavLink>
      <NavLink key="4" to="/team" className="nav-item">
        <TouchRipple key="Teams" name="child" className="w-100">
          <GroupIcon />
          <span className="text-middle pl-20 item-text">
            {t('Molecules.VerticalNav.Teams')}
          </span>
          <div className="mx-auto"></div>
        </TouchRipple>
      </NavLink>
      <NavLink key="5" to="/settings/profile" className="nav-item">
        <TouchRipple key="Settings" name="child" className="w-100">
          <SettingsIcon />
          <span className="text-middle pl-20 item-text">
            {t('Molecules.VerticalNav.Settings')}
          </span>
          <div className="mx-auto"></div>
        </TouchRipple>
      </NavLink>
      {hasAdministrationPagePrivilege && (
        <NavLink key="6" to="/administration" className="nav-item">
          <TouchRipple key="Administration" name="child" className="w-100">
            <BuildIcon />
            <span className="text-middle pl-20 item-text">
              {t('Molecules.VerticalNav.Administration')}
            </span>
            <div className="mx-auto"></div>
          </TouchRipple>
        </NavLink>
      )}
    </>
  );
};
