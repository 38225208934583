import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  Paper,
  ListItem,
  ListItemText,
  Grid,
  List,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSnackbar } from 'notistack';

import { SimpleAlertDialog } from '../../molecules';
import {
  useFetchRequest,
  useAuth,
  useRequest,
  formatDateTime,
} from '../../utils';
import { LoggedInPageLayout } from '../../templates';
import { AddSicknessAttachment } from './AddAttachment';
import { EditSicknessForm } from './EditSicknessForm';

import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    margin: '20px',
  },
}));

export const SicknessDetailPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { sicknessId } = useParams();
  const history = useHistory();
  const user = useAuth();
  const deleteSicknessState = useRequest();
  const attachFileState = useRequest();
  const saveFileState = useRequest();
  const deleteAttachState = useRequest();
  const [sicknessAttachments, setSicknessAttachments] = useState(undefined);
  const [attachFile, setAttachFile] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const [sicknessStateData, setSicknessStateData] = useState(undefined);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const goToPrevPage = () => setPageNumber(pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onSuccessFetchSicknessState = (response) => {
    updateSicknessState(response);
  };

  const onSuccessFetchSicknessAttachState = (response) => {
    updateSicknessAttachState(response);
  };

  const fetchSicknessState = useFetchRequest(
    ENDPOINTS.getSicknessDetail(sicknessId),
    {
      onSuccess: onSuccessFetchSicknessState,
    },
  );

  const fetchSicknessAttachState = useFetchRequest(
    ENDPOINTS.getSicknessAllAttachment(sicknessId),
    {
      onSuccess: onSuccessFetchSicknessAttachState,
    },
  );

  const updateSicknessState = useCallback(({ data: { results: { data } } }) => {
    setSicknessStateData({
      data,
    });
  }, []);

  const updateSicknessAttachState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setSicknessAttachments({
        data,
      });
    },
    [],
  );

  const updateAttachFileState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setAttachFile({
        data,
      });
      setPageNumber(1);
    },
    [],
  );

  const handleClickOpenFile = useCallback(
    (attachId) => {
      attachFileState.request(ENDPOINTS.getSicknessAttach(attachId), {
        method: 'GET',
        onSuccess: (response) => updateAttachFileState(response),
        onError: (err) => {
          enqueueSnackbar(err, {
            variant: 'error',
          });
        },
      });
    },
    [attachFileState, updateAttachFileState, enqueueSnackbar],
  );

  const removeAttachment = (attachId) => {
    setSicknessAttachments({
      data: sicknessAttachments.data.filter(
        (item) => item.attach_id !== attachId,
      ),
    });
  };

  const onSuccessFileAttachment = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      const url = `data:application/pdf;base64,${data.file}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.name);
      document.body.appendChild(link);
      link.click();
    },
    [],
  );

  const handleClickSaveAttachment = useCallback(
    (attachId) => {
      saveFileState.request(ENDPOINTS.getSicknessAttach(attachId), {
        method: 'GET',
        responseType: 'json',
        onSuccess: onSuccessFileAttachment,
      });
    },
    [saveFileState, onSuccessFileAttachment],
  );

  const errorList = [
    {
      id: 1,
      error: fetchSicknessState.error,
    },
    {
      id: 2,
      error: fetchSicknessAttachState.error,
    },
  ];

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          {typeof sicknessStateData !== 'undefined' &&
            sicknessStateData.data &&
            (user.user.userId === sicknessStateData.data.user_id ? (
              <Button
                onClick={() =>
                  history.push(PATHNAMES.getTrainingLog('sickness'))
                }
              >
                <ArrowBackIosIcon fontSize="small" />
                {t('Page.Sickness.SicknessDetailPage.AllSicknesses')}
              </Button>
            ) : (
              <Button onClick={() => history.goBack()}>
                <ArrowBackIosIcon fontSize="small" />
                {t('Page.Sickness.SicknessDetailPage.AllSicknesses')}
              </Button>
            ))}
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Sickness.SicknessDetailPage.SicknessDetailTitle')}
            </Typography>
          </div>
          {fetchSicknessState.isLoading && (
            <CircularProgress className={classes.progress} />
          )}
          {typeof sicknessStateData !== 'undefined' && sicknessStateData.data && (
            <>
              <div className="mb-12">
                {user.user.userId === sicknessStateData.data.user_id && (
                  <>
                    <div className="flex flex-space-between">
                      <div className="flex">
                        <EditSicknessForm
                          sicknessStateData={sicknessStateData}
                          setSicknessStateData={setSicknessStateData}
                        />
                        <AddSicknessAttachment
                          sicknessId={sicknessId}
                          setSicknessAttachments={setSicknessAttachments}
                        />
                      </div>
                      <SimpleAlertDialog
                        title={t(
                          'Page.Sickness.SicknessDetailPage.DeleteSicknessConfirm',
                        )}
                        message={
                          t(
                            'Page.Sickness.SicknessDetailPage.DeleteSicknessMessage',
                          ) +
                          ' ' +
                          sicknessStateData.data.problem
                        }
                        handleConfirm={() =>
                          deleteSicknessState.request(
                            ENDPOINTS.deleteSickness(),
                            {
                              method: 'DELETE',
                              onSuccess: () => {
                                history.push(
                                  PATHNAMES.getTrainingLog('sickness'),
                                );
                                enqueueSnackbar(
                                  t(
                                    'Page.Sickness.SicknessDetailPage.DeletedSicknessAlert',
                                  ),
                                  {
                                    variant: 'success',
                                  },
                                );
                              },
                              onError: (err) => {
                                enqueueSnackbar('Uh Oh, ' + err, {
                                  variant: 'error',
                                });
                              },
                              data: {
                                sicknessId: sicknessId,
                              },
                            },
                          )
                        }
                        color="secondary"
                      >
                        {t('Page.Sickness.SicknessDetailPage.Delete')}
                      </SimpleAlertDialog>
                    </div>
                  </>
                )}
              </div>
              <Paper elevation={0} className="p-20">
                <ListItem>
                  <ListItemText
                    primary={sicknessStateData.data.problem}
                    secondary={t('Page.Sickness.CreateSicknessPage.Problem')}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={sicknessStateData.data.sickness_type.name}
                    secondary={t('Page.Sickness.CreateSicknessPage.Diagnosis')}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      sicknessStateData.data.date_from +
                      ' - ' +
                      sicknessStateData.data.date_to
                    }
                    secondary={t('Page.Sickness.SicknessDetailPage.DateFromTo')}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={sicknessStateData.data.description}
                    secondary={t('Organisms.ActivityForm.Description')}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      sicknessStateData.data.alternative_training === true
                        ? 'Ano'
                        : 'Ne'
                    }
                    secondary={t(
                      'Page.Sickness.CreateSicknessPage.AlternativeTraining',
                    )}
                  />
                </ListItem>
              </Paper>
              {typeof sicknessAttachments !== 'undefined' &&
                sicknessAttachments.data && (
                  <>
                    {sicknessAttachments.data.length > 0 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          <Paper elevation={0} className="mt-12 p-20">
                            <Typography variant="h6">
                              {t(
                                'Page.Sickness.SicknessDetailPage.Attachments',
                              )}
                            </Typography>
                            <List dense={true}>
                              {sicknessAttachments.data.map((attach) => {
                                return (
                                  <Tooltip
                                    title={t(
                                      'Page.Sickness.SicknessDetailPage.ShowDocument',
                                    )}
                                    key={attach.attach_id}
                                  >
                                    <ListItem
                                      dense={true}
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleClickOpenFile(attach.attach_id)
                                      }
                                      key={attach.attach_id}
                                    >
                                      <ListItemAvatar>
                                        <Avatar>
                                          <FolderIcon />
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={attach.name}
                                        secondary={formatDateTime(
                                          attach.created_at,
                                        )}
                                      />
                                      <ListItemSecondaryAction>
                                        <div className="flex">
                                          <IconButton
                                            onClick={() =>
                                              handleClickSaveAttachment(
                                                attach.attach_id,
                                              )
                                            }
                                          >
                                            <Tooltip
                                              title={t(
                                                'Page.Sickness.SicknessDetailPage.SaveAttachment',
                                              )}
                                            >
                                              <SaveAltIcon />
                                            </Tooltip>
                                          </IconButton>
                                          {user.user.userId ===
                                            sicknessStateData.data.user_id && (
                                            <SimpleAlertDialog
                                              title={t(
                                                'Page.Sickness.SicknessDetailPage.DeleteAttachmentConfirm',
                                              )}
                                              message={
                                                t(
                                                  'Page.Sickness.SicknessDetailPage.DeleteAttachmentMessage',
                                                ) +
                                                ' ' +
                                                attach.name
                                              }
                                              type="icon"
                                              handleConfirm={() =>
                                                deleteAttachState.request(
                                                  ENDPOINTS.deleteSicknessAttachment(),
                                                  {
                                                    method: 'DELETE',
                                                    onSuccess: () => {
                                                      removeAttachment(
                                                        attach.attach_id,
                                                      );
                                                      enqueueSnackbar(
                                                        t(
                                                          'Page.Sickness.SicknessDetailPage.DeletedAttachmentAlert',
                                                        ),
                                                        {
                                                          variant: 'success',
                                                        },
                                                      );
                                                    },
                                                    onError: (err) => {
                                                      enqueueSnackbar(
                                                        'Uh Oh, ' + err,
                                                        {
                                                          variant: 'error',
                                                        },
                                                      );
                                                    },
                                                    data: {
                                                      attachId:
                                                        attach.attach_id,
                                                    },
                                                  },
                                                )
                                              }
                                            >
                                              <Tooltip
                                                title={t(
                                                  'Page.Sickness.SicknessDetailPage.DeleteAttachment',
                                                )}
                                              >
                                                <DeleteIcon />
                                              </Tooltip>
                                            </SimpleAlertDialog>
                                          )}
                                        </div>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  </Tooltip>
                                );
                              })}
                            </List>
                          </Paper>
                        </Grid>
                        {typeof attachFile !== 'undefined' && attachFile.data && (
                          <Grid item xs={12} md={7}>
                            <Paper elevation={0} className="mt-12 p-20">
                              <nav>
                                {pageNumber > 1 && (
                                  <Tooltip
                                    title={t(
                                      'Page.Sickness.SicknessDetailPage.PrevPage',
                                    )}
                                  >
                                    <IconButton
                                      aria-label="prev"
                                      onClick={goToPrevPage}
                                      size="small"
                                    >
                                      <ArrowBackIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {pageNumber > 0 && pageNumber < numPages && (
                                  <Tooltip
                                    title={t(
                                      'Page.Sickness.SicknessDetailPage.NextPage',
                                    )}
                                  >
                                    <IconButton
                                      aria-label="next"
                                      onClick={goToNextPage}
                                      size="small"
                                    >
                                      <ArrowForwardIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </nav>
                              <Document
                                file={`data:application/pdf;base64,${attachFile.data.file.toString(
                                  'base64',
                                )}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={{
                                  workerSrc: 'pdf.worker.js',
                                }}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                              <p>
                                {t('Page.Sickness.SicknessDetailPage.Page')}{' '}
                                {pageNumber}{' '}
                                {t('Page.Sickness.SicknessDetailPage.Of')}{' '}
                                {numPages}
                              </p>
                            </Paper>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </>
                )}
            </>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
