import React from 'react';
import { Card, Grid, IconButton } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TimerIcon from '@material-ui/icons/Timer';
import SpeedIcon from '@material-ui/icons/Speed';
import TimelineIcon from '@material-ui/icons/Timeline';

import { convertToKM, getTotals, secondsToHms } from '../../utils';

export const BaseSummary = ({ athleteSummary, t }) => {
  return (
    <Grid container spacing={2} className="flex-stretch">
      <Grid item xs={12} sm={6} lg={3}>
        <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
          <IconButton aria-label="total count">
            <AssessmentIcon />
          </IconButton>
          <div className="ml-4">
            <h3 className="mt-1 text-32">
              {getTotals(athleteSummary, 'count')}
            </h3>
            <p className="m-0 text-muted">
              {t('Page.Team.TeamMemberDetail.AthleteBaseInfo.TotalCount')}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
          <IconButton aria-label="total time">
            <TimerIcon />
          </IconButton>
          <div className="ml-4">
            <h3 className="mt-1 text-32">
              {getTotals(athleteSummary, 'time')
                ? secondsToHms(getTotals(athleteSummary, 'time'))
                : '00:00:00'}
            </h3>
            <p className="m-0 text-muted">
              {t('Page.Dashboard.BaseSummary.TimeTotal')}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
          <IconButton aria-label="total time">
            <SpeedIcon />
          </IconButton>
          <div className="ml-4">
            <h3 className="mt-1 text-32">
              {getTotals(athleteSummary, 'time')
                ? secondsToHms(getTotals(athleteSummary, 'time', 'run'))
                : '00:00:00'}
            </h3>
            <p className="m-0 text-muted">
              {t('Page.Dashboard.BaseSummary.RunningTimeTotal')}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
          <IconButton aria-label="total distance">
            <TimelineIcon />
          </IconButton>
          <div className="ml-4">
            <h3 className="mt-1 text-32">
              {getTotals(athleteSummary, 'distance')
                ? convertToKM(getTotals(athleteSummary, 'distance', 'run')) +
                  ' km'
                : '0 km'}
            </h3>
            <p className="m-0 text-muted">
              {t('Page.Dashboard.BaseSummary.RunningDistanceTotal')}
            </p>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
