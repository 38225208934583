import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Scrollbar from 'react-perfect-scrollbar';

import { CopyrightWithBox } from '../../../molecules';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export const PrivacyPolicyPage = () => {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);

  return (
    <Scrollbar className="scrollable-content">
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            className="mb-24"
          >
            Zásady ochrany osobních údajů
          </Typography>
          <Typography variant="h6" gutterBottom>
            I. Základní ustanovení
          </Typography>
          <ol>
            <li>
              Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského
              parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně
              fyzických osob v souvislosti se zpracováním osobních údajů a o
              volném pohybu těchto údajů (dále jen: „<strong>GDPR</strong>”) je
              Český svaz orientačních sportů (dále jen „ČSOS“), IČ 00548677 se
              sídlem Zátopkova 100/2, Břevnov, Praha 6, 169 00 (dále jen: „
              <strong>správce</strong>“).
            </li>
            <li>
              Kontaktní údaje správce jsou: <br />
              Český svaz orientačních sportů
              <br />
              IČO: 00548677
              <br />
              adresa: Zátopkova 100/2, Břevnov, Praha 6, 169 00
              <br />
              e-mail: treninkac@orientacnisporty.cz
            </li>
            <li>
              Osobními údaji se rozumí veškeré informace o identifikované nebo
              identifikovatelné fyzické osobě; identifikovatelnou fyzickou
              osobou je fyzická osoba, kterou lze přímo či nepřímo
              identifikovat, zejména odkazem na určitý identifikátor, například
              jméno, identifikační číslo, lokační údaje, síťový identifikátor
              nebo na jeden či více zvláštních prvků fyzické, fyziologické,
              genetické, psychické, ekonomické, kulturní nebo společenské
              identity této fyzické osoby.
            </li>
            <li>
              Správce jmenoval pověřence pro ochranu osobních údajů. Kontaktními
              údaji pověřence jsou:
              <ul>
                <li>Martin Křivda, treninkac@orientacnisporty.cz</li>
              </ul>
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            II. Zdroje a kategorie zpracovávaných osobních údajů
          </Typography>
          <ol>
            <li>
              Správce zpracovává osobní údaje, které jste mu poskytl/anebo
              osobní údaje, které správce získal na základě plnění Vaší
              registrace.
            </li>
            <li>
              Správce zpracovává Vaše identifikační a kontaktní údaje a údaje
              nezbytné pro poskytování služby elektronického tréninkového
              deníku.
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            III. Zákonný důvod a účel zpracování osobních údajů
          </Typography>
          <ol>
            <li>
              Zákonným důvodem zpracování osobních údajů je
              <ul>
                <li>
                  poskytování služby dle dohody mezi Vámi a správcem podle čl. 6
                  odst. 1 písm. b) GDPR,
                </li>
                <li>
                  oprávněný zájem správce na poskytování přímého marketingu
                  (zejména pro zasílání obchodních sdělení a newsletterů) podle
                  čl. 6 odst. 1 písm. f) GDPR,
                </li>
              </ul>
            </li>
            <li>
              Účelem zpracování osobních údajů je
              <ul>
                <li>
                  vyřízení Vašeho profilu a výkon práv a povinností
                  vyplývajících ze vztahu mezi Vámi a správcem; při registraci
                  jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné
                  vyřízení elektronického tréninkového deníku, poskytnutí
                  osobních údajů je nutným požadavkem pro uzavření a poskytování
                  služby, bez poskytnutí osobních údajů není možné registraci
                  dokončit či jí ze strany správce plnit,
                </li>
                <li>
                  zasílání obchodních sdělení a činění dalších marketingových
                  aktivit.
                </li>
              </ul>
            </li>
            <li>
              Ze strany správce nedochází k automatickému individuálnímu
              rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste
              poskytl/a svůj výslovný souhlas.
            </li>
            <li>
              Tyto informace jsou neveřejné a viditelné po přihlášení pouze pro
              daného uživatele, jeho ověřeného trenéra a administrátora ČSOS
              vázaného „Směrnicí o ochraně osobních údajů v ČSOS“.
            </li>
            <li>
              Každý uživatel, který se registruje do elektronického tréninkového
              deníku, musí dát k dispozici níže uvedené osobní údaje:
              <ul>
                <li>Jméno a příjmení</li>
                <li>Registrační číslo ČSOS</li>
                <li>E-mail</li>
                <li>Datum narození</li>
                <li>Pohlaví</li>
              </ul>
            </li>
            <li>
              Služby elektronického tréninkového deníku jsou založeny na
              citlivých údajích shromažďovaných chytrými zařízeními,
              sporttestery. Některé shromažďované údaje (jako jsou údaje o
              tepové frekvenci) jsou ze své podstaty takové, že k jejich
              shromažďování a zpracování správce vyžaduje Vaše svolení, zejména
              se jedná o:
              <ul>
                <li>Údaje o tepové frekvenci</li>
                <li>Údaje o Vaší poloze</li>
                <li>
                  Údaje o Vašich aktivitách a trénincích (čas, vzdálenost,
                  převýšení, trasa, apod.)
                </li>
                <li>Údaje o Vašich tréninkových zónách</li>
                <li>Údaje o hmotnosti a výšce</li>
              </ul>
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            IV. Doba uchovávání údajů
          </Typography>
          <ol>
            <li>
              Správce uchovává osobní údaje
              <ul>
                <li>
                  po dobu nezbytnou k výkonu práv a povinností vyplývajících ze
                  vztahu mezi Vámi (sportovcem / trenérem) a správcem a
                  uplatňování nároků z podstaty vaší registrace.
                </li>
              </ul>
              <li>
                Po uplynutí doby uchovávání osobních údajů správce osobní údaje
                vymaže.
              </li>
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            V. Příjemci osobních údajů (subdodavatelé správce)
          </Typography>
          <ol>
            <li>Správce nepředává osobní údaje třetí straně.</li>
            <li>
              Správce nemá v úmyslu předat osobní údaje do třetí země (do země
              mimo EU) nebo mezinárodní organizaci.
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            VI. Vaše práva
          </Typography>
          <ol>
            <li>
              Za podmínek stanovených v GDPR máte
              <ul>
                <li>
                  právo na přístup ke svým osobním údajům dle čl. 15 GDPR,
                </li>
                <li>
                  právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
                  zpracování dle čl. 18 GDPR,
                </li>
                <li>právo na výmaz osobních údajů dle čl. 17 GDPR,</li>
                <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a</li>
                <li>právo na přenositelnost údajů dle čl. 20 GDPR,</li>
                <li>
                  právo odvolat souhlas se zpracováním písemně nebo elektronicky
                  na adresu nebo email správce uvedený v čl. III těchto
                  podmínek.
                </li>
              </ul>
            </li>
            <li>
              Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů
              v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu
              osobních údajů.
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            VII. Podmínky zabezpečení osobních údajů
          </Typography>
          <ol>
            <li>
              Správce prohlašuje, že přijal veškerá vhodná technická a
              organizační opatření k zabezpečení osobních údajů.
            </li>
            <li>
              Správce přijal technická opatření k zabezpečení datových úložišť a
              úložišť osobních údajů v listinné podobě, zejména šifrováním
              protokolem https, použití antivirových programů, bezpečné uložení
              záloh, technologii firewallu.
            </li>
            <li>
              Správce prohlašuje, že k osobním údajům mají přístup pouze jím
              pověřené osoby.
            </li>
          </ol>
          <Typography variant="h6" gutterBottom>
            VIII. Závěrečná ustanovení
          </Typography>
          <ol>
            <li>
              Registrací do elektronického tréninkového deníku z webového
              registračního formuláře potvrzujete, že jste seznámen/a s
              podmínkami ochrany osobních údajů a že je v celém rozsahu
              přijímáte.
            </li>
            <li>
              S těmito podmínkami souhlasíte zaškrtnutím souhlasu
              prostřednictvím internetového formuláře. Zaškrtnutím souhlasu
              potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
              údajů a že je v celém rozsahu přijímáte.
            </li>
            <li>
              Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek
              ochrany osobních údajů zveřejní na svých internetových stránkách,
              případně Vám zašle novou verzi těchto podmínek na e-mailovou
              adresu, kterou jste správci poskytl/a.
            </li>
          </ol>
          <Typography variant="subtitle1">
            Tyto podmínky nabývají účinnosti dnem 1.1.2021.
          </Typography>
        </Paper>
        <CopyrightWithBox />
      </Container>
    </Scrollbar>
  );
};
