import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import echarts from 'echarts';
import {
  Button,
  Card,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';

import { SpeedDials, Emoji } from '../../atoms';
import {
  DoughnutChart,
  ModifiedAreaChart,
  WeekSummaryTable,
  CycleSummaryTable,
  CycleBarChart,
} from '../../organisms';
import { LoggedInPageLayout } from '../../templates';
import { LayoutedActivities } from './LayoutedActivities';
import { useAuth, useRequest } from '../../utils';

import { useFetchData } from './hooks';
import { BaseSummary } from './BaseSummary';
import PATHNAMES from '../../pathnames';
import ENDPOINTS from '../../endpoints';
import theme from 'theme';

import parseISO from 'date-fns/parseISO';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';

const useStyles = makeStyles((theme) => ({
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
  },
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
    '& .MuiFormLabel-root': {
      color: 'white',
    },
  },
  table: {
    minWidth: 650,
  },
  row: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    margin: theme.spacing(1),
    paddingRight: '15px',
  },
}));

const CycleDistanceChart = ({ data, seasonPeriod }) => {
  const countCycles =
    differenceInCalendarWeeks(
      parseISO(seasonPeriod.periodEnd),
      parseISO(seasonPeriod.periodStart),
      1,
    ) / 4;
  const xAxisCategory = Array.from({ length: countCycles }, (_, i) => i + 1);

  return (
    <ModifiedAreaChart
      height="300px"
      option={{
        tooltip: {
          formatter: function (params) {
            var colorSpan = (color) =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            let rez = '<p>' + params[0].axisValue + '</p>';
            //console.log(params); //quite useful for debug
            params.forEach((item) => {
              //console.log(item); //quite useful for debug
              var xx =
                '<p>' +
                colorSpan(item.color) +
                ' ' +
                item.seriesName +
                ': ' +
                item.data +
                ' km' +
                '</p>';
              rez += xx;
            });

            return rez;
          },
        },
        series: [
          {
            name: 'Minulé období',
            data: data[1],
            type: 'line',
            itemStyle: {
              color: '#fff',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255,255,255,0.3)', // color at 0% position
                },
                {
                  offset: 1,
                  color: 'rgba(255,255,255,0)', // color at 100% position
                },
              ]),
            },
          },
          {
            name: 'Současné období',
            data: data[0],
            type: 'line',
            itemStyle: {
              color: 'rgb(255, 70, 131)',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255, 158, 68)',
                },
                {
                  offset: 1,
                  color: 'rgb(255, 70, 131)',
                },
              ]),
            },
          },
        ],
        xAxis: {
          data: xAxisCategory,
        },
        yAxis: {
          min: Math.round(Math.min(...data.flatMap((season) => season))),
          max: Math.round(Math.max(...data.flatMap((season) => season))),
        },
        color: [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(255,255,255,0.3)', // color at 0% position
              },
              {
                offset: 1,
                color: 'rgba(255,255,255,0)', // color at 100% position
              },
            ],
            global: false, // false by default
          },
        ],
      }}
    ></ModifiedAreaChart>
  );
};

const SeasonSwitcher = ({
  seasonState,
  setAthleteStatisticsState,
  setTrainingPeriodState,
  t,
  classes,
}) => {
  const refreshAthleteStatisticsState = useRequest();
  const { updateAthleteStatisticsState } = useFetchData(
    setAthleteStatisticsState,
  );
  const currentSeason = seasonState.data.find(
    (season) => season.current === true,
  );
  const [selectedSeasonState, setSelectedSeasonState] = useState(
    currentSeason.season_id || '',
  );
  useEffect(() => {
    const trainingPeriod = seasonState.data.find(
      (season) => season.season_id === selectedSeasonState,
    );
    setTrainingPeriodState(trainingPeriod);
  });

  const getAthleteStatisticsPerSeason = ({ seasonId }) => {
    refreshAthleteStatisticsState.request(
      ENDPOINTS.getAthleteStatisticsPerSeason(seasonId),
      {
        method: 'GET',
        onSuccess: (response) => updateAthleteStatisticsState(response),
      },
    );
  };

  const handleChangeSeason = (e) => {
    setSelectedSeasonState(e.target.value);
    const selectedPeriod = seasonState.data.find(
      (season) => season.season_id === selectedSeasonState,
    );
    setTrainingPeriodState(selectedPeriod);
    const seasonId = e.target.value;
    getAthleteStatisticsPerSeason({ seasonId });
  };
  return (
    <FormControl fullWidth>
      <InputLabel
        className={classes.root}
        color="secondary"
        id="season-switcher-label"
      >
        {t('Page.Dashboard.SeasonSwitcher.Season')}
      </InputLabel>
      <Select
        labelId="season-switcher-label"
        id="season-switcher"
        label="Season"
        value={selectedSeasonState}
        onChange={handleChangeSeason}
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
        }}
      >
        {seasonState.data.map((season) => {
          return (
            <MenuItem key={season.season_id} value={season.season_id}>
              {season.start_date} - {season.end_date}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const DashboardPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [athleteStatisticsState, setAthleteStatisticsState] =
    useState(undefined);
  const [trainingPeriodState, setTrainingPeriodState] = useState(undefined);
  const {
    fetchSeasonState,
    unidentifiedActivityState,
    athleteSettingsState,
    fetchAthleteStatisticsState,
    enumSportState,
  } = useFetchData(setAthleteStatisticsState);
  const { user } = useAuth();

  const errorList = [
    { id: 1, error: unidentifiedActivityState.error },
    { id: 2, error: athleteSettingsState.error },
    { id: 3, error: fetchAthleteStatisticsState.error },
    { id: 4, error: enumSportState.error },
    { id: 5, error: fetchSeasonState.error },
  ];

  const handleClickSettings = () => {
    history.push(PATHNAMES.settings());
  };

  const handleClickUpload = () => {
    history.push(PATHNAMES.upload());
  };

  const classes = useStyles();

  return (
    <LoggedInPageLayout errorList={errorList}>
      {athleteSettingsState.data && athleteSettingsState.data.zones === false && (
        <div className={classes.alert}>
          <Alert
            variant="outlined"
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={handleClickSettings}
              >
                {t('Page.Dashboard.Settings')}
              </Button>
            }
          >
            {t('Page.Dashboard.AlertSetZones')}
          </Alert>
        </div>
      )}
      {athleteSettingsState.data && athleteSettingsState.data.device === false && (
        <div className={classes.alert}>
          <Alert
            variant="outlined"
            severity="info"
            action={
              <Button color="inherit" size="small" onClick={handleClickUpload}>
                {t('Page.Dashboard.GetStarted')}
              </Button>
            }
          >
            {t('Page.Dashboard.AlertFirstlyConnect')}
          </Alert>
        </div>
      )}
      <div className="pb-86 pt-30 px-30 bg-primary">
        {fetchSeasonState.data && (
          <div className="w-220 pb-30">
            <SeasonSwitcher
              seasonState={fetchSeasonState}
              setAthleteStatisticsState={setAthleteStatisticsState}
              setTrainingPeriodState={setTrainingPeriodState}
              t={t}
              classes={classes}
            />
          </div>
        )}
        {typeof athleteStatisticsState !== 'undefined' &&
          athleteStatisticsState.data &&
          typeof trainingPeriodState !== 'undefined' && (
            <CycleDistanceChart
              data={athleteStatisticsState.data.cycleDistance}
              seasonPeriod={{
                periodStart: trainingPeriodState.start_date,
                periodEnd: trainingPeriodState.end_date,
              }}
            ></CycleDistanceChart>
          )}
      </div>
      <div className="analytics m-sm-30 mt--56">
        <Grid container spacing={2} className="flex-stretch">
          {typeof athleteStatisticsState !== 'undefined' &&
            athleteStatisticsState.data && (
              <>
                <Grid item xs={12}>
                  <BaseSummary
                    athleteSummary={athleteStatisticsState.data.athleteSummary}
                    t={t}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WeekSummaryTable
                    t={t}
                    sumByWeek={athleteStatisticsState.data.sumByWeek}
                    currentWeek={athleteStatisticsState.data.currentWeek}
                  ></WeekSummaryTable>
                </Grid>
              </>
            )}
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Card className="px-24 py-16 h-100">
              <div className="card-title">
                {t('Page.Dashboard.UnidentifiedActivity')}
              </div>
              <div className="card-subtitle">
                {t('Page.Dashboard.AllUnidentified')}
              </div>
              {typeof unidentifiedActivityState !== 'undefined' &&
                unidentifiedActivityState.data &&
                (unidentifiedActivityState.data.pageOfItems &&
                unidentifiedActivityState.data.pageOfItems.length > 0 ? (
                  <>
                    <LayoutedActivities
                      activityList={unidentifiedActivityState.data}
                      header={t('Page.Dashboard.UnidentifiedActivity')}
                      theme={theme}
                    />
                  </>
                ) : (
                  <div>
                    <div className="p-32 flex flex-center flex-middle h-100">
                      <img
                        src="/assets/images/illustrations/thumbup.svg"
                        alt="Thumb Up"
                        width="100px"
                      />
                    </div>
                    <Typography className="text-center font-weight-semi-bold">
                      {t('Page.Dashboard.EmptyQueue')}
                    </Typography>
                    <p className="text-small text-center">
                      {t('Page.Dashboard.CleanBoard')}{' '}
                      <Emoji symbol="🤔" label="thinker" />
                    </p>
                  </div>
                ))}
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card className="px-24 py-16 h-100">
              <div className="card-title">
                {t('Page.Dashboard.TimeInZones')}
              </div>
              <div className="card-subtitle">
                {t('Page.Dashboard.Last7days')}
              </div>
              {typeof athleteStatisticsState !== 'undefined' &&
                athleteStatisticsState.data &&
                (athleteStatisticsState.data.timeInZones[0].zone1 ||
                athleteStatisticsState.data.timeInZones[0].zone2 ||
                athleteStatisticsState.data.timeInZones[0].zone3 ||
                athleteStatisticsState.data.timeInZones[0].zone4 ||
                athleteStatisticsState.data.timeInZones[0].zone5 ? (
                  <DoughnutChart
                    data={[
                      {
                        value: athleteStatisticsState.data.timeInZones[0].zone1,
                        name: t('Organisms.WeekSummaryTable.Zone1'),
                      },
                      {
                        value: athleteStatisticsState.data.timeInZones[0].zone2,
                        name: t('Organisms.WeekSummaryTable.Zone2'),
                      },
                      {
                        value: athleteStatisticsState.data.timeInZones[0].zone3,
                        name: t('Organisms.WeekSummaryTable.Zone3'),
                      },
                      {
                        value: athleteStatisticsState.data.timeInZones[0].zone4,
                        name: t('Organisms.WeekSummaryTable.Zone4'),
                      },
                      {
                        value: athleteStatisticsState.data.timeInZones[0].zone5,
                        name: t('Organisms.WeekSummaryTable.Zone5'),
                      },
                    ]}
                    height="300px"
                    color={[
                      theme.palette.primary.light,
                      theme.palette.primary.main,
                      theme.palette.primary.dark,
                      theme.palette.secondary.dark,
                      theme.palette.secondary.main,
                    ]}
                  />
                ) : (
                  <div>
                    <div className="p-32 flex flex-center flex-middle h-100">
                      <img
                        src="/assets/images/illustrations/heart-rate.svg"
                        alt="Heart"
                        width="100px"
                      />
                    </div>
                    <Typography className="text-center text-small">
                      {t('Page.Dashboard.NoHRData')}
                    </Typography>
                  </div>
                ))}
            </Card>
          </Grid>
          <Grid item xs={12}>
            {typeof athleteStatisticsState !== 'undefined' &&
              athleteStatisticsState.data &&
              typeof trainingPeriodState !== 'undefined' &&
              enumSportState.data && (
                <CycleSummaryTable
                  t={t}
                  sumByCycle={athleteStatisticsState.data.sumByCycle}
                  sumByWeeks={athleteStatisticsState.data.sumByWeeks}
                  cycleOverview={athleteStatisticsState.data.cycleOverview}
                  weekOverview={athleteStatisticsState.data.weekOverview}
                  sumRegByCycle={athleteStatisticsState.data.sumRegByCycle}
                  sumRegByWeek={athleteStatisticsState.data.sumRegByWeek}
                  sickDaysInCycles={athleteStatisticsState.data.sickDaysInCycles}
                  teamInfo={{
                    periodStart: trainingPeriodState.start_date,
                    periodEnd: trainingPeriodState.end_date,
                  }}
                  enumSport={enumSportState.data.sports}
                  memberId={user.userId}
                ></CycleSummaryTable>
              )}
          </Grid>
          {typeof athleteStatisticsState !== 'undefined' &&
            athleteStatisticsState.data &&
            typeof trainingPeriodState !== 'undefined' &&
            enumSportState.data && (
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <Card className="px-24 py-16">
                  <div className="card-title">
                    {t('Page.Team.TeamMemberDetail.CycleChart')}
                  </div>
                  <div className="card-subtitle">
                    {t('Organisms.CycleSummaryTable.CurrentPeriod')}
                  </div>
                  <CycleBarChart
                    data={athleteStatisticsState.data.sumByCycle}
                    teamPeriod={{
                      periodStart: trainingPeriodState.start_date,
                      periodEnd: trainingPeriodState.end_date,
                    }}
                    enumSports={enumSportState.data.sports}
                  />
                </Card>
              </Grid>
            )}
          {typeof athleteStatisticsState !== 'undefined' &&
            athleteStatisticsState.data &&
            athleteStatisticsState.data.oProportionCycle.length >= 2 && (
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Card className="px-24 py-16">
                  <div className="card-title">
                    {t('Page.Dashboard.OrienteeringProportion')}
                  </div>
                  <div className="card-subtitle">
                    {t('Page.Dashboard.CurrentCycle')}
                  </div>

                  <DoughnutChart
                    data={[
                      {
                        value:
                          athleteStatisticsState.data.oProportionCycle[0].time,
                        name: t('Organisms.Metrics.Run'),
                      },
                      {
                        value:
                          athleteStatisticsState.data.oProportionCycle[1].time,
                        name: t('Organisms.Metrics.oRun'),
                      },
                    ]}
                    height="400px"
                    color={[
                      theme.palette.primary.main,
                      theme.palette.secondary.main,
                    ]}
                  />
                </Card>
              </Grid>
            )}
        </Grid>
      </div>
      <SpeedDials />
    </LoggedInPageLayout>
  );
};
