import React, { useState, useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import TranslateIcon from '@material-ui/icons/Translate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Icon, MenuItem, Tooltip, withStyles } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import PATHNAMES from '../pathnames';
import { useAuth } from '../utils';
import { LanguageMenu, MenuButton } from '../atoms';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100vh',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

const IconButtonWhite = withStyles((theme) => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: 'transparent',
    padding: '5px',
  },
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: '1rem',
  },
}))(Icon);

const LoggedInHeaderBase = ({ homeLinkTo, children, history }, props) => {
  const { user, signout } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutCallback = useCallback(() => {
    console.log('click');
    signout();
    history.push(PATHNAMES.signIn());
  }, [history, signout]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className="sidenav__hold">
        <div className="sidenav__user flex">
          <div className="mx-8 text-middle circular-image-small cursor-pointer">
            <img src="/assets/images/face-3.jpg" alt="user" />
          </div>
          <div className="ml-8">
            <span className="username">
              {/* <Icon>lock</Icon> */}
              {user && user.firstName} {user && user.lastName}
            </span>
            <div className="user__menu">
              <MenuButton
                menuButton={
                  <Tooltip title="Settings">
                    <IconButtonWhite
                      aria-label="Delete"
                      className=""
                      size="small"
                    >
                      <IconSmall> settings </IconSmall>
                    </IconButtonWhite>
                  </Tooltip>
                }
              >
                <MenuItem
                  className="flex flex-middle"
                  style={{ minWidth: 185 }}
                >
                  <Icon> home </Icon>
                  <span className="pl-16"> Home </span>
                </MenuItem>
                <MenuItem style={{ minWidth: 185 }}>
                  <Link className="flex flex-middle" to={PATHNAMES.settings()}>
                    <SettingsIcon />
                    <span className="pl-16"> Settings </span>
                  </Link>
                </MenuItem>
              </MenuButton>

              <Tooltip title="Profile">
                <IconButtonWhite aria-label="Delete" className="" size="small">
                  <IconSmall>person</IconSmall>
                </IconButtonWhite>
              </Tooltip>
              <Tooltip title="Sign out">
                <IconButtonWhite
                  onClick={logoutCallback}
                  aria-label="Delete"
                  className=""
                  size="small"
                >
                  <IconSmall>exit_to_app</IconSmall>
                </IconButtonWhite>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <List>
        {['Dashboard', 'Training log', 'Teams'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <LanguageMenu>
            <TranslateIcon />
            <Hidden smDown>Language</Hidden>
            <KeyboardArrowDownIcon />
          </LanguageMenu>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <MenuButton
            menuButton={
              <img
                className="mx-8 text-middle circular-image-small cursor-pointer"
                src="/assets/images/face-3.jpg"
                alt="user"
              />
            }
          >
            <MenuItem style={{ minWidth: 185 }}>
              <Link className="flex flex-middle" to={PATHNAMES.dashboard()}>
                <DashboardIcon />
                <span className="pl-16"> Dashboard </span>
              </Link>
            </MenuItem>
            <MenuItem style={{ minWidth: 185 }}>
              <Link className="flex flex-middle" to={PATHNAMES.settings()}>
                <SettingsIcon />
                <span className="pl-16"> Settings </span>
              </Link>
            </MenuItem>
            <MenuItem className="flex flex-middle" style={{ minWidth: 185 }}>
              <ExitToAppIcon />
              <span className="pl-16" onClick={logoutCallback}>
                {' '}
                Logout{' '}
              </span>
            </MenuItem>
          </MenuButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{drawer}</List>
        <Divider />
      </Drawer>
    </>
  );
};

export const HeaderBaseLoggedIn = withRouter(LoggedInHeaderBase);
