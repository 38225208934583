import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { SimpleAlertDialog } from '../../../molecules';
import { Field, DatePickerField } from '../../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
} from '../../../utils';
import { LoggedInPageLayout } from '../../../templates';
import { TeamMemberSettings } from './TeamMemberSettings';
import { TeamInvitation } from './TeamInvitation';
import PATHNAMES from '../../../pathnames';
import ENDPOINTS from '../../../endpoints';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginTop: 12,
    marginLeft: -12,
  },
}));

export const TeamSettingsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { teamId } = useParams();
  const updateTeamState = useRequest();
  const deleteTeamState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const enumSportState = useFetchRequest(ENDPOINTS.enumSports());
  const teamState = useFetchRequest(ENDPOINTS.getTeamDetail(teamId));

  const errorList = [
    { id: 1, error: teamState.error },
    { id: 2, error: enumSportState.error },
  ];

  const {
    object,
    requiredString,
    string,
    date,
    requiredNumber,
  } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    ({ teamId, name, description, sport, startDate, endDate, leaderId }) => {
      updateTeamState.request(ENDPOINTS.updateTeamSettings(), {
        method: 'PUT',
        onSuccess: () => {
          enqueueSnackbar(t('Page.Team.TeamSettings.TeamUpdated'), {
            variant: 'success',
          });
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: {
          teamId,
          name,
          description,
          sport,
          startDate,
          endDate,
          leaderId,
        },
      });
    },
    [updateTeamState, enqueueSnackbar, t],
  );

  const schema = object({
    name: requiredString,
    description: string,
    sport: requiredNumber,
    startDate: date,
    endDate: date,
  });

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <Button onClick={() => history.push(PATHNAMES.getTeamDetail(teamId))}>
            <ArrowBackIosIcon fontSize="small" />
            {t('Page.Team.TeamMemberDetail.Back')}
          </Button>
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Team.TeamSettings.TeamSettingsTitle')}
            </Typography>
          </div>
          {teamState.isLoading && (
            <CircularProgress className={classes.buttonProgress} />
          )}
          {teamState.data && enumSportState.data && (
            <>
              <Grid container spacing={2} className="flex-stretch">
                {teamState.data.dbTeamResponse && (
                  <>
                    <Grid item lg={6} md={12}>
                      <Card elevation={3} className="p-20 mb-12 h-100">
                        <div className="card-title px-24 mb-12">
                          {t('Page.Settings.BaseSettings.BaseSettingsTitle')}
                        </div>
                        <Formik
                          initialValues={{
                            teamId: teamId,
                            name: teamState.data.dbTeamResponse.name,
                            description:
                              teamState.data.dbTeamResponse.description,
                            sport: teamState.data.dbTeamResponse.sport.sport_id,
                            startDate: teamState.data.dbTeamResponse.start_date,
                            endDate: teamState.data.dbTeamResponse.end_date,
                            leaderId: teamState.data.dbTeamResponse.leader_id,
                          }}
                          validationSchema={schema}
                          onSubmit={onSubmitMemoized}
                        >
                          {({ setFieldValue, values }) => (
                            <Form className={classes.form}>
                              <Grid container spacing={2}>
                                <Grid item md={7} sm={12}>
                                  <Field
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="text"
                                    id="name"
                                    label={t('Page.Team.NewTeamForm.Name')}
                                    placeholder="Zadej název týmu"
                                    name="name"
                                  />
                                </Grid>
                                <Grid item md={5} sm={12}>
                                  <Field
                                    variant="outlined"
                                    required
                                    fullWidth
                                    select
                                    id="sport"
                                    label={t('Organisms.ActivityForm.Sport')}
                                    name="sport"
                                    data={enumSportState.data.sports}
                                  />
                                </Grid>
                                <Field
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  type="text"
                                  id="description"
                                  label={t('Page.Team.NewTeamForm.Description')}
                                  placeholder="Zadej popis týmu"
                                  name="description"
                                />
                                <Grid item md={6} sm={12}>
                                  <DatePickerField
                                    fullWidth
                                    required
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    animateYearScrolling={true}
                                    showTodayButton
                                    label={t('Page.Team.NewTeamForm.StartDate')}
                                    inputVariant="outlined"
                                    format="dd.MM.yyyy"
                                    placeholder="dd.mm.rrrr"
                                    value={values.startDate}
                                    onChange={(value) =>
                                      setFieldValue('startDate', value)
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                  <DatePickerField
                                    fullWidth
                                    required
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    animateYearScrolling={true}
                                    showTodayButton
                                    label={t('Page.Team.NewTeamForm.EndDate')}
                                    inputVariant="outlined"
                                    format="dd.MM.yyyy"
                                    placeholder="dd.mm.rrrr"
                                    disablePast
                                    value={values.endDate}
                                    onChange={(value) =>
                                      setFieldValue('endDate', value)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={updateTeamState.isLoading}
                                  >
                                    {t(
                                      'Page.Team.TeamSettings.SaveConfiguration',
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </Card>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <TeamInvitation
                        teamId={teamId}
                        data={teamState.data.notAcceptedInvitation}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TeamMemberSettings teamId={teamId} />
                    </Grid>
                    <Grid item xs={12}>
                      <Alert variant="filled" severity="error">
                        {t('Page.Team.TeamSettings.DangerousZone')}
                        <SimpleAlertDialog
                          title={t('Page.Team.TeamSettings.DeleteTeamConfirm')}
                          message={
                            t('Page.Team.TeamSettings.DeleteTeamMessage') +
                            ' ' +
                            teamState.data.dbTeamResponse.name
                          }
                          handleConfirm={() =>
                            deleteTeamState.request(ENDPOINTS.deleteTeam(), {
                              method: 'DELETE',
                              onSuccess: () => {
                                history.push(PATHNAMES.team());
                                enqueueSnackbar(
                                  t('Page.Team.TeamSettings.DeletedTeamAlert'),
                                  {
                                    variant: 'success',
                                  },
                                );
                              },
                              onError: (err) => {
                                enqueueSnackbar('Uh Oh, ' + err, {
                                  variant: 'error',
                                });
                              },
                              data: {
                                teamId: teamId,
                              },
                            })
                          }
                        >
                          {t('Page.Team.TeamSettings.DeleteTeam')}
                        </SimpleAlertDialog>
                      </Alert>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
