import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper } from '@material-ui/core';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import csCZ from 'date-fns/locale/cs';

import { ErrorBox } from '../../../atoms';
import { useFetchRequest } from '../../../utils';

import ENDPOINTS from '../../../endpoints';
import PATHNAMES from '../../../pathnames';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
}));

const locales = {
  cs: csCZ,
  'en-US': enUS,
};

const VIEWS = ['month', 'week', 'day', 'agenda'];

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Event = ({ event }) => {
  return (
    <span>
      <strong>{event.title}</strong>
      <p>{event.desc && event.desc}</p>
    </span>
  );
};

export const AthleteCalendar = ({ teamId, userId, t }) => {
  const history = useHistory();
  const classes = useStyles();

  const calendarEventState = useFetchRequest(
    ENDPOINTS.getTeamAthleteCalendar(teamId, userId),
  );

  const location = useLocation();
  const view = new URLSearchParams(location.search).get('view');
  const date = new URLSearchParams(location.search).get('date');
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  const isValidDate = isValid(parsedDate);
  const [searchState, setSearchState] = useState({
    view: VIEWS.includes(view) ? view : 'month',
    defaultView: 'month',
    date: isValidDate
      ? format(parsedDate, 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
  });

  const errorList = [
    {
      id: 1,
      error: calendarEventState.error,
    },
  ];

  const mapToRBCFormat = (event) =>
    Object.assign({}, event, {
      start: new Date(event.start),
      end: new Date(event.end),
    });

  const eventStyleGetter = (event) => {
    let backgroundColor;
    if (event.hexColor) {
      backgroundColor = '#' + event.hexColor;
    }
    const style = {
      backgroundColor: backgroundColor,
      display: 'block',
    };
    return {
      style: style,
    };
  };

  const eventSelectGetter = (event) => {
    if (event.type === 'activity') {
      return history.push(PATHNAMES.getActivityDetail(event.id));
    }
    if (event.type === 'sickness') {
      return history.push(PATHNAMES.getSicknessDetail(event.id));
    }
    if (event.type === 'regeneration') {
      return history.push(PATHNAMES.getRegenerationDetail(event.id));
    }
  };

  const handleViewChange = useCallback(
    (currentView) => {
      setSearchState((prevState) => ({ ...prevState, view: currentView }));
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('view', currentView);
      history.push({
        search: currentUrlParams.toString(),
      });
    },
    [setSearchState, history, location],
  );

  const handleDateChange = useCallback(
    (currentDate) => {
      setSearchState((prevState) => ({
        ...prevState,
        date: format(currentDate, 'yyyy-MM-dd'),
      }));
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('date', format(currentDate, 'yyyy-MM-dd'));
      history.push({
        search: currentUrlParams.toString(),
      });
    },
    [setSearchState, history, location],
  );

  return (
    <Paper className="p-20">
      <ErrorBox errorList={errorList} />
      {calendarEventState.isLoading && (
        <CircularProgress className={classes.progress} />
      )}
      {calendarEventState.data && (
        <Calendar
          culture="cs"
          localizer={localizer}
          events={calendarEventState.data.activityList.map(mapToRBCFormat)}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          onSelectEvent={eventSelectGetter}
          eventPropGetter={eventStyleGetter}
          defaultView={searchState.defaultView}
          view={searchState.view}
          onView={handleViewChange}
          date={new Date(searchState.date)}
          onNavigate={handleDateChange}
          components={{
            event: Event,
          }}
        />
      )}
    </Paper>
  );
};
