import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../atoms';
import { CopyrightWithBox, LayoutedTeam } from '../../molecules';

import { useAuth, useFetchRequest, useRequest } from '../../utils';
import ENDPOINTS from '../../endpoints';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const JoinToTeamPage = () => {
  const { t } = useTranslation();
  const { teamHash } = useParams();
  const { signin } = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const teamDetailState = useFetchRequest(
    ENDPOINTS.getJoinToTeamDetail(teamHash),
  );

  const joinToTeamState = useRequest();

  const onJoinToTeamMemoized = useCallback(() => {
    joinToTeamState.request(ENDPOINTS.joinToTeam(), {
      method: 'PUT',
      onSuccess: ({
        data: {
          results: {
            data: { userIsRegistered, token, user, privileges, email },
          },
        },
      }) => {
        if (userIsRegistered) {
          signin({ token, user, privileges });
          enqueueSnackbar(t('Page.JoinToTeam.JoinTeamAlert'), {
            variant: 'success',
          });
          history.push(PATHNAMES.dashboard());
          return;
        }

        history.push(PATHNAMES.getSignUpWithPrefilledEmail(email));
      },
      data: { teamHash },
    });
  }, [teamHash, history, signin, joinToTeamState, enqueueSnackbar, t]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <GroupWorkIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="mb-12">
          {t('Page.JoinToTeam.JoinTeamTitle')}
        </Typography>
        <ErrorBox errorList={[{ id: 1, error: teamDetailState.error }]} />
        {teamDetailState.isLoading && <CircularProgress />}
        {teamDetailState.data && (
          <>
            {!teamDetailState.data.userIsRegistered && (
              <Alert className="mx-16 my-8" severity="info">
                {t('Page.JoinToTeam.UserNotRegistered')}
              </Alert>
            )}
            <LayoutedTeam
              {...teamDetailState.data}
              footer={
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disabled={joinToTeamState.isLoading}
                  onClick={onJoinToTeamMemoized}
                >
                  {t('Page.JoinToTeam.JoinButton')}
                </Button>
              }
            />
          </>
        )}
      </div>
      <CopyrightWithBox />
    </Container>
  );
};
