import React, { useState, useCallback, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ErrorBox } from '../../atoms';
import { Field } from '../../organisms';
import { useRequest, translatedValidations } from '../../utils';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const AddSicknessAttachment = ({
  sicknessId,
  setSicknessAttachments,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputField = useRef(null);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();
  const storeAttachmentState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const { object, fileRequiredPdf } = translatedValidations(t);

  const onSubmitMemoized = useCallback(
    (data, { resetForm }) => {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) =>
        formData.append(key, value),
      );
      storeAttachmentState.request(ENDPOINTS.addAttachmentToSickness(), {
        method: 'POST',
        onSuccess: ({
          data: {
            results: { data },
          },
        }) => {
          handleClose();
          enqueueSnackbar(
            t('Page.Sickness.SicknessDetailPage.AddedAttachmentAlet'),
            {
              variant: 'success',
            },
          );
          resetForm();
          setSicknessAttachments((prevState) => ({
            data: [
              ...prevState.data,
              {
                attach_id: data.attach_id,
                name: data.name,
                created_at: data.created_at,
              },
            ],
          }));
        },
        onError: (err) => {
          enqueueSnackbar('Uh Oh, ' + err, {
            variant: 'error',
          });
        },
        data: formData,
      });
    },
    [
      storeAttachmentState,
      handleClose,
      enqueueSnackbar,
      t,
      setSicknessAttachments,
    ],
  );

  const schema = object({
    attachment: fileRequiredPdf,
  });

  return (
    <>
      <div className="ml-8">
        <Button
          className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-4 py-2 MuiButton-containedPrimary"
          color="primary"
          onClick={handleClickOpen}
        >
          {t('Page.Sickness.SicknessDetailPage.AddAttachment')}
        </Button>
      </div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('Page.Sickness.SicknessDetailPage.AddAttachment')}
        </DialogTitle>
        <Formik
          initialValues={{
            sicknessId: sicknessId,
            attachment: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmitMemoized}
        >
          {({ setFieldValue }) => (
            <Form className={classes.form}>
              <DialogContent>
                <ErrorBox
                  errorList={[{ id: 1, error: storeAttachmentState.error }]}
                />
                <DialogContentText>
                  {t(
                    'Page.Sickness.SicknessDetailPage.AddAttachmentDescription',
                  )}
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      id="attachment"
                      type="file"
                      name="attachment"
                      accept=".csv"
                      onChange={(event) => {
                        const [file] = event.currentTarget.files;
                        setFieldValue('attachment', file);
                      }}
                      inputRef={fileInputField}
                      value={undefined}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('Page.Team.AddMemberToTeam.Cancel')}
                </Button>
                <Button
                  type="submit"
                  disabled={storeAttachmentState.isLoading}
                  color="primary"
                  autoFocus
                >
                  {t('Page.Sickness.SicknessDetailPage.Save')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
