import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PATHNAMES from '../../../pathnames';
import ENDPOINTS from '../../../endpoints';
import { ErrorBox } from '../../../atoms';
import { CopyrightWithBox } from '../../../molecules';
import { Field } from '../../../organisms';
import {
  useAuth,
  useRequest,
  useFetchRequest,
  translatedValidations,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const ActivateUserPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userHash } = useParams();
  const { signin } = useAuth();
  const history = useHistory();
  const activateUserState = useRequest();

  const activateUserOnSuccess = useCallback(
    ({
      data: {
        results: {
          data: { user, token, privileges },
        },
      },
    }) => {
      signin({ user, token, privileges });
      history.push(PATHNAMES.dashboard());
    },
    [signin, history],
  );

  const sportState = useFetchRequest(ENDPOINTS.enumSports());

  const onSubmitMemoized = useCallback(
    ({ nickname, regNum, primarySport }) => {
      activateUserState.request(ENDPOINTS.activateUser(), {
        method: 'PUT',
        onSuccess: activateUserOnSuccess,
        data: { nickname, regNum, primarySport, userHash },
      });
    },
    [activateUserState, activateUserOnSuccess, userHash],
  );

  const { object, requiredString, requiredCsosRegNum } = translatedValidations(
    t,
  );

  const schema = object({
    nickname: requiredString,
    regNum: requiredCsosRegNum,
    primarySport: requiredString,
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Page.Auth.ActivateUser.ActivateUser')}
        </Typography>
        <ErrorBox errorList={[{ id: 1, error: activateUserState.error }]} />
        {sportState.isLoading && <CircularProgress />}
        {sportState.data && (
          <Paper className="mt-8 p-20">
            <Formik
              initialValues={{
                nickname: '',
                regNum: '',
                primarySport: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmitMemoized}
            >
              {() => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        fullWidth
                        required
                        id="nickname"
                        label={t('User.Nickname')}
                        name="nickname"
                        autoComplete="nickname"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        id="regNum"
                        label={t('User.CsosRegistration')}
                        name="regNum"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        variant="outlined"
                        required
                        fullWidth
                        select
                        id="primarySport"
                        data={sportState.data.sports}
                        name="primarySport"
                        label={t('Page.Auth.ActivateUser.PrimarySport')}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={activateUserState.isLoading}
                  >
                    {t('Page.Auth.ActivateUser.ActivateAccount')}
                  </Button>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Link href={PATHNAMES.signIn()} variant="body2">
                        {t('Page.Auth.SignUp.HaveAnAccount')}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        )}
      </div>
      <CopyrightWithBox />
    </Container>
  );
};
