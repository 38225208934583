import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { LoggedInPageLayout } from '../../templates';
import PATHNAMES from '../../pathnames';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '40vh',
  },
  media: {
    height: '11vh',
    margin: theme.spacing(2, 0),
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const UploadPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const onStravaClick = () => {
    history.push(PATHNAMES.stravaGuide());
  };

  const errorList = [];

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {t('Page.Upload.UploadPage.SyncActivityTitle')}
            </Typography>
          </div>
          <Typography>
            Tréninkáč je propojený se všemi běžnými sportovními hodinkami a
            měřícími přístroji.
          </Typography>
          <Box mt={5} mb={5} className="w-full text-center">
            <h3 className="m-5 font-medium">Vyber službu, kterou používáš</h3>
            <p className="m-0 pt-4 text-muted">
              Aktuálně podporujeme pouze sociální síť STRAVA
            </p>
          </Box>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardActionArea onClick={onStravaClick}>
                  <CardMedia
                    component="img"
                    alt="Connect with STRAVA"
                    height="140"
                    className={classes.media}
                    image="/assets/images/logos/btn_strava_connectwith_orange.svg"
                    title="Connect with STRAVA"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Strava
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Strava je webová stránka a mobilní aplikace pro měření
                      atletické aktivity přes GPS. Má sídlo v americkém San
                      Franciscu. Nazývá se „sociálních sítí atletů“. Nejčastěji
                      pomocí ní sportovci měří výkony při cyklistice a běhání.
                      Firma byla založena v roce 2009. Aplikace je dostupná pro
                      Android a iOS v angličtině a mnoha dalších jazycích.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
