export const users = [
  {
    id: 1,
    email: 'Young Gatchell',
  },
  {
    id: 2,
    email: 'Gatchell Young',
  },
  {
    id: 3,
    email: 'John Doe',
  },
  {
    id: 4,
    email: 'Jane Roe',
  },
];

export const sports = [
  {
    id: 1,
    name: 'Orienteering',
  },
];

export const unidentifiedActivity = [
  {
    message: 'OK',
    error: false,
    code: 200,
    results: {
      data: [
        {
          activity_id: 561,
          name:
            'Zem se otřásala, padaly trakaře a světlo někde hodně daleko za obzorem. 🤷🌧️🌈',
          sport: 'run',
          distance: 10814.9,
          elapsed_time: 3720,
          elevation_gain: 204,
          start_date: '2020-07-02T17:30:41.000Z',
          pace: null,
          description:
            'Bylo to takový hodně divný. Svalek při pohybu je nepříjemný. Celou dobu jsem si vzpomínal, co je to běhání. Ale bylo to dobré zchlazení vlahým lesem, stromů po bouřce popadaných v údolí také dost. Nakonec byla i duha. 😊 A Metuje je po deštích sjízdná .. teče to slušně!',
          athlete: {
            user_id: 1,
          },
        },
        {
          activity_id: 562,
          name: 'JRD VT Liberecko || Middle -> Mňamíí',
          sport: 'run',
          distance: 10582.5,
          elapsed_time: 11652,
          elevation_gain: 156,
          start_date: '2020-07-27T10:37:09.000Z',
          pace: null,
          description: '.. když nemůžeš do Švédska, Švédsko přijde za tebou ',
          athlete: {
            user_id: 1,
          },
        },
        {
          activity_id: 563,
          name: 'Basic endurance || Jakuszyce -> Harrachov s Márou 🤷',
          sport: 'run',
          distance: 20037.1,
          elapsed_time: 10372,
          elevation_gain: 636,
          start_date: '2020-07-27T14:08:16.000Z',
          pace: null,
          description: null,
          athlete: {
            user_id: 1,
          },
        },
        {
          activity_id: 564,
          name: 'JRD VT Liberecko || sprint + roznos, sběr',
          sport: 'run',
          distance: 6491.7,
          elapsed_time: 6263,
          elevation_gain: 67,
          start_date: '2020-07-28T15:14:52.000Z',
          pace: null,
          description: 'minuta ticha za koťátko 🙀',
          athlete: {
            user_id: 1,
          },
        },
        {
          activity_id: 567,
          name: 'JRD VT Liberecko || Vrstevnicovka',
          sport: 'run',
          distance: 6085.2,
          elapsed_time: 8624,
          elevation_gain: 145,
          start_date: '2020-07-30T10:15:56.000Z',
          pace: null,
          description: '🐩🐩 ... povenčit se',
          athlete: {
            user_id: 1,
          },
        },
        {
          activity_id: 571,
          name: 'TrailTour #44 / Hamr',
          sport: 'run',
          distance: 7974,
          elapsed_time: 3253,
          elevation_gain: 148,
          start_date: '2020-08-03T11:16:45.000Z',
          pace: null,
          description: 'Welcome back to the game!',
          athlete: {
            user_id: 1,
          },
        },
      ],
    },
  },
];

export const mocks = {
  users,
  unidentifiedActivity,
};
