import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';

import { ErrorBox, TableIcons, IconWithTooltip } from '../../atoms';
import { useFetchRequest, useRequest, formatDate } from '../../utils';
import ENDPOINTS from '../../endpoints';

import { NewHrZoneForm } from './NewHrZoneForm';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
}));

export const ZoneSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const deleteHeartZoneState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [heartRateZoneState, setHeartRateZonesState] = useState(undefined);

  const onSuccessFetchHeartZonesState = (response) => {
    updateHeartRateZoneState(response);
  };

  const fetchHeartZonesState = useFetchRequest(
    ENDPOINTS.getHeartZonesSettings(),
    {
      onSuccess: onSuccessFetchHeartZonesState,
    },
  );

  const updateHeartRateZoneState = useCallback(
    ({
      data: {
        results: { data },
      },
    }) => {
      setHeartRateZonesState({
        data,
      });
    },
    [],
  );

  const handleRowUpdate = (newData, oldData, resolve) => {
    console.log(oldData);
    deleteHeartZoneState.request(ENDPOINTS.updateHeartZoneSettings(), {
      method: 'PUT',
      onSuccess: () => {
        enqueueSnackbar(t('Page.Settings.ZoneSettings.ZoneSettingsUpdated'), {
          variant: 'success',
        });
        resolve();
      },
      data: {
        zoneId: oldData.zone_id,
        zone1: newData.zone_1,
        zone2: newData.zone_2,
        zone3: newData.zone_3,
        zone4: newData.zone_4,
        zone5: newData.zone_5,
        maxHr: newData.max_hr,
      },
    });
  };
  const handleRowDelete = (oldData, resolve) => {
    console.log(oldData);
    deleteHeartZoneState.request(ENDPOINTS.deleteHeartZoneSettings(), {
      method: 'DELETE',
      onSuccess: () => {
        enqueueSnackbar(t('Page.Settings.ZoneSettings.ZoneSettingsDeleted'), {
          variant: 'success',
        });
        setHeartRateZonesState({
          data: heartRateZoneState.data.filter(
            (item) => item.zone_id !== oldData.zone_id,
          ),
        });
        resolve();
      },
      data: {
        zoneId: oldData.zone_id,
      },
    });
  };

  const columns = [
    { title: 'ID', field: 'zone_id', hidden: true },
    {
      title: t('Organisms.WeekSummaryTable.Zone1'),
      field: 'zone_1',
      type: 'numeric',
    },
    {
      title: t('Organisms.WeekSummaryTable.Zone2'),
      field: 'zone_2',
      type: 'numeric',
    },
    {
      title: t('Organisms.WeekSummaryTable.Zone3'),
      field: 'zone_3',
      type: 'numeric',
    },
    {
      title: t('Organisms.WeekSummaryTable.Zone4'),
      field: 'zone_4',
      type: 'numeric',
    },
    {
      title: t('Organisms.WeekSummaryTable.Zone5'),
      field: 'zone_5',
      type: 'numeric',
    },
    { title: 'Max HR', field: 'max_hr', type: 'numeric' },
    {
      title: t('Page.Settings.ZoneSettings.ValidFrom'),
      field: 'created_at',
      editable: 'never',
      render: (rowData) => formatDate(rowData.created_at),
    },
  ];

  return (
    <Card elevation={3} className="p-20 mb-12">
      <div className="card-title px-24">
        {t('Page.Settings.ZoneSettings.ZoneSettingsTitle')}
        <IconWithTooltip
          helperText={t('Page.Settings.ZoneSettings.ZoneSettingsDescription')}
        />
      </div>
      <ErrorBox errorList={[{ id: 1, error: fetchHeartZonesState.error }]} />
      <div className={classes.buttons}>
        <NewHrZoneForm setHeartRateZonesState={setHeartRateZonesState} />
      </div>
      {fetchHeartZonesState.isLoading && (
        <CircularProgress className={classes.progress} />
      )}
      {typeof heartRateZoneState !== 'undefined' && heartRateZoneState.data && (
        <MaterialTable
          title={t('Page.Settings.ZoneSettings.HeartRateZones')}
          columns={columns}
          data={heartRateZoneState.data}
          icons={TableIcons}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                handleRowUpdate(newData, oldData, resolve);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                handleRowDelete(oldData, resolve);
              }),
          }}
          options={{
            actionsColumnIndex: -1,
          }}
          localization={{
            header: {
              actions: t('DataTable.Actions'),
            },
            body: {
              deleteTooltip: t('DataTable.Delete'),
              editTooltip: t('DataTable.Edit'),
              editRow: {
                deleteText: t('DataTable.DeleteText'),
                cancelTooltip: t('DataTable.CancelTooltip'),
                saveTooltip: t('DataTable.SaveTooltip'),
              },
              toolbar: {
                searchTooltip: t('DataTable.SearchTooltip'),
                searchPlaceholder: t('DataTable.SearchPlaceholder'),
              },
              filterRow: {
                filterTooltip: 'Filtr',
              },
            },
          }}
        />
      )}
    </Card>
  );
};
