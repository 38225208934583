import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuButton } from '../atoms';
import { MenuItem } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import theme from 'theme';
import { useAuth } from '../utils';
import { Link } from 'react-router-dom';
import PATHNAMES from '../pathnames';

export const Layout1Topbar = ({ history, handleSidebarToggle }) => {
  const { signout, avatar } = useAuth();
  const { t } = useTranslation();
  const logoutCallback = useCallback(() => {
    signout();
    history.push(PATHNAMES.signIn());
  }, [history, signout]);

  const imgAvatarSource = avatar
    ? `data:image/jpeg;base64,${avatar}`
    : '/assets/images/user-1.png';
  return (
    <div className="topbar">
      <div
        className={`topbar-hold fixed`}
        style={Object.assign(
          {},
          { backgroundColor: theme.palette.primary.main },
        )}
      >
        <div className="flex flex-space-between flex-middle h-100">
          <div className="flex">
            <IconButton onClick={handleSidebarToggle} className="hide-on-lg">
              <MenuIcon />
            </IconButton>
          </div>
          <div className="flex flex-middle">
            <MenuButton
              menuButton={
                <img
                  className="mx-8 text-middle circular-image-small cursor-pointer"
                  src={imgAvatarSource}
                  alt="user"
                />
              }
            >
              <MenuItem style={{ minWidth: 185 }}>
                <Link className="flex flex-middle" to={PATHNAMES.dashboard()}>
                  <DashboardIcon />
                  <span className="pl-16"> Dashboard </span>
                </Link>
              </MenuItem>
              <MenuItem style={{ minWidth: 185 }}>
                <Link className="flex flex-middle" to={PATHNAMES.settings()}>
                  <SettingsIcon />
                  <span className="pl-16">
                    {t('Organisms.LayoutSidenav.Settings')}
                  </span>
                </Link>
              </MenuItem>
              <MenuItem className="flex flex-middle" style={{ minWidth: 185 }}>
                <ExitToAppIcon />
                <span className="pl-16" onClick={logoutCallback}>
                  {t('Organisms.LayoutSidenav.SignOut')}
                </span>
              </MenuItem>
            </MenuButton>
          </div>
        </div>
      </div>
    </div>
  );
};
