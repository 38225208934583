import React from 'react';
import { Helmet } from 'react-helmet';
import theme from 'theme';
import templateSettings from './templateSettings';

const SidenavTheme = () => {
  function darkHoverStyle() {
    return theme.palette.type === 'dark'
      ? `.navigation .nav-item:hover,
        .navigation .nav-item.active {
          color: ${theme.palette.text.primary};
        }`
      : '';
  }

  function lightHoverStyle() {
    return theme.palette.type === 'light'
      ? `.navigation .nav-item:hover,
        .navigation .nav-item.active,
        .navigation .submenu {
          background: rgba(0, 0, 0, .08);
        }`
      : '';
  }

  return (
    <Helmet>
      <style>
        {`
        
        ${
          theme.palette.type === 'dark'
            ? `.sidenav {
          color: ${theme.palette.text.secondary};
        }`
            : ' '
        }

        .sidenav__hold {
          background-image: url(${templateSettings.leftSidebar.bgImgURL});
          opacity: 1 !important;
        }
        .sidenav__hold::after {
          background: ${templateSettings.leftSidebar.background};
          opacity: ${templateSettings.leftSidebar.bgOpacity};
        }
        .navigation .nav-item:not(.badge) {
          color: ${templateSettings.leftSidebar.color};
        }
        .navigation .nav-item .icon-text::after {
          background: ${theme.palette.text.primary};
        }
        .navigation .nav-item.active, 
        .navigation .nav-item.active:hover {
          background: ${theme.palette.secondary.main};
        }

        
        ${darkHoverStyle()}
        
      `}
      </style>
    </Helmet>
  );
};

export default SidenavTheme;
