import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useAuth, useRequest, translatedValidations } from '../../../utils';
import { ErrorBox } from '../../../atoms';
import { Field } from '../../../organisms';
import ENDPOINTS from '../../../endpoints';
import PATHNAMES from '../../../pathnames';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const PasswordResetConfirmationPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userHash } = useParams();
  const history = useHistory();
  const { signin } = useAuth();

  const passwordResetConfirmationState = useRequest();

  const onSubmitMemoized = useCallback(
    ({ password, passwordConfirmation }) => {
      passwordResetConfirmationState.request(
        ENDPOINTS.resetPasswordConfirmation(),
        {
          method: 'PUT',
          onSuccess: ({ data: { user, token, privileges } }) => {
            signin({ user, token, privileges });
            history.push(PATHNAMES.dashboard());
          },
          data: { password, passwordConfirmation, userHash },
        },
      );
    },
    [passwordResetConfirmationState, userHash, history, signin],
  );

  const { object, passwordsDontMatch, requiredString } = translatedValidations(
    t,
  );

  const schema = object({
    password: requiredString,
    passwordConfirmation: passwordsDontMatch('password'),
  });

  const errorList = [{ id: 1, error: passwordResetConfirmationState.error }];

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Page.Auth.PasswordReset.FormHeading')}
        </Typography>
        <ErrorBox errorList={errorList} />
        <Paper className="mt-8 p-20">
          <Formik
            initialValues={{ password: '', passwordConfirmation: '' }}
            validationSchema={schema}
            onSubmit={onSubmitMemoized}
          >
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t('Page.Auth.SignUp.Password')}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    name="passwordConfirmation"
                    label={t('Page.Auth.SignUp.PasswordConfirmation')}
                    type="password"
                    id="passwordConfirmation"
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={passwordResetConfirmationState.isLoading}
              >
                {t(
                  'Page.Auth.PasswordReset.PasswordResetConfirmation.SubmitResetPassword',
                )}
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href={PATHNAMES.signIn()} variant="body2">
                    {t('Page.Auth.SignUp.HaveAnAccount')}
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Paper>
      </div>
    </Container>
  );
};
