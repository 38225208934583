import React, { useState } from 'react';
import { Paper, MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { TableIcons } from '../../../atoms';
import { useRequest } from '../../../utils';
import ENDPOINTS from '../../../endpoints';

export const TeamInvitation = ({ teamId, data }) => {
  const { t } = useTranslation();

  const deleteInvitationState = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [teamInvitationState, setTeamInvitationState] = useState({
    data: data,
  });

  const handleRowDelete = (oldData, resolve) => {
    deleteInvitationState.request(ENDPOINTS.deleteTeamInvitation(teamId), {
      method: 'DELETE',
      onSuccess: () => {
        enqueueSnackbar(t('Page.Team.TeamSettings.InvitationDeleted'), {
          variant: 'success',
        });
        setTeamInvitationState({
          data: teamInvitationState.data.filter(
            (item) => item.invitationId !== oldData.invitationId,
          ),
        });
        resolve();
      },
      onError: (err) => {
        enqueueSnackbar('Uh Oh, ' + err, {
          variant: 'error',
        });
      },
      data: {
        invitationId: oldData.invitationId,
      },
    });
  };

  const [filtering, setFiltering] = useState(false);

  const columns = [
    {
      title: 'ID',
      width: 10,
      field: 'invitationId',
      editable: 'never',
      hidden: true,
    },
    {
      title: t('User.Email'),
      field: 'email',
      type: 'string',
      width: 50,
      editable: 'never',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  ];

  return (
    <Paper className="h-100">
      {typeof teamInvitationState !== 'undefined' && teamInvitationState.data && (
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            title={t('Page.Team.TeamSettings.TeamInvitation')}
            columns={columns}
            data={teamInvitationState.data}
            icons={TableIcons}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData, resolve);
                }),
            }}
            options={{
              actionsColumnIndex: -1,
              tableLayout: 'auto',
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50, 100],
              filtering: filtering,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return {
                    backgroundColor: '#f2f2f2',
                  };
                }
              },
            }}
            actions={[
              {
                icon: () => <FilterList />,
                tooltip: t('DataTable.EnableFilter'),
                isFreeAction: true,
                onClick: () => setFiltering(!filtering),
              },
            ]}
            localization={{
              header: {
                actions: t('DataTable.Actions'),
              },
              body: {
                deleteTooltip: t('DataTable.Delete'),
                editTooltip: t('DataTable.Edit'),
                editRow: {
                  deleteText: t('DataTable.DeleteText'),
                  cancelTooltip: t('DataTable.CancelTooltip'),
                  saveTooltip: t('DataTable.SaveTooltip'),
                },
                emptyDataSourceMessage: t('DataTable.EmptyDataSource'),
                toolbar: {
                  searchTooltip: t('DataTable.SearchTooltip'),
                  searchPlaceholder: t('DataTable.SearchPlaceholder'),
                  nRowsSelected: '{0} řádků vybráno',
                },
                filterRow: {
                  filterTooltip: t('DataTable.Filter'),
                },
              },
            }}
          />
        </MuiThemeProvider>
      )}
    </Paper>
  );
};
