import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Scrollbar from 'react-perfect-scrollbar';
import { VerticalNav } from './VerticalNav';
import Link from '@material-ui/core/Link';
import TouchRipple from '@material-ui/core/ButtonBase';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(() => ({
  help: {
    position: 'fixed',
    bottom: '-25px',
  },
}));

export const Sidenav = ({ privileges, children }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Scrollbar
        options={{ suppressScrollX: true }}
        className="scrollable position-relative"
      >
        {children}
        <div className="navigation">
          <VerticalNav privileges={privileges} />
          <div className={classes.help}>
            <div className="pr-20">
              <Link
                href="https://treninkac.orientacnisporty.cz/napoveda"
                color="inherit"
                key="help"
                className="nav-item"
                target="_blank"
              >
                <TouchRipple key="Mail" name="child" className="w-100">
                  <HelpOutlineIcon />
                  <span className="text-middle pl-20 item-text">Nápověda</span>
                  <div className="mx-auto"></div>
                </TouchRipple>
              </Link>
              <Link
                href="mailto:treninkac@orientacnisporty.cz"
                color="inherit"
                key="mail"
                className="nav-item top-20"
              >
                <p className="text-small text-middle pl-20 item-text">
                  treninkac@orientacnisporty.cz
                </p>
              </Link>
            </div>
          </div>
        </div>
      </Scrollbar>
    </Fragment>
  );
};
