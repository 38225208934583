import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { formatDate } from '../utils';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export const LayoutedTeam = ({
  name,
  joinedUsers,
  description,
  start_date,
  end_date,
  leader,
  footer,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    // Enterprise card is full width at sm breakpoint
    <Card>
      <CardHeader
        title={name}
        subheader="private"
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={name === 'Pro' ? <StarIcon /> : null}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography component="h2" variant="h3" color="textPrimary">
            {joinedUsers}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            /{t('Molecules.Members')}
          </Typography>
        </div>
        <Typography variant="subtitle1" align="center">
          {description}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {formatDate(start_date)} - {formatDate(end_date)}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {leader.firstname} {leader.lastname}
        </Typography>
      </CardContent>
      <CardActions>{footer}</CardActions>
    </Card>
  );
};
