import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import MoreIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import csLocale from 'date-fns/locale/cs';

const localeMap = {
  en: enLocale,
  cs: csLocale,
};

export function DatePickerWithLabel({
  error,
  label,
  type,
  inputRef,
  ...inputProps
}) {
  const [locale, setLocale] = useState('cs');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, handleDateChange] = useState(new Date());

  const handleMenuOpen = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const selectLocale = useCallback((locale) => {
    setLocale(locale);
    setAnchorEl(null);
  }, []);

  return (
    <Grid item xs={12}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
        {type === 'date-time' ? (
          <KeyboardDateTimePicker
            value={selectedDate}
            onChange={handleDateChange}
            label={label}
            error={error ? true : undefined}
            helperText={error ? error : null}
            ref={inputRef}
            KeyboardButtonProps={{
              'aria-label': 'change date-time',
            }}
            {...inputProps}
          />
        ) : type === 'time' ? (
          <KeyboardTimePicker
            label={label}
            error={error ? true : undefined}
            helperText={error ? error : null}
            ref={inputRef}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            {...inputProps}
          />
        ) : (
          <>
            <KeyboardDatePicker
              value={selectedDate}
              onChange={handleDateChange}
              label={label}
              error={error ? true : undefined}
              helperText={error ? error : null}
              ref={inputRef}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              {...inputProps}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="Select locale"
                    onClick={handleMenuOpen}
                    aria-owns={anchorEl ? 'locale-menu' : undefined}
                  >
                    <MoreIcon />
                  </IconButton>
                ),
              }}
            />
            <Menu
              id="locale-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {Object.keys(localeMap).map((localeItem) => (
                <MenuItem
                  key={localeItem}
                  selected={localeItem === locale}
                  onClick={() => selectLocale(localeItem)}
                >
                  {localeItem}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
