import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { formatSecondsToTime, convertToKM } from '../utils';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
}));
export const WeekSummaryTable = ({ currentWeek, sumByWeek, t }) => {
  const classes = useStyles();
  return (
    <Card className="px-24 py-16">
      <div className="card-title">
        {t('Organisms.WeekSummaryTable.WeekSummary')}
      </div>
      <div className="card-subtitle">
        {t('Organisms.WeekSummaryTable.CurrentWeek')}
      </div>
      <TableContainer className="mb-12">
        <Table size="small" aria-label="week overview">
          <TableHead>
            <TableRow>
              <TableCell>{t('Organisms.WeekSummaryTable.DayLoad')}</TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.ActivityCount')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.RaceCount')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Regeneration')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.CycleSummaryTable.TimeLoad')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Distance')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {currentWeek.day_load}
              </TableCell>
              <TableCell align="right">{currentWeek.activity_count}</TableCell>
              <TableCell align="right">{currentWeek.race_count}</TableCell>
              <TableCell align="right">
                {currentWeek.regeneration_time &&
                  currentWeek.regeneration_time !== 0 &&
                  formatSecondsToTime(currentWeek.regeneration_time)}
              </TableCell>
              <TableCell align="right">
                {currentWeek.time_load &&
                  currentWeek.time_load !== 0 &&
                  formatSecondsToTime(currentWeek.time_load)}
              </TableCell>
              <TableCell align="right">
                {currentWeek.distance &&
                  convertToKM(currentWeek.distance) + ' km'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* TODO: show current week, cycle, race count, activity count, čas běhu, čas na mapě, počet dnů zátěže, regenerace */}
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="week summary by sport"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('Organisms.WeekSummaryTable.Sport')}</TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Time')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Distance')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.AvgHr')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Zone1')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Zone2')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Zone3')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Zone4')}
              </TableCell>
              <TableCell align="right">
                {t('Organisms.WeekSummaryTable.Zone5')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sumByWeek.map((row) => (
              <TableRow key={row.sport}>
                <TableCell component="th" scope="row">
                  {row.sport}
                </TableCell>
                <TableCell align="right">
                  {formatSecondsToTime(row.time)}
                </TableCell>
                <TableCell align="right">
                  {row.distance && convertToKM(row.distance) + ' km'}
                </TableCell>
                <TableCell align="right">
                  {row.hr && Math.round(row.hr) + ' bpm'}
                </TableCell>
                <TableCell align="right">
                  {row.zone1 &&
                    row.zone1 !== 0 &&
                    formatSecondsToTime(row.zone1)}
                </TableCell>
                <TableCell align="right">
                  {row.zone2 &&
                    row.zone2 !== 0 &&
                    formatSecondsToTime(row.zone2)}
                </TableCell>
                <TableCell align="right">
                  {row.zone3 &&
                    row.zone3 !== 0 &&
                    formatSecondsToTime(row.zone3)}
                </TableCell>
                <TableCell align="right">
                  {row.zone4 &&
                    row.zone4 !== 0 &&
                    formatSecondsToTime(row.zone4)}
                </TableCell>
                <TableCell align="right">
                  {row.zone5 &&
                    row.zone5 !== 0 &&
                    formatSecondsToTime(row.zone5)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow key="total">
              <TableCell component="th" scope="row">
                {t('Organisms.WeekSummaryTable.Totals')}
              </TableCell>
              <TableCell align="right">
                {formatSecondsToTime(getTotals(sumByWeek, 'time'))}
              </TableCell>
              <TableCell align="right">
                {convertToKM(getTotals(sumByWeek, 'distance')) + ' km'}
              </TableCell>
              <TableCell align="right">
                {Math.round(getAverage(sumByWeek, 'hr')) + ' bpm'}
              </TableCell>
              <TableCell align="right">
                {formatSecondsToTime(getTotals(sumByWeek, 'zone1'))}
              </TableCell>
              <TableCell align="right">
                {formatSecondsToTime(getTotals(sumByWeek, 'zone2'))}
              </TableCell>
              <TableCell align="right">
                {formatSecondsToTime(getTotals(sumByWeek, 'zone3'))}
              </TableCell>
              <TableCell align="right">
                {formatSecondsToTime(getTotals(sumByWeek, 'zone4'))}
              </TableCell>
              <TableCell align="right">
                {formatSecondsToTime(getTotals(sumByWeek, 'zone5'))}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  );
};

const getTotals = (data, key) => {
  let total = 0;
  data.forEach((item) => {
    total += item[key];
  });
  return total;
};

const getAverage = (data, key) => {
  let average = 0;
  let index = 0;
  data.forEach((item) => {
    if (item[key]) {
      average += item[key];
      index++;
    }
  });
  average = average / index;
  if (typeof average !== 'number' || !average) {
    average = 0;
  }
  return average;
};
