import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import DraftsIcon from '@material-ui/icons/Drafts';
import FaceIcon from '@material-ui/icons/Face';
import ClassIcon from '@material-ui/icons/Class';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SettingsIcon from '@material-ui/icons/Settings';
import { useFetchData } from './hooks';
import { TeamOverview } from './TeamOverview';
import { AddMemberToTeam } from './AddMemberToTeam';
import { SetCoachPrivileges } from './SetCoachPrivileges';
import { LoggedInPageLayout } from '../../../templates';
import { useAuth } from '../../../utils';
import PATHNAMES from '../../../pathnames';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

export const TeamDetailPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { teamId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const user = useAuth();

  const { teamState } = useFetchData(teamId);

  const errorList = [{ id: 1, error: teamState.error }];

  return (
    <LoggedInPageLayout errorList={errorList}>
      <div className="relative flex-grow">
        <div className="analytics m-sm-30">
          <Button onClick={() => history.push(PATHNAMES.team())}>
            <ArrowBackIosIcon fontSize="small" />
            {t('Page.Team.TeamDetail.AllTeams')}
          </Button>
          <div className="flex justify-between items-center items-center mb-6">
            <Typography className={classes.heading} variant="h3" component="h3">
              {teamState.data
                ? teamState.data.dbTeamResponse.name
                : t('Page.Team.TeamDetail.TeamDetailTitle')}
            </Typography>
          </div>
          <AddMemberToTeam teamId={teamId} />
          <SetCoachPrivileges teamId={teamId} />
          {teamState.data &&
            teamState.data.dbTeamResponse.leader_id === user.user.userId && (
              <Button
                className="float-right mr-30"
                onClick={() => history.push(PATHNAMES.getTeamSettings(teamId))}
              >
                <SettingsIcon fontSize="small" className="mr-4" />
                {t('Page.Team.TeamDetail.TeamSettings')}
              </Button>
            )}
          <div className="m-sm-30">
            {teamState.isLoading && <CircularProgress />}
            {teamState.data && (
              <>
                <Grid container spacing={2} className="flex-stretch">
                  <Grid item xs={12} sm={6} lg={3}>
                    <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
                      <IconButton aria-label="members">
                        <FaceIcon />
                      </IconButton>
                      <div className="ml-4">
                        <h3 className="mt-1 text-32">
                          {teamState.data.dbTeamResponse.leader.firstname}{' '}
                          {teamState.data.dbTeamResponse.leader.lastname}
                        </h3>
                        <p className="m-0 text-muted">
                          {t('Page.Team.TeamDetail.TeamOwner')}
                        </p>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
                      <IconButton aria-label="members">
                        <ClassIcon />
                      </IconButton>
                      <div className="ml-4">
                        <h3 className="mt-1 text-32">
                          {teamState.data.dbTeamResponse.sport.name}
                        </h3>
                        <p className="m-0 text-muted">Sport</p>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
                      <IconButton aria-label="members">
                        <PeopleIcon />
                      </IconButton>
                      <div className="ml-4">
                        <h3 className="mt-1 text-32">
                          {teamState.data.dbTeamResponse.joinedUsers}
                        </h3>
                        <p className="m-0 text-muted">
                          {t('Page.Team.TeamDetail.MembersCount')}
                        </p>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Card className="MuiPaper-root MuiCard-root p-20 flex MuiPaper-elevation3 MuiPaper-rounded h-100">
                      <IconButton aria-label="members">
                        <DraftsIcon />
                      </IconButton>
                      <div className="ml-4">
                        <h3 className="mt-1 text-32">
                          {teamState.data.notAcceptedInvitation.length}
                        </h3>
                        <p className="m-0 text-muted">
                          {t('Page.Team.TeamDetail.NotAcceptedCount')}
                        </p>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <TeamOverview
                  teamId={teamId}
                  history={history}
                  location={location}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </LoggedInPageLayout>
  );
};
