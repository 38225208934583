import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
export function AutoSuggestWithLabel({
  error,
  label,
  inputRef,
  options,
  filterSelectedOptions,
  onChange,
  ...inputProps
}) {
  return (
    <Grid item xs={12}>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.title || ''}
        filterSelectedOptions={filterSelectedOptions}
        onChange={onChange}
        ref={inputRef}
        {...inputProps}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={error ? true : undefined}
            helperText={error ? error : null}
            {...inputProps}
          />
        )}
      />
    </Grid>
  );
}
