import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Box,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Alert from '@material-ui/lab/Alert';

import { ErrorBox } from '../../atoms';
import { SimpleAlertDialog } from '../../molecules';
import { Field } from '../../organisms';
import {
  useFetchRequest,
  useRequest,
  translatedValidations,
} from '../../utils';
import PATHNAMES from '../../pathnames';
import ENDPOINTS from '../../endpoints';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginTop: 12,
    marginLeft: -12,
  },
  buttonGetIt: {
    position: 'relative',
    margin: '-10px auto 2px',
    display: 'table',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  image: {
    width: '100px',
    position: 'relative',
    left: '40%',
    marginLeft: -12,
  },
}));

export const AthleteSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { object, string, number } = translatedValidations(t);
  const { enqueueSnackbar } = useSnackbar();

  const settingsAthleteState = useFetchRequest(ENDPOINTS.getAthleteSettings());
  const updateAthleteSettingsState = useRequest();
  const disconnectStravaApp = useRequest();

  const onSubmitMemoized = useCallback(
    ({ weight, height, country }) => {
      updateAthleteSettingsState.request(ENDPOINTS.updateAthleteSettings(), {
        method: 'PUT',
        onSuccess: () => {
          enqueueSnackbar(
            t('Page.Settings.AthleteSettings.AthleteUpdatedAlert'),
            {
              variant: 'success',
            },
          );
        },
        data: {
          weight,
          height,
          country,
        },
      });
    },
    [updateAthleteSettingsState, enqueueSnackbar, t],
  );

  const schema = object({
    weight: number,
    height: number,
    country: string,
  });
  return (
    <Card elevation={3} className="p-20 h-100">
      <div className="card-title mb-12">
        {t('Page.Settings.AthleteSettings.AthleteSettingsTitle')}
      </div>
      <ErrorBox
        errorList={[
          { id: 1, error: settingsAthleteState.error },
          { id: 2, error: updateAthleteSettingsState.error },
        ]}
      />
      {settingsAthleteState.isLoading && (
        <CircularProgress className={classes.buttonProgress} />
      )}
      {settingsAthleteState.data ? (
        <>
          <Formik
            initialValues={{
              sex: settingsAthleteState.data.sex,
              weight: settingsAthleteState.data.weight
                ? settingsAthleteState.data.weight
                : '',
              height: settingsAthleteState.data.height
                ? settingsAthleteState.data.height
                : '',
              country: settingsAthleteState.data.country,
              birth: settingsAthleteState.data.birth,
            }}
            validationSchema={schema}
            onSubmit={onSubmitMemoized}
          >
            {() => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      variant="outlined"
                      fullWidth
                      id="weight"
                      label={t('Page.Settings.AthleteSettings.Weight')}
                      name="weight"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position="end">Kg</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      variant="outlined"
                      fullWidth
                      id="height"
                      label={t('Page.Settings.AthleteSettings.Height')}
                      name="height"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        'aria-label': 'height',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.wrapper}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={updateAthleteSettingsState.isLoading}
                      >
                        {t('Page.Settings.BaseSettings.Save')}
                      </Button>
                      {updateAthleteSettingsState.isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <div className="card-title mb-12">
            {t('Page.Settings.AthleteSettings.ConnectedApps')}
          </div>
          <Typography component="h6">STRAVA</Typography>
          <SimpleAlertDialog
            title={t('Page.Settings.AthleteSettings.DisconnectStrava')}
            message={t('Page.Settings.AthleteSettings.DisconnectStravaMessage')}
            color="secondary"
            handleConfirm={() =>
              disconnectStravaApp.request(ENDPOINTS.disconnectStrava(), {
                method: 'DELETE',
                onSuccess: () => {
                  history.push(PATHNAMES.dashboard());
                  enqueueSnackbar(
                    t('Page.Settings.AthleteSettings.DisconnectStravaAlert'),
                    {
                      variant: 'success',
                    },
                  );
                },
                onError: (err) => {
                  enqueueSnackbar('Uh Oh, ' + err, {
                    variant: 'error',
                  });
                },
              })
            }
          >
            {t('Page.Settings.AthleteSettings.Disconnect')}
          </SimpleAlertDialog>
        </>
      ) : (
        <div className={classes.alert}>
          <Alert variant="outlined" severity="info">
            {t('Page.Settings.AthleteSettings.AlertFirstlyConnect')}
          </Alert>
          <img
            src="/assets/images/illustrations/stopwatch.svg"
            alt="stopwatch"
            className={classes.image}
          />

          <Box display="block">
            <Button
              className={classes.buttonGetIt}
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(PATHNAMES.upload());
              }}
            >
              {t('Page.Settings.AthleteSettings.GetStartedButton')}
            </Button>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </Box>
        </div>
      )}
    </Card>
  );
};
