import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

export const SimpleAlertDialog = ({
  title,
  message,
  color,
  handleConfirm,
  children,
  type,
  ...inputProps
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div>
      {type !== 'icon' ? (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          color={color && color}
          {...inputProps}
        >
          {children}
        </Button>
      ) : (
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={handleClickOpen}
          color={color && color}
          {...inputProps}
        >
          {children}
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Molecules.SimpleAlertDialog.Cancel')}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {t('Molecules.SimpleAlertDialog.Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
